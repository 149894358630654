import React, { useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import { SignInFormContainer, SignInPageContainer } from './SignInPage.styled';
import LogoText from '../../assets/logo-text.svg';
import SignInForm from '../../components/SignInForm';
import { SignInOptions } from '../../types';

/**
 * SignInPage Component
 */
function SignInPage({ accountType }: SignInOptions) {

  return (
    <SignInPageContainer data-testid="sign-in-page">
      <img src={LogoText} alt="logo" />
      <SignInFormContainer>
        {accountType === 'admin' ? <SignInForm accountType={accountType} defaultPath="/admin-app/users" /> : <SignInForm accountType={accountType} defaultPath="/business-app/event-list" />}
      </SignInFormContainer>
    </SignInPageContainer>
  );
}

export default SignInPage;