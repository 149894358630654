import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Theme, useTheme } from '@mui/material/styles';
import { Box, Grid, Paper, IconButton, InputBase,Tab, Button, List, TextField, Typography } from '@mui/material';
import { ItemDisplay, DateTimeState, Payload } from '../../types';
import { formatDateToYYYYMMDD, fetchData, updateShowtimesById, getShowtimesById, convertDateTimeState, fetchListData, formatDate, partialUpdateEventById, deleteOrganizationById, deleteEventById, deleteAdById, getAdsByOrganizationId, getEventsByOrganizationId } from '../../utils';
import ListItemList from '../../components/ListItems/ListItemList';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Page, Title, BottomBar } from '../../components/Pages/PageComponents';
import { CookieNames } from '../../constants';
import ImageItemList from '../../components/ListItems/ImageItemList';
import AlertDialog from '../../components/Modals/Alert';
import { StyledButton, ButtonGroup} from '../../components/Buttons/StyledButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';

import tailwindConfig from '../../tailwind.config';
import resolveConfig from 'tailwindcss/resolveConfig';
const fullConfig = resolveConfig(tailwindConfig);

const secondary = fullConfig.theme?.colors?.secondary as Record<string, string>;

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

/**
 * Business Page
 */
function AdsShowtimesEdit(): JSX.Element {
  const { id = '-1' } = useParams();
  const [type, setType] = useState<string>('Date');
  const [weekday, setWeekday] = useState<string>('Sunday');
  const [inputDate, setInputDate] = useState<Date | null>(null);

  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    fetchData(id, getShowtimesById, setData, console.log);
  }, []);




  const [isDialogOpen, setDialogOpen] = useState({title: '', message: ''});
  const closeDialog = (e: React.MouseEvent<HTMLElement>) => {
    setDialogOpen({title: '', message: ''});
  };

  const handleError = async (response: Response) => {
    console.log(response);
    if (response.body) {
      const data = await response.json();
      setDialogOpen({title: data?.title || 'Unknown Problem', message: data?.message || 'An unknown problem occured on submission'});
    }
  };

  const handleSave = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const submitData: Payload = {
      'type': type === 'Date' ? 'date' : weekday,
      'date': (type === 'Date' && inputDate) ? formatDateToYYYYMMDD(inputDate) : null,
      'time_start': startTime?.toISOString(),
      'time_end': endTime?.toISOString(),
    };
    const response = await updateShowtimesById(id,submitData);
    if (response.ok) {
      navigate(-1);
    } else{
      handleError(response);
    }
    console.log(submitData);
  };




  const setData = (data: any) => {
    data = data ? data[0]: null;
    console.log(data);
    if (data) {
      if (data.type === 'date') {
        setType('Date');
        setInputDate(new Date(data.date));
      } else {
        setType('Weekday');
        setWeekday(data.type);
      }
      setStartTime(new Date(data.time_start));
      setEndTime(new Date(data.time_end));

      console.log(endTime);
      console.log(startTime);
    }
  };

  const DayOptions = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  const handleDateChange = (newDate: any) => {
    if (newDate) {
      setInputDate(newDate);
    }
  };

  const handleStartTimeChange = (newTime: any) => {
    if (newTime) {
      setStartTime(newTime);
    }
  };

  const handleEndTimeChange = (newTime: any) => {
    if (newTime) {
      setEndTime(newTime);
    }
  };

  const handleTypeChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;
    setType(value);
  };

  const handleWeekdayChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;
    setWeekday(value);
  };

  const WeekdaySelector = (
    <div>
      <div className='pb-2 text-secondary-300 w-48 text-2xl font-cabin'>
        Weekday
      </div>
      <FormControl sx={{width: 200 }}>
        <Select
          value={weekday}
          onChange={handleWeekdayChange}
          sx={{
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'black',
              borderWidth: 2,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'black',
              borderWidth: 3,
            },
          }}
        >
          {DayOptions.map((name) => (
            <MenuItem
              key={name}
              value={name}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );

  const DateSelector = (
    <div>
      <div className='pb-2 text-secondary-300 w-48 text-2xl font-cabin'>
        Date
      </div>
      <DatePicker
        value={inputDate}
        onChange={(date) => handleDateChange(date)}
        sx={{
          width: 200,
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'black',
            borderWidth: 2,
          },
          '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'black',
            borderWidth: 3,
          },
        }}
      />
    </div>
  );

  return (

    <Page>
      <Title>Edit Showtime</Title>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className='p-8'>
          <div className='pb-2 text-secondary-300 w-48 text-2xl font-cabin'>
            Type
          </div>
          <FormControl sx={{width: 200 }}>
            <Select
              value={type}
              onChange={handleTypeChange}
              sx={{
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'black',
                  borderWidth: 2,
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'black',
                  borderWidth: 3,
                },
              }}
            >
              <MenuItem
                key={'Date'}
                value={'Date'}
              >
                Date
              </MenuItem>
              <MenuItem
                key={'Weekday'}
                value={'Weekday'}
              >
                Weekday
              </MenuItem>
            </Select>
          </FormControl>
          <div className='pt-4'>
            {type === 'Weekday' ? WeekdaySelector : DateSelector}
          </div>
          <div className='pt-4'>
            <div className='pb-2 text-secondary-300 w-48 text-2xl font-cabin'>
              Start Time
            </div>
            <TimePicker
              value={startTime}
              onChange={handleStartTimeChange}
              sx={{
                width: 200,
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'black',
                  borderWidth: 2,
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'black',
                  borderWidth: 3,
                },
              }}
            />
          </div>
          <div className='pt-4'>
            <div className='pb-2 text-secondary-300 w-48 text-2xl font-cabin'>
              End Time
            </div>
            <TimePicker
              value={endTime}
              onChange={handleEndTimeChange}
              sx={{
                width: 200,
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'black',
                  borderWidth: 2,
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'black',
                  borderWidth: 3,
                },
              }}
            />
          </div>
        </div>
      </LocalizationProvider>
      <BottomBar>
        <ButtonGroup>
          <div className='pr-4'>
            <StyledButton variant="secondary" onClick={handleSave}>Save</StyledButton>
          </div>
          <StyledButton onClick={handleCancel}>Cancel</StyledButton>
        </ButtonGroup>
      </BottomBar>
    </Page>
  );
}

export default AdsShowtimesEdit;