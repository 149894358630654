import React from 'react';
import LogoText from '../../assets/logo-text.svg';
import { useNavigate, NavLink } from 'react-router-dom';
import { cookie } from '../../utils';
import { CookieNames } from '../../constants';

const handleLogoutClick = () => {
  cookie.remove(CookieNames.AUTH_TOKEN, { path: '/', domain: '.unione.cc' });
};
export type NamePathPair = {
  name: string;
  path: string;
}

export interface NavBarProps {
  logout_url: string;
  pathMaps: Array<NamePathPair>;
}

const drawWidth = 220;

/**
 * Navigation Components
 */
function NavBar(props: NavBarProps): JSX.Element {
  const { logout_url, pathMaps } = props;

  return (
    <div className=' h-[100vh] w-[300px] flex flex-col rounded-r-lg drop-shadow-2xl bg-white font-cabin text-2xl'>
      <div className=" pt-9 pb-16 px-14">
        <img src={LogoText} alt="logo-text" />
      </div>
      <div className=" pt-16">
        <div className=" pt-16">
          {
            pathMaps.map((namePathPair, idx) => {
              return (
                <NavLink key={idx} to={namePathPair.path}
                  className={({ isActive }) => isActive ? 'nav-bar-elements-clicked' : 'nav-bar-elements'} >
                  {namePathPair.name}
                </NavLink>
              );
            })
          }
        </div>
        <div>
          <NavLink to={logout_url} onClick={handleLogoutClick} className=' text-secondary-300 nav-bar-elements-logout'>
            Log Out
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default NavBar;