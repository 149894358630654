export const PrimaryButtonStyle = ({width = '18vh', height = '5vh'}) => ({
  width: width,
  height: height,
  bgcolor: 'primary.main',
  color: 'secondary.main',
  borderRadius: '8px',
  transition: '0.2s ease-out',
  typography: {
    fontFamily: 'Cabin', fontSize: '20px',
  },
  textTransform: 'capitalize',
  '&:hover': {
    bgcolor: 'error.main', color: 'white', transition: '0.2s ease-in-out'
  }
});

export const SecondaryButtonStyle = ({width = '18vh', height = '5vh'}) => ({
  width: width,
  height: height,
  bgcolor: 'secondary.main',
  borderRadius: '8px',
  transition: '0.2s ease-out',
  typography: {
    fontFamily: 'Cabin', fontSize: '20px',
  },
  textTransform: 'capitalize',
  '&:hover': {
    bgcolor: 'primary.main', color: 'secondary.main', transition: '0.2s ease-in-out'
  }
});

export const ErrorButtonStyle = ({width = '18vh', height = '5vh'}) => ({
  width: width,
  height: height,
  bgcolor: 'error.main',
  color: 'white',
  borderRadius: '8px',
  transition: '0.2s ease-out',
  typography: {
    fontFamily: 'Cabin', fontSize: '20px',
  },
  textTransform: 'capitalize',
  '&:hover': {
    bgcolor: 'warning.main', color: 'white', transition: '0.2s ease-in-out'
  }
});

export const SuccessButtonStyle = ({width = '18vh', height = '5vh'}) => ({
  width: width,
  height: height,
  bgcolor: 'success.main',
  color: 'white',
  borderRadius: '8px',
  transition: '0.2s ease-out',
  typography: {
    fontFamily: 'Cabin', fontSize: '20px',
  },
  textTransform: 'capitalize',
  '&:hover': {
    bgcolor: 'warning.main', color: 'white', transition: '0.2s ease-in-out'
  }
});

export const buttonSizes = {
  'extra_small': {width: '120px', height: '36px'},
  'small': {},
  'medium': {width: '18vh', height: '5vh'},
  'large': {}
};

export const ButtonDivStyle= 'drop-shadow-2xl float-right';