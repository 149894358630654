import React, { useRef } from 'react';
import { Container, useTheme } from '@mui/material';
import { ItemDisplay } from '../../types';

interface UploadProps {
  name?: string;
  noImageText?: string;
  image?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const AdDisplay: React.FC<UploadProps> = ({ name = 'Ad', noImageText = 'No image exists', image, onClick}) => {
  const theme = useTheme();

  return (
    <div className='text-xl text-info-300 justify-center items-center mt-1 mr-8'>
      <div className='bg-primary-300' style={{
        padding: '15px 15px 15px 15px',
        borderRadius: '12px', // Slightly larger radius than the inner container
        boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.3)', // Optional: Shadow for the outer container
        position: 'relative', // Ensures the inner container is positioned relative to this
        display: 'inline-block', // Adjust display to your layout needs
        border: '1px solid rgba(0, 0, 0, 0.1)', // Add a 1-pixel border
      }} onClick={onClick}>
        <div className='flex flex-col text-xl font-cabin text-secondary-300 w-48 mb-1 mt-30 justify-center items-center'>
          {name}
        </div>
        <Container sx={{
          float: 'left',
          height: '20vh',
          width: '20vh',
          padding: '0px',
          marginTop: '0px',
          flex: '0 0 auto',
          aspectRatio: '1',
          backgroundColor: theme.palette.secondary.light,
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          border: '1px solid rgba(0, 0, 0, 0.1)', // Add a 1-pixel border
          borderRadius: '12px',
          boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.2)', // Add a shadow
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }} >
          {image ? null : <div className='text-sm font-cabin text-secondary-300'>
            {noImageText}
          </div>}
        </Container>
      </div>
    </div>
  );
};


export interface ListProps {
  DisplayItems: Array<ItemDisplay>;
  noImageText: string;
  onClick: (id: any, e: React.MouseEvent<HTMLElement>) => void;
}

export const AdDisplayList: React.FC<ListProps> = ({ DisplayItems, noImageText, onClick}) => {

  return (
    <div className='flex flex-wrap text-xl text-info-300 pl-8 py-2 justify-start items-start mt-1'>
      {DisplayItems.map((item, idx) => {
        return (<AdDisplay key={idx} name={item.primary} noImageText={noImageText} image={item.img} onClick={((e) => (onClick(item.id, e)))} />);
      })}
    </div>
  );
};