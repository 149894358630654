import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Paper, IconButton, InputBase,Tab, Button, List } from '@mui/material';
import { ItemDisplay, AttendeeDisplay } from '../../types';
import { updateJoinRequestById, fetchListData, getAttendeesByEvent, deleteJoinRequestById } from '../../utils';
import { useNavigate } from 'react-router-dom';
import AttendeeList from '../../components/ListItems/AttendeeList';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Page, Title, PaginationBar, SearchBar } from '../../components/Pages/PageComponents';


function EventsPage(): JSX.Element {
  const { id = '-1' } = useParams();
  const [eventsList, setEventList] = useState<Array<AttendeeDisplay>>([]);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [maxPages, setMaxPages] = useState<number>(1);

  const navigate = useNavigate();

  const setEventsData = async (data: any) => {
    const DisplayResults = data.map((item: any) => ({
      id: item?.id,
      primary: item?.name,
      secondary: `Email: ${item?.email ? item?.email : 'No email provided'}`,
      attended: item?.has_attended
    }));
    console.log(data);
    setEventList(DisplayResults);
  };
  
  const getRequests = () => {
    return getAttendeesByEvent(id,pageNumber);
  };

  useEffect(() => {
    fetchListData(getRequests,setEventsData,console.log, {page: pageNumber, setter: setMaxPages});
  }, [pageNumber]);

  /**
   * Delete an item from the list by idx
   */
  const deleteByIdx = async (idx: number, setter: React.Dispatch<React.SetStateAction<Array<AttendeeDisplay>>>, list: Array<AttendeeDisplay>) => {
    const newList = list.filter((_, i) => i != idx);
    setter(newList);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {

    // Implement search logic based on the current page
    const newEventsList = eventsList.map((item, idx) => {
      if ((item.primary).toLowerCase().includes(event.target.value.toLowerCase())) {
        item.dont_show = false;
      } else {
        item.dont_show = true;
      }
      return item;
    });
    setEventList(newEventsList);
  };

  const handleDelete = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    deleteJoinRequestById(eventsList[idx].id.toString());
    deleteByIdx(idx, setEventList, eventsList);
  };

  const handleAttend = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    updateJoinRequestById(eventsList[idx].id.toString(),{ has_attended: !eventsList[idx].attended });
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    navigate(-1);
  };


  return (

    <Page>
      <Grid container spacing={3} sx={{ width: '100%' }} className='pl-4 pt-4'>
        <SearchBar placeholder='Search...' onChange={handleSearch}/>
        <Grid item xs={6} md={4} >
          <Button className=' float-right' variant="contained" color="secondary" size="large" onClick={handleCancel}>
            Return
          </Button>
        </Grid>
      </Grid>
      <div className=' py-4'>
        <AttendeeList DisplayItems={eventsList} onAttend={handleAttend} onDelete={handleDelete} />
      </div>
      <PaginationBar pageNumber={pageNumber} maxPages={maxPages} setter={setPageNumber}/>
    </Page>
  );
}

export default EventsPage;
