import React from 'react';
import NavBar from './NavBar';

const pathMaps = [
  {
    name: 'Analytics',
    path: '/admin-app/Analytics'
  }, 
  {
    name: 'Users',
    path: '/admin-app/users'
  },

  {
    name: 'Businesses',
    path: '/admin-app/businesses'
  },
  {
    name: 'Events',
    path: '/admin-app/events'
  },
  {
    name: 'Ads',
    path: '/admin-app/ads'
  },
  {
    name: 'Locations',
    path: '/admin-app/locations'
  },
  {
    name: 'Schools',
    path: '/admin-app/schools'
  },
  {
    name: 'Requests',
    path: '/admin-app/requests'
  }
];

/**
 * Navigation Components
 */
function Navigation(): JSX.Element {
  return <NavBar logout_url='/sign-in-admin' pathMaps={pathMaps} />;
}

export default Navigation;