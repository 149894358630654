/* istanbul ignore file */
export const UserStateActions = {
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
};


export const CookieNames = {
  AUTH_TOKEN: 'auth_token',
  ORG_ID: 'org_id'
};