// simply genereted in case of need
import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Page, Title, TopBar } from '../../components/Pages/PageComponents';

const AnalyticsDataManagement = () => {

  return (
    <Page>
      <TopBar>
        <Title>Data Management for Analytics</Title>
      </TopBar>
      <Box sx={{ p: 3 }}>
        <Typography variant="h6">Manage Your Data Here</Typography>

        <Button variant="contained" color="primary" sx={{ mt: 2 }}>
          Add New Data
        </Button>

      </Box>
    </Page>
  );
};

export default AnalyticsDataManagement;
