import React, { useReducer, type ReactNode } from 'react';
import { UserContext } from '../hooks/useUserContext';
import userContextReducer from '../reducers/userContextReducer';
import { type UserState } from '../types';

const defaultUserContext: UserState = { isSignedIn: false };

export interface UserContextProviderProps {
  initialContext?: UserState;
  children: ReactNode;
}

/**
 * UserContextProvider
 */
function UserContextProvider(props: UserContextProviderProps) {
  /* istanbul ignore next */
  const { initialContext = defaultUserContext, children } = props;
  return (
    <UserContext.Provider value={useReducer(userContextReducer, initialContext)}>
      {children}
    </UserContext.Provider>
  );
}

export default UserContextProvider;
