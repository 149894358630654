import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, IconButton, InputBase,Tab, Button, List } from '@mui/material';
import { ItemDisplay } from '../../types';
import { formatImageUrl, updateOrganizationById, getSignUpRequests, getEventRequests, getAdRequests, formatDateString, fetchListData, updateAdById, partialUpdateEventById, acceptAllEvents, acceptAllAds, acceptAllOrganizations } from '../../utils';
import { useNavigate } from 'react-router-dom';
import SignUpList from '../../components/ListItems/SignUpList';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Page, Title, TopBar, SearchBar, PaginationBar } from '../../components/Pages/PageComponents';
import ImageSignUpList from '../../components/ListItems/ImageSignUpList';
import PasswordDialog from '../../components/Modals/PasswordSetter';

/**
 * Business Page
 */
function RequestsPage(): JSX.Element {
  const [organizationList, setOrganizationList] = useState<Array<ItemDisplay>>([]);
  const [eventsList, setEventList] = useState<Array<ItemDisplay>>([]);
  const [adsList, setAdList] = useState<Array<ItemDisplay>>([]);
  const [tabType, setTabType] = useState('organizations');

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [maxPages, setMaxPages] = useState<number>(1);

  const navigate = useNavigate();

  const sendAcceptanceEmail = async (email: string, isApproved: boolean, password?: string) => {
    const payload = {
      recipient_email: email,
      is_approved: isApproved,
      password: password, 
    };
  
    try {
      const response = await fetch('https://api.unione.cc/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error('Failed to send email');
      }
  
      console.log('Email sent successfully');
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };
  

  const setOrganizationData = async (data: any) => {
    const DisplayResults = data.map((item: any) => ({
      id: item?.id,
      primary: item?.name,
      secondary: `Creation Date: ${formatDateString(item.creationDate?.toString())}`,
      extra: item?.email
    }));
    console.log(DisplayResults);
    setOrganizationList(DisplayResults);
  };

  const setEventsData = async (data: any) => {
    const DisplayResults = data.map((item: any) => ({
      id: item?.id,
      primary: item?.name,
      secondary: `Organization: ${item.org_name}`,
    }));
    setEventList(DisplayResults);
  };

  const setAdsData = async (data: any) => {
    const DisplayResults = data.map((item: any) => ({
      id: item?.id,
      primary: item?.name,
      secondary: `Date: from ${formatDateString(item?.time_start)} to ${formatDateString(item?.time_end)}`,
      img: item?.media ? formatImageUrl(item.media) : undefined,
    }));
    setAdList(DisplayResults);
  };

  useEffect(() => {
    if (tabType === 'organizations') {
      fetchListData(getSignUpRequests,setOrganizationData,console.log, {page: pageNumber, setter: setMaxPages});
    }
    if (tabType === 'ads') {
      fetchListData(getAdRequests,setAdsData,console.log, {page: pageNumber, setter: setMaxPages});
    }
    if (tabType === 'events') {
      fetchListData(getEventRequests,setEventsData,console.log, {page: pageNumber, setter: setMaxPages});
    }
  }, [pageNumber, tabType]);

  /**
   * Delete an item from the list by idx
   */
  const deleteByIdx = async (idx: number, setter: React.Dispatch<React.SetStateAction<Array<ItemDisplay>>>, list: Array<ItemDisplay>) => {
    const newList = list.filter((_, i) => i != idx);
    setter(newList);
  };

  /**
   * Set the tabType to be the corresponding tab
   */
  const handleTabChange = (_: React.SyntheticEvent<Element, Event>, newValue: string) => {
    setPageNumber(1);
    setTabType(newValue);
  };

  /**
   * Accept all requests
   */
  const handleOnAcceptAll = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    console.log('Accepted all requests of type');
    switch (tabType) {
    case 'organizations':
      setOrganizationList([]);
      acceptAllOrganizations();
      break;
    case 'ads':
      setAdList([]);
      acceptAllAds();
      break;
    case 'events':
      setEventList([]);
      acceptAllEvents();
      break;
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Implement search logic based on the current page
    const newAdsList = adsList.map((item, idx) => {
      if ((item.primary).toLowerCase().includes(event.target.value.toLowerCase())) {
        item.dont_show = false;
      } else {
        item.dont_show = true;
      }
      return item;
    });
    setAdList(newAdsList);

    const newOrganizationList = organizationList.map((item, idx) => {
      if ((item.primary).toLowerCase().includes(event.target.value.toLowerCase())) {
        item.dont_show = false;
      } else {
        item.dont_show = true;
      }
      return item;
    });
    setOrganizationList(newOrganizationList);

    const newEventsList = eventsList.map((item, idx) => {
      if ((item.primary).toLowerCase().includes(event.target.value.toLowerCase())) {
        item.dont_show = false;
      } else {
        item.dont_show = true;
      }
      return item;
    });
    setEventList(newEventsList);
  };


  const handleEventAccept = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    partialUpdateEventById(eventsList[idx].id.toString(),{ proposal: false });
    deleteByIdx(idx, setEventList, eventsList);
  };

  const handleEventReject = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    partialUpdateEventById(eventsList[idx].id.toString(),{ rejected: true });
    deleteByIdx(idx, setEventList, eventsList);
  };

  const handleAdAccept = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    updateAdById(adsList[idx].id.toString(),{ pending: false });
    deleteByIdx(idx, setAdList, adsList);
  };

  const handleAdReject = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    updateAdById(adsList[idx].id.toString(),{ rejected: true });
    deleteByIdx(idx, setAdList, adsList);
  };

  const handleOrganizationReject = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    const email = organizationList[idx].extra as string;
    updateOrganizationById(organizationList[idx].id.toString(),{ rejected: true });
    deleteByIdx(idx, setOrganizationList, organizationList);
    sendAcceptanceEmail(email, false);
  };

  const handleOrganizationClick = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    navigate(`businesses/${organizationList[idx].id}`);
  };

  const handleEventClick = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    navigate(`events/${eventsList[idx].id}`);
  };

  const handleAdClick = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    navigate(`ads/${adsList[idx].id}`);
  };

  const [currentOrg, setCurrentOrg] = useState<number>(-1);

  const closeDialog = (e: React.MouseEvent<HTMLElement>) => {
    setCurrentOrg(-1);
  };

  const handleOrganizationAcceptClick = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    setCurrentOrg(idx);
  };

  const handleSuccessfulCreation = (idx: number, passwordText: string, e: React.MouseEvent<HTMLElement>) => {
    const email = organizationList[idx].extra as string;
    updateOrganizationById(organizationList[idx].id.toString(),{ pending: false, password: passwordText });
    updateOrganizationById(organizationList[idx].id.toString(),{ pending: false });
    deleteByIdx(idx, setOrganizationList, organizationList);
    setCurrentOrg(-1);
    sendAcceptanceEmail(email, true, passwordText);
  };

  return (

    <Page>
      <PasswordDialog idx={currentOrg} onClose={closeDialog} title={(currentOrg === -1) ? '' : organizationList[currentOrg].primary} subtext={(currentOrg === -1) ? '' : organizationList[currentOrg].extra as string} onCreate={handleSuccessfulCreation} open={(currentOrg!==-1)}/>
      <Grid container spacing={3} sx={{ width: '100%' }} className='pl-4 pt-4'>
        <SearchBar placeholder='Search for a request' onChange={handleSearch}/>
        <Grid item xs={6} md={4}>
          {tabType !== 'organizations' ? <Button variant="contained" color="secondary" onClick={handleOnAcceptAll}>Accept All</Button> : null}
        </Grid>
      </Grid>
      <div className=' py-4'>
        <TabContext value={tabType}>
          <div className='pl-6 pr-6'>
            <TabList onChange={handleTabChange} variant="fullWidth" indicatorColor='secondary' textColor='secondary'>
              <Tab label="Organizations" value="organizations"/>
              <Tab label="Ads" value="ads"/>
              <Tab label="Events" value="events"/>
            </TabList>
          </div>
          <TabPanel value="organizations" sx={{padding: '0px'}}>
            <SignUpList DisplayItems={organizationList} onClick={handleOrganizationClick} onAccept={handleOrganizationAcceptClick} onReject={handleOrganizationReject} />
          </TabPanel>
          <TabPanel value="ads" sx={{padding: '0px'}}>
            <ImageSignUpList DisplayItems={adsList} onClick={handleAdClick} onAccept={handleAdAccept} onReject={handleAdReject} />
          </TabPanel>
          <TabPanel value="events" sx={{padding: '0px'}}>
            <SignUpList DisplayItems={eventsList} onClick={handleEventClick} onAccept={handleEventAccept} onReject={handleEventReject} />
          </TabPanel>
        </TabContext>
      </div>
      <PaginationBar pageNumber={pageNumber} maxPages={maxPages} setter={setPageNumber}/>
    </Page>
  );
}

export default RequestsPage;