import React from 'react'; 
import NavBar from './NavBar';
  

const pathMaps = [
  {
    name: 'Analytics',
    path: '/business-app/analytics'
  },
  {
    name: 'Event',
    path: '/business-app/event-list',
  },

  {
    name: 'Event Planner',
    path: '/business-app/event-planner'
  }, 
  {
    name: 'Locations',
    path: '/business-app/locations'
  },
  {
    name: 'Profile',
    path: '/business-app/profile'
  },
];


/**
 * Navigation Components
 */
function BusinessNavBar(): JSX.Element { 
  return (
    <NavBar logout_url='/sign-in-org' pathMaps={pathMaps} />
  );
} 
  
export default BusinessNavBar;