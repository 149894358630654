import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, IconButton, InputBase,Tab, Button, List, TextField, Typography } from '@mui/material';
import { ItemDisplay, DateTimeState } from '../../types';
import { formatDateToYYYYMMDD, createShowtimes, formatTimeString, getShowtimesByAdId, convertDateTimeState, fetchListData, formatDate, deleteShowtimesById, deleteOrganizationById, deleteEventById, deleteAdById, getAdsByOrganizationId, getEventsByOrganizationId } from '../../utils';
import { useNavigate } from 'react-router-dom';
import ListItemList from '../../components/ListItems/ListItemList';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Page, Title, TopBar, SearchBar, PaginationBar } from '../../components/Pages/PageComponents';
import { CookieNames } from '../../constants';
import ImageItemList from '../../components/ListItems/ImageItemList';
import AlertDialog from '../../components/Modals/Alert';

import tailwindConfig from '../../tailwind.config';
import resolveConfig from 'tailwindcss/resolveConfig';
const fullConfig = resolveConfig(tailwindConfig);

const secondary = fullConfig.theme?.colors?.secondary as Record<string, string>;

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

/**
 * Business Page
 */
function AdShowtimes(): JSX.Element {
  const [weekday, setWeekday] = useState('Sunday');
  const [tabType, setTabType] = useState('date');
  const [selectedDates, setSelectedDates] = useState<ItemDisplay[]>([]);
  const navigate = useNavigate();

  const [inputDate, setInputDate] = useState<Date | null>(null);
  const [inputStartTime, setInputStartTime] = useState<Date | null>(null);
  const [inputEndTime, setInputEndTime] = useState<Date | null>(null);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [maxPages, setMaxPages] = useState<number>(1);

  const queryParams = new URLSearchParams(location.search);
  const ad_id = queryParams.get('ad_id');

  const handleDateChange = (newDate: any) => {
    if (newDate) {
      setInputDate(newDate);
    }
  };

  const handleStartTimeChange = (newTime: any) => {
    if (newTime) {
      setInputStartTime(newTime);
    }
  };

  const handleEndTimeChange = (newTime: any) => {
    if (newTime) {
      setInputEndTime(newTime);
    }
  };

  const setDateShowtimesData = async (data: any) => {
    console.log(data);
    const DisplayResults = data.map((item: any) => ({
      id: item?.id,
      primary: item?.date ? formatDate(item?.date) : item?.type,
      secondary: `Times: From ${formatTimeString(item?.time_start)} to ${formatTimeString(item?.time_end)}`,
    }));
    setSelectedDates(DisplayResults);
  };



  const getData = () => {
    if (tabType==='date') {
      fetchListData((() => (getShowtimesByAdId(ad_id as string, 'date',pageNumber))),setDateShowtimesData,console.log, {page: pageNumber, setter: setMaxPages});
    } else {
      fetchListData((() => (getShowtimesByAdId(ad_id as string, weekday ,pageNumber))),setDateShowtimesData,console.log, {page: pageNumber, setter: setMaxPages});
    }
  };

  useEffect(() => {
    getData();
  }, [tabType, pageNumber, weekday]);

  /**
   * Set the tabType to be the corresponding tab
   */
  const handleTabChange = (_: React.SyntheticEvent<Element, Event>, newValue: string) => {
    setTabType(newValue);
    setPageNumber(1);
  };

  const handleWeekdayChange = (_: React.SyntheticEvent<Element, Event>, newValue: string) => {
    setWeekday(newValue);
    setPageNumber(1);
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    navigate(-1);
  };
  
  const handleCreate = async (e: React.MouseEvent<HTMLElement>) => {
    if (tabType === 'date') {
      if (inputDate && inputStartTime && inputEndTime) {
        const startTime = convertDateTimeState({name: 'Start Time', time: inputStartTime, date: inputDate});
        const endTime = convertDateTimeState({name: 'End Time', time: inputEndTime, date: inputDate});
        if (startTime && endTime) {
          const data = {ad_id: ad_id, date: formatDateToYYYYMMDD(inputDate), type: tabType, time_start: startTime, time_end: endTime};
          console.log(data);
          const response = await createShowtimes(data);
          if (response.ok) {
            getData();
          } else {
            handleError(response);
          }
        }
      } else {
        setDialogOpen({title: 'Invalid Fields', message: '' + (!inputDate ? 'No Date was Inputted. ' : '') + (!inputStartTime ? 'No Start Time was Inputted. ' : '') + (!inputEndTime ? 'No End Time was Inputted. ' : '')});
      }
    } else {
      if (inputStartTime && inputEndTime) {
        const startTime = convertDateTimeState({name: 'Start Time', time: inputStartTime, date: new Date()});
        const endTime = convertDateTimeState({name: 'End Time', time: inputEndTime, date: new Date()});
        if (startTime && endTime) {
          const data = {ad_id: ad_id, date: null, type: weekday, time_start: startTime, time_end: endTime};
          console.log(data);
          const response = await createShowtimes(data);
          if (response.ok) {
            getData();
          } else {
            handleError(response);
          }
        }
      } else {
        setDialogOpen({title: 'Invalid Fields', message: '' + (!inputStartTime ? 'No Start Time was Inputted. ' : '') + (!inputEndTime ? 'No End Time was Inputted. ' : '')});
      }
    }
  };

  /**
   * Delete an item from the list by idx
   */
  const deleteByIdx = async (idx: number, setter: React.Dispatch<React.SetStateAction<Array<ItemDisplay>>>, list: Array<ItemDisplay>) => {
    const newList = list.filter((_, i) => i != idx);
    setter(newList);
  };

  const deleteShowtime = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    deleteShowtimesById(selectedDates[idx].id.toString());
    deleteByIdx(idx, setSelectedDates, selectedDates);
  };

  const editShowtime = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    navigate(`../showtimes/${selectedDates[idx].id.toString()}/edit`);
  };

  const [isDialogOpen, setDialogOpen] = useState({title: '', message: ''});
  const closeDialog = (e: React.MouseEvent<HTMLElement>) => {
    setDialogOpen({title: '', message: ''});
  };

  const handleError = async (response: Response) => {
    console.log(response);
    if (response.body) {
      const data = await response.json();
      setDialogOpen({title: data?.title || 'Unknown Problem', message: data?.message || 'An unknown problem occured on submission'});
    }
  };

  return (

    <Page>
      <AlertDialog title={isDialogOpen.title} open={isDialogOpen.message!==''} message={isDialogOpen.message} onClose={closeDialog}/>
      <div className='flex flex-row'>
        <div className='flex-1'>
          <Title>Ad Showtimes</Title>
        </div>
        <Button 
          variant="contained" 
          color="secondary" 
          size='large' 
          onClick={handleCancel}
          sx={{
            height: '40px',
            margin: '25px'
          }}
        >
          Cancel
        </Button>
      </div>
      <div className=' py-4'>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TabContext value={tabType}>
            <TabList onChange={handleTabChange} variant="fullWidth" indicatorColor='secondary' textColor='secondary'>
              <Tab label="Specific Dates" value="date"/>
              <Tab label="Days of the Week" value="Weekdays"/>
            </TabList>
            <TabPanel value="date" sx={{padding: '0px'}}>
              <div className='p-8'>
                <div className='flex flex-row'>
                  <div className='flex-1'>
                    <div className='flex flex-col' >
                      <div className='pb-2 text-secondary-300 w-48 text-2xl font-cabin'>
                        Date To Add
                      </div>
                      <div>
                        <DatePicker
                          value={null}
                          onChange={(date) => handleDateChange(date)}
                          sx={{
                            width: '10vw',
                          }}
                        />
                        <Button 
                          variant="contained" 
                          color="secondary" 
                          size='large' 
                          onClick={handleCreate}
                          sx={{
                            height: '50px',
                            marginLeft: '20px'
                          }}
                        >
                          Create
                        </Button>
                      </div>
                    </div>
                  </div>
                      
                  <div className='flex flex-row'>
                    <div className='flex-1 pr-4'>
                      <div className='flex flex-col' >
                        <div className='pb-2 text-secondary-300 w-48 text-2xl font-cabin'>
                          Start Time
                        </div>
                        <div>
                          <TimePicker
                            onChange={handleStartTimeChange}
                            sx={{
                              width: '10vw',
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='flex-1'>
                      <div className='flex flex-col'>
                        <div className='pb-2 text-secondary-300 w-48 text-2xl font-cabin'>
                          End Time
                        </div>
                        <div>
                          <TimePicker
                            onChange={handleEndTimeChange}
                            sx={{
                              width: '10vw',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>                  
                </div>
              </div>
              <div className=' pl-8 text-secondary-300 w-48 text-2xl font-cabin'>
                Showtimes
              </div>
              <div className=' pl-4'>
                <ListItemList DisplayItems={selectedDates} onClick={() => console.log('Clicked')} onEdit={editShowtime} onDelete={deleteShowtime} />
              </div>
            </TabPanel>
            <TabPanel value="Weekdays" sx={{padding: '0px'}}>
              <TabContext value={weekday}>
                <TabList onChange={handleWeekdayChange} variant="fullWidth" indicatorColor='secondary' textColor='secondary'>
                  <Tab label="Sunday" value="Sunday"/>
                  <Tab label="Monday" value="Monday"/>
                  <Tab label="Tuesday" value="Tuesday"/>
                  <Tab label="Wednesday" value="Wednesday"/>
                  <Tab label="Thursday" value="Thursday"/>
                  <Tab label="Friday" value="Friday"/>
                  <Tab label="Saturday" value="Saturday"/>
                </TabList>
              </TabContext>
              <div className='p-8'>
                <div className='flex flex-row float-left mb-8'>
                  <div className='flex-1'>
                    <div className='flex flex-col' >
                      <div className='pb-2 text-secondary-300 w-48 text-2xl font-cabin'>
                        Start Time
                      </div>
                      <div>
                        <TimePicker
                          onChange={handleStartTimeChange}
                          sx={{
                            width: '10vw',
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='flex-1 ml-4'>
                    <div className='flex flex-col'>
                      <div className='pb-2 text-secondary-300 w-48 text-2xl font-cabin'>
                        End Time
                      </div>
                      <div className='flex flex-row'>
                        <TimePicker
                          onChange={handleEndTimeChange}
                          sx={{
                            width: '10vw',
                          }}
                        />
                        <Button 
                          variant="contained" 
                          color="secondary" 
                          size='large' 
                          onClick={handleCreate}
                          sx={{
                            height: '50px',
                            marginLeft: '20px'
                          }}
                        >
                          Create
                        </Button>
                      </div>
                    </div>
                  </div>                  
                </div>
              </div>
              <div className=' pl-8 text-secondary-300 w-48 text-2xl font-cabin'>
                Showtimes
              </div>
              <div className=' pl-4'>
                <ListItemList DisplayItems={selectedDates} onClick={() => console.log('Clicked')} onEdit={editShowtime} onDelete={deleteShowtime} />
              </div>
            </TabPanel>
          </TabContext>
        </LocalizationProvider>
      </div>
      <PaginationBar pageNumber={pageNumber} maxPages={maxPages} setter={setPageNumber}/>
    </Page>
  );
}

export default AdShowtimes;