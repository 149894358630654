import React from 'react';
import { ItemDisplay } from '../../types';
import SignUpItem from './SignUpItem';

export interface SignUpListProps {
  DisplayItems: Array<ItemDisplay>;
  onAccept: (id: any, e: React.MouseEvent<HTMLElement>) => void;
  onReject: (id: any, e: React.MouseEvent<HTMLElement>) => void;
  onClick: (id: any, e: React.MouseEvent<HTMLElement>) => void;
}

export function SignUpList(props: SignUpListProps) {
  const { DisplayItems, onAccept, onReject, onClick } = props;

  return (
    <div className='p-4'>
      {DisplayItems.map((item, idx) => {
        if (!item.dont_show) {
          return (<SignUpItem key={idx} id={idx} primary={item.primary} secondary={item.secondary} onAccept={onAccept} onClick={onClick} onReject={onReject} />);
        }
      })}
    </div>
  );
}

export default SignUpList;