import { Bar } from 'react-chartjs-2';
import { getSummaryData } from '../../utils';
import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface ChartProps {
  selectedDay: string;
}


function Chart({ selectedDay }: ChartProps): JSX.Element {
  // Hours of the day from 8 AM to 8 PM
  const hours = Array.from({ length: 13 }, (_, i) => {
    let hour = 8 + i;
    if (hour > 12) {
      hour -= 12;
      return `${hour} PM`;
    } else if (hour === 12) {
      return `${hour} PM`;
    } else {
      return `${hour} AM`;
    }
  });

  // Initialize chart data with an empty dataset, CHANGE LATER
  const [chartData, setChartData] = useState({
    datasets: [
      {
        label: 'Number of Students Available',
        data: [], // Initially empty
        backgroundColor: 'darkorange',
        borderColor: 'darkorange',
        borderWidth: 1,
      },
    ],
  });

  const chartOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Student Availability',
        font: {
          size: 30,
        },
      },
      legend: {
        display: false, // This will hide the legend
      },
    },
    scales: {
      y: {
        max: 20,
        title: {
          display: true,
          text: 'Number of Students',
          font: {
            size: 24,
          },
        },
        grid: {
          drawBorder: true, // Draw the border
          borderColor: 'black', // Border color
          borderWidth: 4, // Border width
          drawOnChartArea: false,
        },
      },
      x: {
        labels: hours,
        title: {
          display: true,
          text: 'Time of Day',
          font: {
            size: 24,
          },
        },
        grid: {
          drawBorder: true, // Draw the border
          borderColor: 'black', // Border color
          borderWidth: 4, // Border width
          drawOnChartArea: false,
        },
      },
    },
    maintainAspectRatio: false,
  };

  // Fetch data when the selected day changes
  useEffect(() => {
    // Fetch summary data for the selected day
    getSummaryData(selectedDay)
      .then((response: Response) => response.json())
      .then((data: any) => {
        // Update the chart data with the received data
        setChartData({
          datasets: [
            {
              label: 'Number of Students Available',
              data: data, // data
              backgroundColor: 'darkorange',
              borderColor: 'darkorange',
              borderWidth: 1,
            },
          ],
        });
      });
  }, [selectedDay]);

  return (
    <Bar data={chartData} options={chartOptions} />
  );
}

export default Chart;