// fr profile page for the organization
import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Paper, useTheme } from '@mui/material';
import { Page, Title, TopBar } from '../../components/Pages/PageComponents';
import { getOrganizationById, partialUpdateOrganizationById } from '../../utils';
import { cookie } from '../../utils'; 
import { CookieNames } from '../../constants';

const UserProfile = () => {
  const theme = useTheme();
  const [organization, setOrganization] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchOrganization = async () => {
      const orgId = cookie.get(CookieNames.ORG_ID);
      if (!orgId) {
        console.log('Organization ID not found in cookies');
        setIsLoading(false);
        return;
      }

      try {
        const response = await getOrganizationById(orgId);
        if (response.ok) {
          const data = await response.json();
          if (data && data.length > 0) {
            setOrganization(data[0]); 
          } else {
            console.error('Organization data is empty');
          }
        } else {
          console.error('Failed to fetch organization data:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching organization data:', error);
      }
      setIsLoading(false);
    };

    fetchOrganization();
  }, []);


  const handleInputChange = (event) => {
    const { value } = event.target;
    setOrganization((prevOrganization) => ({
      ...prevOrganization,
      description: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const orgId = cookie.get(CookieNames.ORG_ID);
    if (!orgId || !organization) {
      console.error('Organization ID not found or organization data is null');
      return;
    }

    const updatedFields = { description: organization.description };

    const response = await partialUpdateOrganizationById(orgId, updatedFields);
    if (response.ok) {
      console.log('Organization updated successfully');
    } else {
      console.error('Failed to update organization');
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (!organization) return <div>Organization data not available.</div>;

  return (
    <Page>
      <TopBar>
        <Title>Profile</Title>
      </TopBar>
      <Box component={Paper} p={3}>
        <Typography variant="h6" gutterBottom>Edit Business Information</Typography>
        <form onSubmit={handleSubmit}>
          {/* Displaying other fields as text */}
          {['name', 'email', 'phone'].map((key) => (
            <Typography key={key} variant="body1" gutterBottom>
              {`${key.charAt(0).toUpperCase() + key.slice(1)}: ${organization[key] || 'N/A'}`}
            </Typography>
          ))}
          {/* Making description the only editable field */}
          <TextField
            fullWidth
            margin="normal"
            label="Description"
            name="description"
            multiline
            rows={4}
            value={organization.description || ''}
            onChange={handleInputChange}
          />
          <Box mt={2}>
            <Button variant="contained" color="primary" type="submit">Save Changes</Button>
          </Box>
        </form>
      </Box>
    </Page>
  );
};

export default UserProfile;
