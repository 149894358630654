import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { FlaggedUsers, DisplayField } from '../../types';
import { findFieldValue, deleteFlagByUserId, deleteUserById, formatDateString, flagsByUserId, getFlaggedUsersById, patchUserById } from '../../utils';
import { StyledButton } from '../../components/Buttons/StyledButton';
import { NonEditableText } from '../../components/Text/TextComponents';
import { createNavigateBack } from '../../utils';
import { UserInfo } from '../../types';
import { Button, Divider, MenuItem } from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled, alpha } from '@mui/material/styles';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 8,
    marginTop: theme.spacing(1),
    minWidth: 180,
    bgcolor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    transition: '0.2s ease-out',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 20,
        fontFamily: 'Cabin',
        color: theme.palette.secondary.main,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
      '&: hover': {
        backgroundColor: alpha(
          theme.palette.secondary.main,
          theme.palette.action.hoverOpacity,
        ),
        color: theme.palette.secondary.main,
        transition: '0.2s ease-in-out'
      }
    },
  },
}));

/**
 * UserInfo Component
 */
function UserInfoPage(): JSX.Element {
  const { id = '-1' } = useParams<{ id: string; }>();
  const navigate = useNavigate();
  const navigateBack = createNavigateBack(navigate, '/admin-app/users');
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [flagged, setFlagged] = useState<boolean>(false);
  const fieldDictionary: DisplayField[] = [
    { name: 'Display Name', value: `${userInfo?.firstName} ${userInfo?.lastName}`, dont_display: true },
    { name: 'Flagged Words', value: userInfo?.flaggedTexts?.join(', '), dont_display: !flagged},
    { name: 'Preferred Name', value: userInfo?.preferredName },
    { name: 'Pronouns', value: userInfo?.pronouns },
    { name: 'Email', value: userInfo?.email },
    { name: 'Major', value: userInfo?.major },
    { name: 'Year', value: userInfo?.year },
    { name: 'Creation Date', value: formatDateString(userInfo?.creation_date) },
    { name: 'Bio', value: userInfo?.bio, big: true },
  ];

  useEffect(() => {
    getData();
  }, []);

  /**
   * Fetches userInfo from the server
   */
  const getData = async () => {
    const userInfoResponse = await getFlaggedUsersById(id);
    let userInfo = await userInfoResponse.json();
    userInfo = userInfo[0] as UserInfo;
    const flagResponse = await flagsByUserId(id);
    const flagData = await flagResponse.json();
    const flaggedWords = flagData.map((flag: FlaggedUsers) => flag.text);
    userInfo.flaggedTexts = flaggedWords;
    setUserInfo(userInfo);
    const is_flagged = userInfo.is_flag;
    setFlagged(is_flagged);
  };

  /**
   * Deletes the user from the server
   */
  const handleDelete = async () => {
    await deleteUserById(id);
    navigateBack();
  };

  /**
   * Removes the user from the flagged list
   */
  const handleRemoveFlag = async () => {
    await deleteFlagByUserId(id);
    getData();
  };

  const handleEdit = () => {
    navigate(`/admin-app/users/${id}/edit`);
  };
  const handleBlock = async () => {
    const response = await patchUserById(id.toString(), { is_blocked: !is_blocked });
    getData();
  };
  /**
   * Handles the restrict event
  */
  const handleRestrict = async () => {
    const response = await patchUserById(id.toString(), { is_restricted: !is_restricted });
    getData();
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenu = (button: string) => (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (button === 'block') {
      handleBlock();
    }
    if (button === 'restrict') {
      handleRestrict();
    }
    if (button !== '') {
      getData();
      handleCloseMenu();
    }
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const is_blocked = userInfo?.is_blocked;
  const is_restricted = userInfo?.is_restricted;

  if (userInfo === null) {
    return <></>;
  }

  return (
    <div className=' p-4 h-[100vh]'>
      <div className=' font-cabin bg-white rounded-lg drop-shadow-2xl h-[100%] overflow-y-scroll'>
        <div className=' flow-root'>
          <div className=' text-secondary-300 pt-8 pl-8 text-4xl float-left'>
            {findFieldValue(fieldDictionary, 'Display Name')}
          </div>
          <div className=' pt-8 float-right'>
            <div className=' flow-root'>
              <div className=' float-left'>
                <Button sx={{
                  width: '18vh',
                  height: '5vh',
                  bgcolor: 'secondary.main',
                  color: 'white',
                  borderRadius: '8px',
                  transition: '0.2s ease-out',
                  typography: {
                    fontFamily: 'Cabin',
                    fontSize: '20px',
                  },
                  textTransform: 'capitalize',
                  ': hover': {
                    bgcolor: 'primary.main',
                    color: 'secondary.main',
                    transition: '0.2s ease-in-out'
                  }
                }} aria-controls={open ? 'demo-customized-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} variant="contained" disableElevation onClick={handleMenu('')} endIcon={<KeyboardArrowDownIcon />}>
                  Actions
                </Button>
                <StyledMenu data-testid='user-info-menu'
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleCloseMenu}>
                  {flagged && <MenuItem onClick={handleRemoveFlag} data-testid='user-info-menu-flag' disableRipple>Remove Flag</MenuItem>}
                  {flagged && <Divider />}
                  <MenuItem onClick={handleMenu('block')} data-testid='user-info-menu-block' disableRipple>{is_blocked ? 'Unblock' : 'Block'}</MenuItem>
                  <MenuItem onClick={handleMenu('restrict')} data-testid='user-info-menu-restrict' disableRipple> {is_restricted ? 'Unrestrict' : 'Restrict'}</MenuItem>
                  <Divider />
                  <MenuItem onClick={handleEdit} data-testid='user-info-menu-edit' disableRipple>Edit</MenuItem>
                  <MenuItem onClick={handleDelete} data-testid='user-info-menu-edit' disableRipple>Delete</MenuItem>
                </StyledMenu>
              </div>
              <div className=' pl-4 pr-9 float-right'>
                <StyledButton onClick={() => navigate(-1)}>
                  Cancel
                </StyledButton>
              </div>
            </div>
          </div>
        </div>
        <NonEditableText fields={fieldDictionary} />
      </div>
    </div>
  );
}

export default UserInfoPage;