import { ListItem, Stack, ListItemText, Container } from '@mui/material';
import React from 'react';
import { StyledButton } from '../Buttons/StyledButton';
import { formatImageUrl } from '../../utils';

export interface SignUpItemProps {
  id: any;
  primary: string;
  secondary: string;
  image: string;
  onEdit: (id: any, e: React.MouseEvent<HTMLElement>) => void;
  onDelete: (id: any, e: React.MouseEvent<HTMLElement>) => void;
  onClick: (id: any, e: React.MouseEvent<HTMLElement>) => void;
}

export function ImageListItem(props: SignUpItemProps) {
  const { id, primary, secondary, image, onEdit, onDelete, onClick } = props;
  console.log(`url(${image})`);
  return (
    <div className=' bg-primary-300 drop-shadow-2xl rounded-lg'>
      <ListItem sx={{
        height: '100%',
        width: '100%',
        margin: '16px 0px 16px 0px',
      }}>
        <Container sx={{
          height: '100%',
          width: 'auto',
          padding: '2px',
          marginRight: '10px',
          flex: '0 0 auto',
          aspectRatio: '1',
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          borderRadius: '12px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)', // Add a shadow
        }} />
        <ItemText primary = {primary} secondary= {secondary} handleItemClick={((e) => {onClick(id,e);})} />
        <Stack spacing={2} direction='row'>
          <StyledButton variant = 'primary' onClick={((e) => {onEdit(id,e);})} size = 'extra_small' variantExtra='contained'>Edit</StyledButton>
          <StyledButton variant = 'primary' onClick={((e) => {onDelete(id,e);})} size = 'extra_small' variantExtra='contained'>Delete</StyledButton>
        </Stack>
      </ListItem>
    </div>
  );
}


interface ItemTextParams {
  primary: string,
  secondary: string
  handleItemClick?:  (event: React.MouseEvent<HTMLElement>) => void;
}

// For displaying non-editable text
export const ItemText: React.FC<ItemTextParams> = ({ primary, secondary, handleItemClick }) => {
  return (
    <ListItemText
      primary={primary}
      secondary={secondary}
      primaryTypographyProps={{
        color: 'secondary',
        fontSize: '20px'
      }}
      onClick={handleItemClick}
      sx={{
        color: 'secondary.main',
      }}
    />
  );
};

export default ImageListItem;