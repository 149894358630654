import React from 'react';
import { UserListItems } from '../../types';
import UserListItem from './UserListItem';

export interface SignUpListProps {
  items: Array<UserListItems>;
  onChange: () => void;
}

export function UserListItemList(props: SignUpListProps) {
  const { items, onChange } = props;

  return (
    <div>
      {items.filter((user) => !user.is_removed && !user.dont_show).map((user) => (
        <UserListItem key={user.id} {...user} onChange={onChange} />
      ))}
    </div>
  );
}

export default UserListItemList;
