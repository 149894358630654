import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, IconButton, InputBase, Button, List } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { ItemDisplay } from '../../types';
import { deleteOrganizationById, getOrganizationList, fetchListData, formatDateString } from '../../utils';
import { Page, Title, TopBar, SearchBar, PaginationBar, BottomBar } from '../../components/Pages/PageComponents';
import { useNavigate } from 'react-router-dom';
import ListItemList from '../../components/ListItems/ListItemList';

/**
 * Business Page
 */
function BusinessesPage(): JSX.Element {
  const [organizationList, setOrganizationList] = useState<Array<ItemDisplay>>([]);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [maxPages, setMaxPages] = useState<number>(1);

  const navigate = useNavigate();


  const setOrganizationData = async (data: any) => {
    const DisplayResults = data.map((item: any) => ({
      id: item?.id,
      primary: item?.name,
      secondary: `Email: ${item?.email}`,
    }));
    console.log(data);
    setOrganizationList(DisplayResults);
  };

  useEffect(() => {
    fetchListData(getOrganizationList,setOrganizationData,console.log, {page: pageNumber, setter: setMaxPages});
  }, [pageNumber]);

  /**
   * Delete an item from the list by idx
   */
  const deleteByIdx = async (idx: number, setter: React.Dispatch<React.SetStateAction<Array<ItemDisplay>>>, list: Array<ItemDisplay>) => {
    const newList = list.filter((_, i) => i != idx);
    setter(newList);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {

    // Implement search logic based on the current page
    const newOrgList = organizationList.map((item, idx) => {
      if ((item.primary).toLowerCase().includes(event.target.value.toLowerCase())) {
        item.dont_show = false;
      } else {
        item.dont_show = true;
      }
      return item;
    });
    setOrganizationList(newOrgList);
  };

  /**
   * Naivgate to ./create-business path after clicking
   */
  const handleOnCreateClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    navigate('-1/edit');
  };

  const handleOrgEdit = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    navigate(`${organizationList[idx].id}/edit`);
  };

  const handleOrgClick = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    navigate(`${organizationList[idx].id}`);
  };

  const handleOrgDelete = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    deleteOrganizationById(organizationList[idx].id.toString());
    deleteByIdx(idx, setOrganizationList, organizationList);
  };

  return (
    <Page>
      <Grid container spacing={3} sx={{ width: '100%' }} className='pl-4 pt-4'>
        <SearchBar placeholder='Search for a business' onChange={handleSearch}/>
        <Grid item xs={6} md={4}>
          <Button variant="contained" color="secondary" onClick={handleOnCreateClick}>
            Create
          </Button>
        </Grid>
      </Grid>
      <div className=' py-4'>
        <ListItemList DisplayItems={organizationList} onClick={handleOrgClick} onEdit={handleOrgEdit} onDelete={handleOrgDelete} />
      </div>
      <PaginationBar pageNumber={pageNumber} maxPages={maxPages} setter={setPageNumber}/>
    </Page>
  );
}

export default BusinessesPage;