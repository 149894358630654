import React, { useState, useEffect } from 'react';
import { AppBar, Typography, Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Chart from '../../components/Charts/Chart';
import { Page, Title, TopBar, SearchBar } from '../../components/Pages/PageComponents';
import { getSummaryOverview } from '../../utils';

function EventPlannerPage(): JSX.Element {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const [tabType, setTabType] = useState('chart');
  const [selectedDay, setSelectedDay] = useState<string>('Sunday');
  const [overViewData, setOverViewData] = useState({'Best Times': {}, 'Best Day': 'N/A', 'Best Day Total': 'N/A'});

  useEffect(() => {
    getSummaryOverview().then(response => {
      console.log('Status Code:', response.status); // Access the status code
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Network response was not ok.');
      }
    }).then(data => {
      setOverViewData(data);
    }).catch(error => {
      console.error('There has been a problem with your fetch operation:', error);
    });
  }, []);

  const handleTabChange = (_: React.SyntheticEvent<Element, Event>, newValue: string) => {
    setTabType(newValue);
  };

  const handleDayChange = (_: React.SyntheticEvent<Element, Event>, newValue: string) => {
    setSelectedDay(newValue);
  };

  return (
    <Page>
      <Title>
        {tabType == 'chart' ? 'Availability Chart' : 'Availability Insights'}
      </Title>
      <TabContext value={tabType}>
        <div className='pb-4'>
          <TabList className='py-2' onChange={handleTabChange} variant="fullWidth" indicatorColor='secondary' textColor='secondary'>
            <Tab label="Chart" value="chart"/>
            <Tab label="Summary" value="summary"/>
          </TabList>
        </div>
        <TabPanel value="summary" sx={{ padding: '0px', height: '100vh' }}>
          <div className='flex flex-col justify-between h-full'>
            <div>
              <div className=" text-secondary-300 pt-4 pl-8 text-[28px]">
                Overview
              </div>
              <div className='text-xl text-info-300 pl-8 mt-2'>
                <p className='mt-2'>
                  {`Day with highest availability: ${overViewData['Best Day']}`}
                </p>
                <p className='mt-2'>
                  {`Availability on ${overViewData['Best Day']}: ${overViewData['Best Day Total']}`}
                </p>
              </div>
            </div>
            <div>
              <div className=" text-secondary-300 pt-4 pl-8 text-[28px]">
                Highest Availabilities Per Weekday
              </div>
              <div className='flex flex-wrap text-xl text-info-300 pl-8 py-2 justify-start items-start mt-1'>
                {Object.entries(overViewData['Best Times']).map(([key, value]) => (
                  <div key={key} className='mr-0 mb-2'>
                    <DayDisplay name={key} text={value as string} />
                  </div>
                ))}
              </div>
            </div>
            { /*  Below is to modify the spacing a bit */}
            <div></div>
            <div></div>
            
          </div>
        </TabPanel>
        <TabPanel value="chart" sx={{padding: '0px'}}>
          <TabContext value={selectedDay}>
            <TabList onChange={handleDayChange} variant="fullWidth" indicatorColor='secondary' textColor='secondary'>
              <Tab label="Sundary" value="Sunday"/>
              <Tab label="Monday" value="Monday"/>
              <Tab label="Tuesday" value="Tuesday"/>
              <Tab label="Wednesday" value="Wednesday"/>
              <Tab label="Thursday" value="Thursday"/>
              <Tab label="Friday" value="Friday"/>
              <Tab label="Saturday" value="Saturday"/>
            </TabList>
            <div className='p-16 h-[80vh] w-[100%]'>
              <Chart selectedDay={selectedDay} />
            </div>
          </TabContext>
        </TabPanel>
      </TabContext>
    </Page>
  );
}

export default EventPlannerPage;


interface UploadProps {
  name?: string;
  text?: string;
}

export const DayDisplay: React.FC<UploadProps> = ({ name = 'Ad', text = 'N/A' }) => {
  return (
    <div className='flex justify-center items-center mt-1 mr-8'>
      <div className='bg-primary-300' style={{
        height: '10vh',
        width: '15vh',
        padding: '15px',
        borderRadius: '12px',
        boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.3)',
        border: '1px solid rgba(0, 0, 0, 0.1)',
      }}>
        <div className='text-xl font-cabin text-secondary-300 mb-1 flex justify-center'>
          {name}
        </div>
        <div className='text-sm font-cabin text-info-300 flex justify-center'>
          {text}
        </div>
      </div>
    </div>
  );
};