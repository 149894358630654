import './LocationPicker.css';

import React, {  useState, useEffect, useMemo } from 'react';
import { GoogleMap, useLoadScript, Marker} from '@react-google-maps/api';
import { Container, useTheme } from '@mui/material';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox';
import '@reach/combobox/styles.css';

interface Location {
  lat: number;
  lng: number;
}

interface MapParams {
  setLoc?: React.Dispatch<React.SetStateAction<Location | null>>;
  loc: Location | null;
  readOnly: boolean | undefined;
}

interface PlacesAutocompleteProps {
  setSelected: (location: Location) => void;
}

const Places: React.FC<MapParams> = ({setLoc, loc, readOnly=false}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDSqbAi7pkHSLpwUmvpXqwRmlXg-BROftI',
    libraries: ['places'],
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <Map setLoc={setLoc} loc={loc} readOnly={readOnly}/>;
};

const Map: React.FC<MapParams> = ({setLoc, loc}) => {
  const [center, setCenter] = useState({lat: 0, lng: 0});
  const [zoom, setZoom] = useState(1);
  const theme = useTheme();

  const onMapClick = React.useCallback((event: any) => {
    if (setLoc) {
      setLoc({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
    }
  }, []);

  const onTyped = (event: any) => {
    if (setLoc) {
      setLoc(event);
    }
    setCenter(event);
    setZoom(16);
  };

  return (
    <div  className=' '>
      <div className='flex flex-col text-xl font-cabin text-secondary-300 w-48 mb-2'>
        Select A Location
      </div>
      <Container sx={{
        float: 'left',
        height: '40vh',
        width: '40vh',
        padding: '25px',
        backgroundColor: theme.palette.primary.main,
        backgroundSize: 'cover',
        border: '1px solid rgba(0, 0, 0, 0.1)', // Add a 1-pixel border
        borderRadius: '12px',
        boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.2)', // Add a shadow
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }} >
        <div className='places-container'>
          {setLoc ? <PlacesAutocomplete setSelected={onTyped} /> : null}
        </div>

        <GoogleMap
          zoom={zoom}
          center={center}
          mapContainerClassName='map-container'
          onClick={onMapClick}
        >
          {loc && <Marker position={loc} title={`${loc.lat}, ${loc.lng}`} />}
        </GoogleMap>
      </Container>
    </div>
  );
};

const PlacesAutocomplete: React.FC<PlacesAutocompleteProps> = ({ setSelected }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (address: any) => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    setSelected({ lat, lng });
  };

  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={!ready}
        className='combobox-input'
        placeholder='Search for an address'
      />
      <ComboboxPopover>
        <ComboboxList>
          {status === 'OK' &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
};


export default Places;