import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, IconButton, InputBase,Tab, Button, List } from '@mui/material';
import { ItemDisplay } from '../../types';
import { updateOrganizationById, getSignUpRequests, getEventRequests, getAdRequests, formatDateString, fetchListData, updateAdById, partialUpdateEventById, deleteOrganizationById, deleteSchoolById, deleteAdById, getSchools } from '../../utils';
import { useNavigate } from 'react-router-dom';
import ListItemList from '../../components/ListItems/ListItemList';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Page, Title, TopBar, SearchBar, PaginationBar } from '../../components/Pages/PageComponents';


function SchoolsPage(): JSX.Element {
  const [schoolList, setSchoolList] = useState<Array<ItemDisplay>>([]);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [maxPages, setMaxPages] = useState<number>(1);

  const navigate = useNavigate();

  const setSchoolData = async (data: any) => {
    const DisplayResults = data.map((item: any) => ({
      id: item?.id,
      primary: item?.name,
      secondary: `Email Suffix: ${item?.email_suffix}`,
    }));
    setSchoolList(DisplayResults);
  };

  useEffect(() => {
    fetchListData(getSchools,setSchoolData,console.log, {page: pageNumber, setter: setMaxPages});
  }, [pageNumber]);

  /**
   * Delete an item from the list by idx
   */
  const deleteByIdx = async (idx: number, setter: React.Dispatch<React.SetStateAction<Array<ItemDisplay>>>, list: Array<ItemDisplay>) => {
    const newList = list.filter((_, i) => i != idx);
    setter(newList);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {

    // Implement search logic based on the current page
    const newSchoolList = schoolList.map((item, idx) => {
      if ((item.primary).toLowerCase().includes(event.target.value.toLowerCase())) {
        item.dont_show = false;
      } else {
        item.dont_show = true;
      }
      return item;
    });
    setSchoolList(newSchoolList);
  };

  const handleSchoolEdit = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    navigate(`${schoolList[idx].id}/edit`);
  };

  const handleSchoolDelete = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    deleteSchoolById(schoolList[idx].id.toString());
    deleteByIdx(idx, setSchoolList, schoolList);
  };

  const handleSchoolClick = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    navigate(`${schoolList[idx].id}`);
  };

  const handleOnCreateClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    navigate('-1/edit');
  };

  return (

    <Page>
      <Grid container spacing={3} sx={{ width: '100%' }} className='pl-4 pt-4'>
        <SearchBar placeholder='Search for a school' onChange={handleSearch}/>
        <Grid item xs={6} md={4}>
          <Button variant="contained" color="secondary" onClick={handleOnCreateClick}>
            Create
          </Button>
        </Grid>
      </Grid>
      <div className=' py-4'>
        <ListItemList DisplayItems={schoolList} onClick={handleSchoolClick} onEdit={handleSchoolEdit} onDelete={handleSchoolDelete} />
      </div>
      <PaginationBar pageNumber={pageNumber} maxPages={maxPages} setter={setPageNumber}/>
    </Page>
  );
}

export default SchoolsPage;
