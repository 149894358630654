import React, { useEffect, useState } from 'react';
import { ListItem, ListItemText } from '@mui/material';
import { Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FlaggedUsers, UserListItems } from '../../types';
import { deleteUserById, flagView, patchUserById } from '../../utils';
import Flag from '../../assets/flagged-flag.svg';

export interface UserListItemsProps extends UserListItems {
  onChange?: () => void;
}

/**
 * UserListItem Component
 */
function UserListItem(props: UserListItemsProps): JSX.Element {
  const { id, firstName, lastName, is_blocked, is_restricted, creation_date, is_flag, onChange } = props;
  const navigate = useNavigate();
  const date = creation_date.substring(0, 10);
  const dateFormatted = date.replace(/-/g, '/');

  /**
   * Deletes the user from the server
   */
  const handleDelete = async () => {
    const response = await deleteUserById(id.toString());
    if (response.ok) {
      onChange?.();
    }
  };
  /**
  * Handles the block event
  */
  const handleBlock = async () => {
    const response = await patchUserById(id.toString(), { is_blocked: !is_blocked });
    if (response.ok) {
      onChange?.();
    }
  };
  /**
   * Handles the restrict event
  */
  const handleRestrict = async () => {
    const response = await patchUserById(id.toString(), { is_restricted: !is_restricted });
    if (response.ok) {
      onChange?.();
    }
  };

  const handleItemClick = () => {
    navigate(`/admin-app/users/${id}`);
  };

  /**
   * Handles the click event
    */
  const createHandleClick = (button: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (button === 'delete') {
      handleDelete();
    }
    if (button === 'block') {
      handleBlock();
    }
    if (button === 'restrict') {
      handleRestrict();
    }
  };

  return (
    <div className=' bg-primary-300 drop-shadow-2xl rounded-lg' onClick={handleItemClick}>
      <ListItem sx={{
        height: '100%',
        width: '100%',
        margin: '16px 0px 16px 0px',
      }}>
        <img src={Flag} alt="flag" className=' pr-4' style={{ display: is_flag ? 'block' : 'none' }} />
        <ListItemText sx={{
          color: 'secondary.main',
        }} primaryTypographyProps={{ fontSize: '20px' }} primary={`${firstName} ${lastName == null ? '' : lastName} `} secondary={`Creation Date: ${dateFormatted}`} data-testid={`${firstName} ${lastName}`} />

        <Stack spacing={2} direction='row'>
          <Button variant="contained" sx={{
            width: '120px',
            height: '36px',
            bgcolor: is_blocked ? 'warning.main' : 'primary.light',
            color: is_blocked ? 'black' : 'secondary.main',
            borderRadius: '8px',
            ': hover': {
              bgcolor: 'error.main',
              color: 'white'
            },
            typography: {
              fontWeight: 'bold',
              fontSize: '16px',
            }
          }} data-testid="user-list-block" onClick={createHandleClick('block')}>
            {is_blocked ? 'Unblock' : 'Block'}
          </Button>
          <Button variant="contained" sx={{
            width: '120px',
            height: '36px',
            bgcolor: is_restricted ? 'warning.main' : 'primary.light',
            color: is_restricted ? 'black' : 'secondary.main',
            borderRadius: '8px',
            ': hover': {
              bgcolor: 'error.main',
              color: 'white'
            },
            typography: {
              fontWeight: 'bold',
              fontSize: '16px',
            }
          }} data-testid="user-list-restrict" onClick={createHandleClick('restrict')}>
            {is_restricted ? 'Unrestrict' : 'Restrict'}
          </Button>
          <Button
            variant="contained" sx={{
              width: '120px',
              height: '36px',
              bgcolor: 'primary.light',
              color: 'secondary.main',
              borderRadius: '8px',
              ': hover': {
                bgcolor: 'error.main',
                color: 'white'
              },
              typography: {
                fontWeight: 'bold',
                fontSize: '16px',
              }
            }}
            onClick={createHandleClick('delete')} data-testid="user-list-delete">
            Delete
          </Button>
        </Stack>
      </ListItem>
    </div>
  );
}

export default UserListItem;