// Business Analytics Page
// Importing necessary React, Material UI components, CountUp for animated numbers, and custom components
import React, { useState, useEffect } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, useTheme } from '@mui/material';
import CountUp from 'react-countup';
import { Page, Title, TopBar } from '../../components/Pages/PageComponents';
import { getEventsByOrganizationId, cookie } from '../../utils';
import { CookieNames } from '../../constants';

const BusinessAnalytics = () => {
  // Using Material UI theme for consistent styling
  const theme = useTheme();

  // State for storing ad clicks data, initialized with example campaigns
  const [adClicksData, setAdClicksData] = useState({});
  // State for storing event views data, initialized with example events
  const [eventViewsData, setEventViewsData] = useState({});
  // State for storing event names
  const [eventNames, setEventNames] = useState([]);

  const base_url = 'https://api.unione.cc';
  
  // Fetch ad clicks and event views data on component mount
  useEffect(() => {
    // Fetch logged-in organization's events
    const fetchEvents = async () => {
      const orgID = cookie.get(CookieNames.ORG_ID);
      const response = await getEventsByOrganizationId(orgID);

      // Did the request succeed?
      if (response.status === 200) {
        // Get json from response
        const data = await response.json();
        const eventNames = [];
        
        // Extract event name from each event object
        data.forEach(event => {
          eventNames.push(event.name);
        });

        setEventNames(eventNames);
      }
    };

    fetchEvents()
      .then(Promise.all([fetchAnalytics('ad', cookie.get(CookieNames.ORG_ID)), fetchAnalytics('event', cookie.get(CookieNames.ORG_ID))]));
  }, []);

  // General function to fetch either ad or event analytics
  const fetchAnalytics = async (type, orgID) => {
    // Determine the url for the API call
    const url = type === 'ad' ? base_url + `/ad-view-analytics?org_id=${encodeURIComponent(orgID)}` : base_url + `/events-view-analytics?org_id=${encodeURIComponent(orgID)}`;
    console.log(url); 
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      if (type === 'ad') {
        setAdClicksData(prevState => ({ ...prevState, ...data }));
      } else {
        setEventViewsData(prevState => ({ ...prevState, ...data }));
      }
    } catch (error) {
      console.error('Error fetching analytics data');
    }
  };

  // Render the analytics overview page
  return (
    <Page>
      <TopBar>
        <Title variant="h4" component="h1" gutterBottom>
          Analytics Overview
        </Title>
      </TopBar>
      {/* Section for Ad Clicks Analytics */}
      <Box sx={{ width: '100%', p: theme.spacing(3) }}>
        <Typography variant="h5" gutterBottom sx={{ color: theme.palette.primary.dark, fontWeight: 'bold' }}>
          Ad Clicks Analytics
        </Typography>
        <TableContainer component={Paper} sx={{ mb: 4, overflow: 'hidden', borderRadius: '12px' }}>
          {/* Table for displaying ad clicks data */}
          <Table sx={{ minWidth: 650 }} aria-label="ad clicks table">
            <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
              <TableRow>
                <TableCell sx={{ color: theme.palette.primary.contrastText, fontWeight: 'bold', fontSize: '1rem' }}>Ad Campaign</TableCell>
                <TableCell align="right" sx={{ color: theme.palette.primary.contrastText, fontWeight: 'bold', fontSize: '1rem' }}>Clicks</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(adClicksData).map(([adName, clicks]) => (
                <TableRow
                  key={adName}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" sx={{ fontSize: '0.9rem' }}>{adName}</TableCell>
                  <TableCell align="right" sx={{ fontSize: '0.9rem' }}>
                    <CountUp end={clicks} duration={2.75} separator="," />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="h5" gutterBottom sx={{ color: theme.palette.primary.dark, fontWeight: 'bold', mt: 4 }}>
          Event Views Analytics
        </Typography>
        <TableContainer component={Paper} sx={{ mb: 4, overflow: 'hidden', borderRadius: '12px' }}>
          <Table sx={{ minWidth: 650 }} aria-label="event views table">
            <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
              <TableRow>
                <TableCell sx={{ color: theme.palette.primary.contrastText, fontWeight: 'bold', fontSize: '1rem' }}>Event Name</TableCell>
                <TableCell align="right" sx={{ color: theme.palette.primary.contrastText, fontWeight: 'bold', fontSize: '1rem' }}>Views</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(eventViewsData).map(([eventName, views]) => (
                <TableRow
                  key={eventName}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" sx={{ fontSize: '0.9rem' }}>{eventName}</TableCell>
                  <TableCell align="right" sx={{ fontSize: '0.9rem' }}>
                    <CountUp end={views} duration={2.75} separator="," />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Page>
  );
};

export default BusinessAnalytics;
