import './App.css';
import React from 'react';
import {
  BrowserRouter, Routes, Route, Navigate,
} from 'react-router-dom';
import {
  createTheme, ThemeProvider, Box,
} from '@mui/material';
import SignInPage from './pages/SignInPage/SignInPage';
import useUserContext from './hooks/useUserContext';
import AdsPage from './pages/AdsPage/AdsPage';
import EventsPage from './pages/EventsPage/EventPage';
import EventPlannerPage from './pages/EventPlannerPage/EventPlannerPage';
import AuthWrapper from './components/AuthWrapper';
import AdminNavBar from './components/Navbars/AdminNavBar';
import BusinessNavBar from './components/Navbars/BusinessNavBar';
import UsersPage from './pages/UsersPage/UsersPage';
import UserAddEdit from './pages/UsersPage/UserAddEdit';
import UserInfoPage from './pages/UsersPage/UserInfo';
import BusinessEdit from './pages/BusinessesPage/BusinessEdit';
import BusinessPreview from './pages/BusinessesPage/BusinessPreview';
import BusinessesPage from './pages/BusinessesPage/BusinessesPage';
import BusinessEventsPage from './pages/BusinessEventsPage/BusinessEventsPage';
import RequestsPage from './pages/RequestPage/RequestsPage';
import EventEdit from './pages/EventsPage/EventEdit';
import EventPreview from './pages/EventsPage/EventPreview';
import AdsEdit from './pages/AdsPage/AdsEdit';
import AdsPreview from './pages/AdsPage/AdsPreview';
import SchoolsPage from './pages/SchoolsPage/SchoolPage';
import SchoolEdit from './pages/SchoolsPage/SchoolEdit';
import SchoolPreview from './pages/SchoolsPage/SchoolPreview';
import Signups from './pages/EventsPage/EventJoinRequests';
import Attendees from './pages/EventsPage/EventAttendees';
import AdShowtimes from './pages/AdsPage/AdsShowtimes';
import AdsShowtimesEdit from './pages/AdsPage/AdsShowtimesEdit';
import LocationsEdit from './pages/LocationsPage/LocationsEdit';
import LocationsPreview from './pages/LocationsPage/LocationsPreview';
import LocationsPage from './pages/LocationsPage/LocationsPage';
import LocationsGenerate from './pages/LocationsPage/LocationsGenerate';
import AnalyticsDataManagement from './pages/AnalyticsPage/AnalyticsDataManagement';
import AnalyticsDetailPage from './pages/AnalyticsPage/AnalyticsDetailPage';
import AnalyticsOverviewPage from './pages/AnalyticsPage/AnalyticsOverviewPage';
import BusinessAnalytics from './pages/BusinessAnalytics/BusinessAnalytics';
import UserProfile from './pages/Profile/UserProfile';

import tailwindConfig from './tailwind.config';
import resolveConfig from 'tailwindcss/resolveConfig';
const fullConfig = resolveConfig(tailwindConfig);

const primary = fullConfig.theme?.colors?.primary as Record<string, string>;
const secondary = fullConfig.theme?.colors?.secondary as Record<string, string>;
const warning = fullConfig.theme?.colors?.warning as Record<string, string>;
const success = fullConfig.theme?.colors?.success as Record<string, string>;
const danger = fullConfig.theme?.colors?.danger as Record<string, string>;


/**
 * App
 */
function App() {
  const theme = createTheme({
    palette: {
      primary: {
        light: primary['100'],
        main: primary['300'],
        dark: '#9c9a99',
        contrastText: secondary['300'],
      },
      secondary: {
        light: '#fdf2ee',
        main: secondary['300'],
        dark: '#924b33',
        contrastText: primary['100'],
      },
      error: {
        light: '#f9e6e6',
        main: danger['300'],
        dark: '#760000',
      },
      warning: {
        light: '#fefce6',
        main: warning['300'],
        dark: '#978a01',
      },
      success: {
        light: '#e6f9e6',
        main: success['300'],
        dark: '#007600',
      },
    },
    typography: {
      fontFamily: 'Cabin',
    },
  });
  const [userContext] = useUserContext();
  let initialPath = 'sign-in-org';

  if (userContext.isSignedIn) {
    initialPath = userContext.accountType === 'admin' ? 'admin-app' : 'business-app';
  }

  return (
    <ThemeProvider theme={theme}>
      <Box bgcolor="primary.main">
        <BrowserRouter>
          <Routes>
            <Route index element={<Navigate to={initialPath} />} />
            <Route path="sign-up" element={<BusinessEdit variation='signup'/>} />
            <Route path="sign-in-org" element={<SignInPage accountType='business' />} />
            <Route path="sign-in-admin" element={<SignInPage accountType='admin'/>} />
            <Route path="admin-app">
              <Route index element={<Navigate to="users" />} />
              <Route path="locations">
                <Route
                  index
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <AdminNavBar />
                      </div>
                      <div className='content'>
                        <LocationsPage variation='admin' />
                      </div>
                    </div>
                  )}
                />
                <Route
                  path="generate"
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <AdminNavBar />
                      </div>
                      <div className='content'>
                        <LocationsGenerate />
                      </div>
                    </div>
                  )}
                />
                <Route
                  path=":id"
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <AdminNavBar />
                      </div>
                      <div className='content'>
                        <LocationsPreview />
                      </div>
                    </div>
                  )}
                />
                <Route
                  path=":id/edit"
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <AdminNavBar />
                      </div>
                      <div className='content'>
                        <LocationsEdit />
                      </div>
                    </div>
                  )}
                />
              </Route>
              <Route path="users">
                <Route
                  index
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <AdminNavBar />
                      </div>
                      <div className='content'>
                        <UsersPage />
                      </div>
                    </div>
                  )}
                />
                <Route
                  path=":id"
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <AdminNavBar />
                      </div>
                      <div className='content'>
                        <UserInfoPage />
                      </div>
                    </div>
                  )}
                />
                <Route
                  path=":id/edit"
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <AdminNavBar />
                      </div>
                      <div className='content'>
                        <UserAddEdit />
                      </div>
                    </div>
                  )}
                />
              </Route>
              <Route path="businesses">
                <Route
                  index
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <AdminNavBar />
                      </div>
                      <div className='content'>
                        <BusinessesPage />
                      </div>
                    </div>
                  )}
                />
                <Route
                  path=":id"
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <AdminNavBar />
                      </div>
                      <div className='content'>
                        <BusinessPreview />
                      </div>
                    </div>
                  )}
                />
                <Route
                  path=":id/edit"
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <AdminNavBar />
                      </div>
                      <div className='content'>
                        <BusinessEdit />
                      </div>
                    </div>
                  )}
                />
              </Route>

              <Route path="events">
                <Route
                  index
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <AdminNavBar />
                      </div>
                      <div className='content'>
                        <EventsPage />
                      </div>
                    </div>
                  )}
                />
                <Route
                  path=":id"
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <AdminNavBar />
                      </div>
                      <div className='content'>
                        <EventPreview/>
                      </div>
                    </div>
                  )}
                />
                <Route
                  path=":id/edit"
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <AdminNavBar />
                      </div>
                      <div className='content'>
                        <EventEdit/>
                      </div>
                    </div>
                  )}
                />
              </Route>

              <Route path="Analytics">
                <Route
                  index
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <AdminNavBar />
                      </div>
                      <div className='content'>
                        <AnalyticsOverviewPage /> 
                      </div>
                    </div>
                  )}
                />
                <Route
                  path=":id"
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <AdminNavBar />
                      </div>
                      <div className='content'>
                        <AnalyticsDetailPage /> 
                      </div>
                    </div>
                  )}
                />
                <Route
                  path=":id/edit"
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <AdminNavBar />
                      </div>
                      <div className='content'>
                        <AnalyticsDataManagement />
                      </div>
                    </div>
                  )}
                />
              </Route>

              <Route path="ads">
                <Route
                  index
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <AdminNavBar />
                      </div>
                      <div className='content'>
                        <AdsPage />
                      </div>
                    </div>
                  )}
                />
                <Route
                  path=":id"
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <AdminNavBar />
                      </div>
                      <div className='content'>
                        <AdsPreview />
                      </div>
                    </div>
                  )}
                />
                <Route
                  path=":id/edit"
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <AdminNavBar />
                      </div>
                      <div className='content'>
                        <AdsEdit />
                      </div>
                    </div>
                  )}
                />
                <Route path="showtimes">
                  <Route
                    index
                    element={(
                      <div className='page-container'>
                        <div className='navbar'>
                          <AdminNavBar />
                        </div>
                        <div className='content'>
                          <AdShowtimes />
                        </div>
                      </div>
                    )}
                  />
                  <Route
                    path=":id/edit"
                    element={(
                      <div className='page-container'>
                        <div className='navbar'>
                          <AdminNavBar />
                        </div>
                        <div className='content'>
                          <AdsShowtimesEdit />
                        </div>
                      </div>
                    )}
                  />
                </Route>
                
              </Route>

              <Route path="signups">
                <Route
                  path=":id"
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <AdminNavBar />
                      </div>
                      <div className='content'>
                        <Signups />
                      </div>
                    </div>
                  )}
                />
              </Route>

              <Route path="attendees">
                <Route
                  path=":id"
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <AdminNavBar />
                      </div>
                      <div className='content'>
                        <Attendees />
                      </div>
                    </div>
                  )}
                />
              </Route>
                

              <Route path="requests">
                <Route
                  index
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <AdminNavBar />
                      </div>
                      <div className='content'>
                        <RequestsPage />
                      </div>
                    </div>
                  )}
                />

                <Route path="businesses">
                  <Route
                    path=":id"
                    element={(
                      <div className='page-container'>
                        <div className='navbar'>
                          <AdminNavBar />
                        </div>
                        <div className='content'>
                          <BusinessPreview variation='requests'/>
                        </div>
                      </div>
                    )}
                  />
                  <Route
                    path=":id/edit"
                    element={(
                      <div className='page-container'>
                        <div className='navbar'>
                          <AdminNavBar />
                        </div>
                        <div className='content'>
                          <BusinessEdit variation='requests'/>
                        </div>
                      </div>
                    )}
                  />
                </Route>

                <Route path="events">
                  <Route
                    path=":id"
                    element={(
                      <div className='page-container'>
                        <div className='navbar'>
                          <AdminNavBar />
                        </div>
                        <div className='content'>
                          <EventPreview variation='requests'/>
                        </div>
                      </div>
                    )}
                  />
                  <Route
                    path=":id/edit"
                    element={(
                      <div className='page-container'>
                        <div className='navbar'>
                          <AdminNavBar />
                        </div>
                        <div className='content'>
                          <EventEdit variation='requests'/>
                        </div>
                      </div>
                    )}
                  />
                </Route>

                <Route path="ads">
                  <Route
                    path=":id"
                    element={(
                      <div className='page-container'>
                        <div className='navbar'>
                          <AdminNavBar />
                        </div>
                        <div className='content'>
                          <AdsPreview variation='requests'/>
                        </div>
                      </div>
                    )}
                  />
                  <Route
                    path=":id/edit"
                    element={(
                      <div className='page-container'>
                        <div className='navbar'>
                          <AdminNavBar />
                        </div>
                        <div className='content'>
                          <AdsEdit variation='requests'/>
                        </div>
                      </div>
                    )}
                  />
                  <Route path="showtimes">
                    <Route
                      index
                      element={(
                        <div className='page-container'>
                          <div className='navbar'>
                            <AdminNavBar />
                          </div>
                          <div className='content'>
                            <AdShowtimes />
                          </div>
                        </div>
                      )}
                    />
                    <Route
                      path=":id/edit"
                      element={(
                        <div className='page-container'>
                          <div className='navbar'>
                            <AdminNavBar />
                          </div>
                          <div className='content'>
                            <AdsShowtimesEdit />
                          </div>
                        </div>
                      )}
                    />
                  </Route>
                </Route>
              </Route>

              <Route path="schools">
                <Route
                  index
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <AdminNavBar />
                      </div>
                      <div className='content'>
                        <SchoolsPage />
                      </div>
                    </div>
                  )}
                />
                <Route
                  path=":id"
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <AdminNavBar />
                      </div>
                      <div className='content'>
                        <SchoolPreview />
                      </div>
                    </div>
                  )}
                />
                <Route
                  path=":id/edit"
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <AdminNavBar />
                      </div>
                      <div className='content'>
                        <SchoolEdit />
                      </div>
                    </div>
                  )}
                />
              </Route>
              
            </Route>
            <Route path="business-app">
              <Route
                path="profile"
                element={(
                  <div className='page-container'>
                    <div className='navbar'>
                      <BusinessNavBar />
                    </div>
                    <div className='content'>
                      <UserProfile />
                    </div>
                  </div>
                )}
              />
              <Route
                path="analytics"
                element={(
                  <div className='page-container'>
                    <div className='navbar'>
                      <BusinessNavBar />
                    </div>
                    <div className='content'>
                      <BusinessAnalytics />
                    </div>
                  </div>
                )}
              />
              <Route index element={<Navigate to="event-list" />} />
              <Route path="event-list">
                <Route
                  index
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <BusinessNavBar />
                      </div>
                      <div className='content'>
                        <BusinessEventsPage />
                      </div>
                    </div>
                  )}
                />
                <Route path="events">
                  <Route
                    path=":id"
                    element={(
                      <div className='page-container'>
                        <div className='navbar'>
                          <BusinessNavBar />
                        </div>
                        <div className='content'>
                          <EventPreview />
                        </div>
                      </div>
                    )}
                  />
                  <Route
                    path=":id/edit"
                    element={(
                      <div className='page-container'>
                        <div className='navbar'>
                          <BusinessNavBar />
                        </div>
                        <div className='content'>
                          <EventEdit variation='requests'/>
                        </div>
                      </div>
                    )}
                  />
                </Route>
                <Route path="ads">
                  <Route
                    path=":id"
                    element={(
                      <div className='page-container'>
                        <div className='navbar'>
                          <BusinessNavBar />
                        </div>
                        <div className='content'>
                          <AdsPreview />
                        </div>
                      </div>
                    )}
                  />
                  <Route
                    path=":id/edit"
                    element={(
                      <div className='page-container'>
                        <div className='navbar'>
                          <BusinessNavBar />
                        </div>
                        <div className='content'>
                          <AdsEdit variation='proposal'/>
                        </div>
                      </div>
                    )}
                  />
                  <Route path="showtimes">
                    <Route
                      index
                      element={(
                        <div className='page-container'>
                          <div className='navbar'>
                            <BusinessNavBar />
                          </div>
                          <div className='content'>
                            <AdShowtimes />
                          </div>
                        </div>
                      )}
                    />
                    <Route
                      path=":id/edit"
                      element={(
                        <div className='page-container'>
                          <div className='navbar'>
                            <BusinessNavBar />
                          </div>
                          <div className='content'>
                            <AdsShowtimesEdit />
                          </div>
                        </div>
                      )}
                    />
                  </Route>
                </Route>

                <Route path="signups">
                  <Route
                    path=":id"
                    element={(
                      <div className='page-container'>
                        <div className='navbar'>
                          <BusinessNavBar />
                        </div>
                        <div className='content'>
                          <Signups />
                        </div>
                      </div>
                    )}
                  />
                </Route>

                <Route path="attendees">
                  <Route
                    path=":id"
                    element={(
                      <div className='page-container'>
                        <div className='navbar'>
                          <BusinessNavBar />
                        </div>
                        <div className='content'>
                          <Attendees />
                        </div>
                      </div>
                    )}
                  />
                </Route>

              </Route>
              <Route
                path="event-planner"
                element={(
                  <div className='page-container'>
                    <div className='navbar'>
                      <BusinessNavBar />
                    </div>
                    <div className='content'>
                      <EventPlannerPage />
                    </div>
                  </div>
                )}
              />
              <Route path="locations">
                <Route
                  index
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <BusinessNavBar />
                      </div>
                      <div className='content'>
                        <LocationsPage />
                      </div>
                    </div>
                  )}
                />
                <Route
                  path=":id"
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <BusinessNavBar />
                      </div>
                      <div className='content'>
                        <LocationsPreview />
                      </div>
                    </div>
                  )}
                />
                
                <Route
                  path=":id/edit"
                  element={(
                    <div className='page-container'>
                      <div className='navbar'>
                        <BusinessNavBar />
                      </div>
                      <div className='content'>
                        <LocationsEdit />
                      </div>
                    </div>
                  )}
                />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </Box>
    </ThemeProvider>
  );
}

export default App;