import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DisplayField, DateTimeState, Payload, PageOptions } from '../../types';
import { findFieldValue, validateDateString, updateFieldInfo, getEventById, updateDateInfo, getDictInfo, convertDateTimeState, partialUpdateEventById } from '../../utils';
import { StyledButton, ButtonGroup } from '../../components/Buttons/StyledButton';
import { EditableText, NonEditableText } from '../../components/Text/TextComponents';
import { createNavigateBack, fetchData, saveData, updateUserById, createUser, getUserById } from '../../utils';
import { Page, Title, TopBar } from '../../components/Pages/PageComponents';
import AlertDialog from '../../components/Modals/Alert';

/**
 * 
 */
function UserAddEdit() {
  const { id = '-1' } = useParams();
  const [nonEditDictionary, setNonEditFieldDictionary] = useState<DisplayField[]>([]);
  const [fieldDictionary, setFieldDictionary] = useState<DisplayField[]>([]);
  const navigate = useNavigate();
  const navigateBack = createNavigateBack(navigate, '/admin-app/users');

  useEffect(() => {
    fetchData(id, getUserById, setData, console.log);
  }, []);

  // Set data to null for create mode
  const setData = (data: any) => {
    const newNonEditFields: DisplayField[] = [
      { name: 'Display Name', value: data ? `${data.firstName} ${data.lastName}` : 'New User', dont_display: true },
      { name: 'First Name', value: data?.firstName || '', dont_display: id === '-1' },
      { name: 'Last Name', value: data?.lastName || '', dont_display: id === '-1' },
      { name: 'Email', value: data?.email || '', dont_display: id === '-1' },
    ];

    const newFields: DisplayField[] = [
      { name: 'First Name', value: data?.firstName || '', dont_display: id !== '-1' },
      { name: 'Last Name', value: data?.lastName || '', dont_display: id !== '-1' },
      { name: 'Email', value: data?.email || '', dont_display: id !== '-1' },
      { name: 'Preferred Name', value: data?.preferredName || '' },
      { name: 'Pronouns', value: data?.pronouns || '' },
      { name: 'Major', value: data?.major || '' },
      { name: 'Year', value: data?.year || '', type: 'number' },
      { name: 'Bio', value: data?.bio || '', big: true },
    ];

    updateFieldInfo(newFields, setFieldDictionary,false);
    updateFieldInfo(newNonEditFields, setNonEditFieldDictionary,false);
  };
  


  const [isDialogOpen, setDialogOpen] = useState({title: '', message: ''});
  const closeDialog = (e: React.MouseEvent<HTMLElement>) => {
    setDialogOpen({title: '', message: ''});
  };

  const handleError = async (response: Response) => {
    console.log(response);
    if (response.body) {
      const data = await response.json();
      setDialogOpen({title: data?.title || 'Unknown Problem', message: data?.message || 'An unknown problem occured on submission'});
    } else {
      setDialogOpen({title: 'Unknown Problem', message: 'An unknown problem occured on submission'});
    }
  };

  const handleSuccess = (response: Response) => {
    navigate(-1);
  };



  const handleSave = (e: React.MouseEvent<HTMLElement>) => {
    updateFieldInfo(fieldDictionary, setFieldDictionary, true);
    e.preventDefault();
    console.log(fieldDictionary);
    console.log(nonEditDictionary);
    const submitData: Payload = {
      'preferredName': getDictInfo<DisplayField>(fieldDictionary, 'Preferred Name')?.value,
      'pronouns': getDictInfo<DisplayField>(fieldDictionary, 'Pronouns')?.value,
      'major': getDictInfo<DisplayField>(fieldDictionary, 'Major')?.value,
      'year': getDictInfo<DisplayField>(fieldDictionary, 'Year')?.value,
      'bio': getDictInfo<DisplayField>(fieldDictionary, 'Bio')?.value,
    };
    if (id==='-1') {
      submitData['firstName'] = getDictInfo<DisplayField>(fieldDictionary, 'First Name')?.value;
      submitData['lastName'] = getDictInfo<DisplayField>(fieldDictionary, 'Last Name')?.value;
      submitData['email'] = getDictInfo<DisplayField>(fieldDictionary, 'Email')?.value;
      submitData['finishedSetup'] = true;
    }
    console.log(submitData);
    saveData(id, submitData, updateUserById, createUser, handleError, handleSuccess);
  };

  const handleChanges = (key: string, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const Field = getDictInfo<DisplayField>(fieldDictionary, key);
    if (Field){
      Field.value = e.target.value;
      console.log(Field);
      updateFieldInfo([Field], setFieldDictionary,false);
    }
  };


  return (
    <Page>
      <AlertDialog title={isDialogOpen.title} open={isDialogOpen.message!==''} message={isDialogOpen.message} onClose={closeDialog}/>
      <TopBar>
        <Title>
          {findFieldValue(nonEditDictionary, 'Display Name')}
        </Title>
        <ButtonGroup>
          <StyledButton variant='secondary' onClick={handleSave}>Submit</StyledButton>
          <div className='pl-4'>
            <StyledButton onClick={() => navigateBack()}>Cancel</StyledButton>
          </div>
        </ButtonGroup>
      </TopBar>
      <NonEditableText fields={nonEditDictionary} />
      <EditableText fields={fieldDictionary} onChange={handleChanges} />
    </Page>
  );
}

export default UserAddEdit;