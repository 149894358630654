import Cookie from 'universal-cookie';
import { CookieNames } from './constants';
import { DisplayField, DateTimeState } from './types';
import { NavigateFunction } from 'react-router-dom';


//const base_url = "http://[2605:fd00:4:1001:f816:3eff:fe0b:a11b]";
const base_url = 'https://api.unione.cc'; //'http://192.168.178.2:8000';

export function formatImageUrl(url: string) {
  return `${base_url}${url}`;
}

export function formatDateToYYYYMMDD(date: Date): string {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;  // getMonth() returns 0-11
  const day = date.getDate();

  // Pad the month and day with a leading zero if they are less than 10
  const formattedMonth = month < 10 ? `0${month}` : month.toString();
  const formattedDay = day < 10 ? `0${day}` : day.toString();

  return `${year}-${formattedMonth}-${formattedDay}`;
}

/**
 * make an Authentication API call and return the http response
 * @param email 
 * @param password 
 * @returns 
 */
export async function authenticate(email: string, password: string, type: string) {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email, password })
  };
  return await fetch(base_url + `/logins/login?type=${type}`, options);
}

export function formatDateString(DateString: string | null | undefined): string {

  // Check if the date string is valid
  const date = new Date(DateString || '');
  if (isNaN(date.getTime())) {
    // The date is not valid
    return 'an unknown date and time';
  }

  // Converts to a human-readable form
  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true  // Use 12-hour time with AM/PM
  });


  return dateFormatter.format(date);
}

export function formatDate(inputDate: string): string {
  const [year, month, day] = inputDate.split('-').map(Number);

  // Parse the date string into a Date object
  const date = new Date(year, month - 1, day);

  // Options for toLocaleDateString to format the date
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short', // 'short' for abbreviated day of the week
    year: 'numeric',
    month: 'long', // 'long' for full month name
    day: 'numeric'
  };

  // Format the date
  return date.toLocaleDateString('en-US', options);
}


export function formatTimeString(DateString: string | null | undefined): string {

  // Check if the date string is valid
  const date = new Date(DateString || '');
  if (isNaN(date.getTime())) {
    // The date is not valid
    return 'an unknown time';
  }

  // Converts to a human-readable form
  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true  // Use 12-hour time with AM/PM
  });


  return dateFormatter.format(date);
}

export function validateDateString(DateString: string | null | undefined): Date | null {
  const dateObj = new Date(DateString || '');
  return isNaN(dateObj.getTime()) ? null : dateObj;
}

/**
 * Set cookie values in the same path
 */
function setCookie(key: string, value: any, options?: { domain?: string }) {
  const cookie = new Cookie();

  cookie.set(key, value, {
    secure: true,
    sameSite: 'strict',
    path: '/',
    ...options
  });
}

/**
 * Get cookie by key
 */
function getCookie(key: string): any {
  const cookie = new Cookie();

  return cookie.get(key);
}

/**
 * Remove a cookie value by id
 */
function removeCookie(key: string, options?: { path?: string, domain?: string }) {
  const cookie = new Cookie();

  cookie.remove(key, options);
}

export const cookie = {
  set: setCookie,
  get: getCookie,
  remove: removeCookie
};


/**
 * authenticate with auth token
 */
export async function tokenAuth() {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    }
  };

  return await fetch(base_url + '/logins/token-auth', options);
}

/**
 * Get a list of organizations
 */
export async function getOrganizationList(page?: number) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    }
  };

  return await fetch(base_url + `/organizations/organization?pending=False&rejected=False${page ? `&page=${page}` : ''}`, options);
}

/**
 * Get an organization's details by id
 */
export async function getOrganizationById(id: string) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    }
  };

  return await fetch(base_url + `/organizations/organization?id=${id}`, options);
}

/**
 * Create an organization
 */
export async function createOrganization(account: any) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
    method: 'PUT',
    body: JSON.stringify(account)
  };
  console.log('sending request');
  return await fetch(base_url + '/organizations/organization', options);
}

export async function getTags() {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
    method: 'GET'
  };
  return await fetch(base_url + '/tags', options);
}

export async function generateLocations(data:any) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(data)
  };
  return await fetch(base_url + '/locations/?type=tags', options);
}

export async function createLocations(data:any) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(data)
  };
  return await fetch(base_url + '/locations/?type=create', options);
}

export async function editLocationsById(id: string, account: any) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
    method: 'PATCH',
    body: JSON.stringify(account)
  };
  return await fetch(base_url + `/locations/${id}`, options);
}

export async function getLocationsById(id: string) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
    method: 'GET',
  };
  return await fetch(base_url + `/locations/${id}`, options);
}

/**
 * Create an organization request
 */
export async function createOrganizationRequest(account: any) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
    method: 'PUT',
    body: JSON.stringify(account)
  };
  console.log('sending request for signup');
  return await fetch(base_url + '/organizations/signup', options);
}

/**
 * Update an organization by id
 */
export async function updateOrganizationById(id: string, organization: any) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
    method: 'PATCH',
    body: JSON.stringify(organization)
  };
  console.log(JSON.stringify(organization));
  return await fetch(base_url + `/organizations/organization/${id}`, options);
}

/**
 * Partial update an organization by id
 */
export async function partialUpdateOrganizationById(id: string, organization: any) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
    method: 'PATCH',
    body: JSON.stringify(organization)
  };

  return await fetch(base_url + `/organizations/organization/${id}`, options);
}

/**
 * Partial update an event by id
 */
export async function partialUpdateEventById(id: string, organization: any) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
    method: 'PATCH',
    body: JSON.stringify(organization)
  };
  console.log(options.body);
  return await fetch(base_url + `/events/event/${id}`, options);
}

/**
 * Delete an organization by id
 */
export async function deleteOrganizationById(id: string) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
    method: 'DELETE'
  };

  return await fetch(base_url + `/organizations/organization/${id}`, options);
}


/**
 * Get a list of organizations requests
 */
export async function getSignUpRequests(page?: number) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    }
  };

  return await fetch(base_url + `/organizations/organization?pending=True&rejected=False${page ? `&page=${page}` : ''}`, options);
}

/**
 * Get a list of event requests
 */
export async function getEventRequests(page?: number) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    }
  };

  return await fetch(base_url + `/events/event?proposal=True&rejected=False${page ? `&page=${page}` : ''}`, options);
}

/**
 * Get an event by id
 */
export async function getEventById(id: string) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
  };
  return await fetch(base_url + `/events/event?id=${id}`, options);
}

/**
 * Get a join request by id
 */
export async function getJoinRequestById(id: string) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
  };
  return await fetch(base_url + `/events/event_pending?id=${id}`, options);
}

/**
 * Partial update an event by id
 */
export async function updateJoinRequestById(id: string, joinRequest: any) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
    method: 'PATCH',
    body: JSON.stringify(joinRequest)
  };
  console.log(JSON.stringify(joinRequest));
  return await fetch(base_url + `/events/event_pending/${id}`, options);
}

/**
 * Partial update an event by id
 */
export async function deleteJoinRequestById(id: string) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
    method: 'DELETE'
  };
  return await fetch(base_url + `/events/event_pending/${id}`, options);
}

/**
 * Get all join requests
 */
export async function getJoinRequests(page?: number) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
  };
  return await fetch(base_url + `/events/event_pending?was_rejected=False&was_accepted=False&sort=id${page ? `&page=${page}` : ''}`, options);
}

export async function getJoinRequestsByEvent(id: string, page?: number) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
  };
  return await fetch(base_url + `/events/event_pending?was_rejected=False&was_accepted=False&sort=id&event_id=${id}${page ? `&page=${page}` : ''}`, options);
}

export async function getAttendeesByEvent(id: string, page?: number) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
  };
  return await fetch(base_url + `/events/event_pending?was_rejected=False&was_accepted=True&sort=id&event_id=${id}${page ? `&page=${page}` : ''}`, options);
}

export async function getJoinRequestsByEventCount(id: string) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
  };
  return await fetch(base_url + `/events/event_pending?was_rejected=False&was_accepted=False&event_id=${id}&count_only=True`, options);
}

export async function getJoinRequestsByEventAttendedCount(id: string) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
  };
  return await fetch(base_url + `/events/event_pending?was_rejected=False&was_accepted=True&has_attended=True&event_id=${id}&count_only=True`, options);
}

export async function getJoinRequestsByEventAbsentCount(id: string) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
  };
  return await fetch(base_url + `/events/event_pending?was_rejected=False&was_accepted=True&has_attended=False&event_id=${id}&count_only=True`, options);
}

/**
 * Get an event by organization id
 */
export async function getEventsByOrganizationId(id: string, page?: number) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
  };
  return await fetch(base_url + `/events/event?org_id=${id}&rejected=False${page ? `&page=${page}` : ''}`, options);
}

/**
 * Get an ads by organization id
 */
export async function getAdsByOrganizationId(id: string, page?: number) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
  };
  return await fetch(base_url + `/ads/ad?org_id=${id}&rejected=False${page ? `&page=${page}` : ''}`, options);
}

/**
 * Get ad showtimes by ad id
 */
export async function getShowtimesByAdId(id: string, type: string, page?: number) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
  };
  return await fetch(base_url + `/ads/showtimes?ad_id=${id}&type=${type}${page ? `&page=${page}` : ''}`, options);
}

/**
 * Create ad showtime 
 */
export async function createShowtimes(showtime: any) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
    method: 'PUT',
    body: JSON.stringify(showtime)
  };
  console.log('sending request');
  return await fetch(base_url + '/ads/showtimes', options);
}

/**
 * Update ad showtime 
 */
export async function updateShowtimesById(id: string, showtime: any) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
    method: 'PATCH',
    body: JSON.stringify(showtime)
  };
  console.log('sending request');
  return await fetch(base_url + `/ads/showtimes/${id}`, options);
}

/**
 * Get an ad showtime by id
 */
export async function getShowtimesById(id: string) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
  };
  return await fetch(base_url + `/ads/showtimes?id=${id}`, options);
}

/**
 * Delete an ad showtime by id
 */
export async function deleteShowtimesById(id: string) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
    method: 'DELETE'
  };
  return await fetch(base_url + `/ads/showtimes/${id}`, options);
}

export async function getAdsById(id: string) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
  };
  return await fetch(base_url + `/ads/ad?id=${id}&rejected=False`, options);
}


/**
 * Delete an event by id
 */
export async function deleteEventById(id: string) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
    method: 'DELETE'
  };
  return await fetch(base_url + `/events/event/${id}`, options);
}

/**
 * Get events
 */
export async function getEvents(page?: number) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';

  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
  };
  return await fetch(base_url + `/events/event?proposal=False&rejected=False${page ? `&page=${page}` : ''}`, options);
}

/**
 * Get a list of ad requests
 */
export async function getAdRequests(page?: number) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
  };

  return await fetch(base_url + `/ads/ad?pending=True&rejected=False${page ? `&page=${page}` : ''}`, options);
}

export async function getAds(page?: number) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
  };

  return await fetch(base_url + `/ads/ad?pending=False&rejected=False${page ? `&page=${page}` : ''}`, options);
}

export async function updateAdById(id: string, ad: any) {
  const formData = new FormData();

  // Append the fields from the adData object to the FormData
  for (const key in ad) {
    formData.append(key, ad[key]);
  }

  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
    method: 'POST',
    body: formData
  };

  return await fetch(base_url + `/ads/ad/${id}`, options);
}

/**
 * Delete an ad by id
 */
export async function deleteAdById(id: string) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
    method: 'DELETE'
  };

  return await fetch(base_url + `/ads/ad/${id}`, options);
}

export async function createAd(ad: any) {
  const formData = new FormData();

  // Append the fields from the adData object to the FormData
  for (const key in ad) {
    formData.append(key, ad[key]);
  }

  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
    method: 'POST',
    body: formData
  };

  return await fetch(base_url + '/ads/ad', options);
}

/**
 * Get a list of users
 */
export async function getUserList(page?: number) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
  };
  return await fetch(base_url + `/flags/users_with_flags?is_admin=False&sort=id${page ? `&page=${page}` : ''}`, options);
}

export async function getLocations(page?: number) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
  };
  return await fetch(base_url + `/locations/?sort=id${page ? `&page=${page}` : ''}`, options);
}

export async function deleteLocationsById(id: string) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
    method: 'DELETE'
  };
  return await fetch(base_url + `/locations/${id}`, options);
}

/**
 * Get a list of restricted users
 */
export async function getRestrictedUserList(page?: number) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
  };
  return await fetch(base_url + `/flags/users_with_flags?is_admin=False&is_restricted=True&sort=id${page ? `&page=${page}` : ''}`, options);
}

/**
 * Get list of flagged users
 */
export async function getFlaggedUsers(page?: number) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`
    },
  };
  return await fetch(base_url + `/flags/users_with_flags?is_admin=False&is_flag=True&sort=id${page ? `&page=${page}` : ''}`, options);
}

/**
 * Get the user with a flag
 */
export async function getFlaggedUsersById(id: string) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`
    },
  };
  return await fetch(base_url + `/flags/users_with_flags?is_admin=False&id=${id}`, options);
}

/**
 * Get list of blocked users
 */
export async function getBlockedUsers(page?: number) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`
    },
  };
  return await fetch(base_url + `/flags/users_with_flags?is_admin=False&is_blocked=True&sort=id${page ? `&page=${page}` : ''}`, options);
}

/**
 * Get a user by id
 */
export async function getUserById(id: string) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
  };
  return await fetch(base_url + `/users/${id}`, options);
}

/**
 * Deletes the user by id
 */
export async function deleteUserById(id: string) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
    method: 'DELETE'
  };
  return await fetch(base_url + `/users/${id}`, options);
}

/**
 * Update the user by id
 */
export async function updateUserById(id: string, user: any) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify(user),
  };
  return await fetch(base_url + `/users/${id}`, options);
}

/**
 * Add an user
 */
export async function createUser(user: any) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(user),
  };
  return await fetch(base_url + '/users/', options);
}

/**
 * Add an event
 */
export async function createEvent(user: any) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(user),
  };
  return await fetch(base_url + '/events/event', options);
}

/**
 * Patch user by id
 */
export async function patchUserById(id: string, user: any) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`
    },
    method: 'PATCH',
    body: JSON.stringify(user),
  };
  return await fetch(base_url + `/users/${id}`, options);
}

/**
 * Delete flag by id
*/
export async function deleteFlagById(id: string) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`
    },
    method: 'DELETE'
  };
  return await fetch(base_url + `/flags/delete_flag/${id}`, options);
}

/**
 * Delete flag by id
*/
export async function deleteFlagByUserId(id: string) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`
    },
    method: 'DELETE'
  };
  return await fetch(base_url + `/flags/delete_user_flag/${id}`, options);
}


/**
 * Flag view
 */
export async function flagView(page?: number) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`
    },
  };
  return await fetch(base_url + `/flags/flags${page ? `&page=${page}` : ''}`, options);
}

/**
 * Flag view
 */
export async function flagsByUserId(id: string, page?: number) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`
    },
  };
  return await fetch(base_url + `/flags/flags?user_id=${id}${page ? `&page=${page}` : ''}`, options);
}

// In case fields are used for display info storage instead of with the Text components
export function findFieldValue(fields: DisplayField[], fieldName: string): string | number | boolean | null | undefined{
  const displayNameField = fields.find((field) => field.name === fieldName);
  return displayNameField?.value;
}

// This is a special function to handle display data. It's generalized so pretty much any page can use it.
export const updateFieldInfo = (data: DisplayField[] | null | undefined, setFieldDictionary: React.Dispatch<React.SetStateAction<DisplayField[]>>, convert: boolean ) => {
  console.log(data);
  if (!data) {
    // Do nothing if data doesn't exist. The null or undefined is for stuff
    return;
  }

  setFieldDictionary((prevFields) => {
    const updatedFields = [...prevFields];

    // Iterate through data properties
    for (const fieldIndex in data) {
      if (convert) {
        data[fieldIndex].value = convertType(data[fieldIndex].value, data[fieldIndex].type);
      }

      const fieldName = data[fieldIndex].name;
      // Find the field with the same name in updatedFields
      const matchingFieldIndex = updatedFields.findIndex((field) => field.name === fieldName);

      // If the field exists in updatedFields, update it; otherwise, add it
      if (matchingFieldIndex !== -1 && data[fieldIndex].value !== undefined) {
        updatedFields[matchingFieldIndex] = data[fieldIndex];
      } else {
        updatedFields.push(data[fieldIndex]);
      }
    }

    return updatedFields;
  });
};

// This converts between types depending on what the type value is.
export function convertType(Value: any, type: string = 'string'): any {

  // Define a dictionary of type conversion functions
  const typeConversionFunctions: Record<string, (value: any) => any> = {
    string: (value: any) => String(value),
    number: (value: any) => Number(value),
    boolean: (value: any) => Boolean(value),
  };

  // Get the conversion function based on the provided type
  const conversionFunction = typeConversionFunctions[type];

  // If a valid conversion function is found, apply it to the Value
  if (conversionFunction) {
    return conversionFunction(Value);
  }

  // Default: return Value as is
  return Value;
}

// This is a special function to handle display data. It's generalized so pretty much any page can use it.
export const updateDateInfo = (data: DateTimeState[] | null | undefined, setFieldDictionary: React.Dispatch<React.SetStateAction<DateTimeState[]>> ) => {
  console.log(data);
  if (!data) {
    // Do nothing if data doesn't exist. The null or undefined is for stuff
    return;
  }

  setFieldDictionary((prevFields) => {
    const updatedFields = [...prevFields];

    // Iterate through data properties
    for (const fieldIndex in data) {

      const fieldName = data[fieldIndex].name;
      // Find the field with the same name in updatedFields
      const matchingFieldIndex = updatedFields.findIndex((field) => field.name === fieldName); // Should probs be changed to be hashable in the future

      // If the field exists in updatedFields, update it; otherwise, add it
      if (matchingFieldIndex !== -1) {
        updatedFields[matchingFieldIndex] = data[fieldIndex];
      } else {
        updatedFields.push(data[fieldIndex]);
      }
    }

    return updatedFields;
  });
};

// This is a special function to handle display data. It's generalized so pretty much any page can use it.
export const getDictInfo = <T extends { name: string }>(data: T[] | null | undefined, key: string): T | null => {
  console.log(data);
  if (!data) {
    // return null if data doesn't exist, may be used later for alerts
    return null;
  }
  const matchingFieldIndex = data.findIndex((field) => field.name === key); // Should be hashable later for performance

  if (matchingFieldIndex !== -1) {
    return data[matchingFieldIndex];
  } else {
    return null;
  }
};

const isValidDate = (d: any): d is Date => {
  return d instanceof Date && !isNaN(d.getTime());
};

export const convertDateTimeState = (inp: DateTimeState | null): string | null => {
  const TimeObj = inp?.time;
  const DateObj = inp?.date;

  if (TimeObj && DateObj) {
    const result = new Date(DateObj.getTime());
    result.setHours(TimeObj.getHours(), TimeObj.getMinutes(), TimeObj.getSeconds(), TimeObj.getMilliseconds());
    if (isValidDate(result)) {
      console.log(result.toISOString());
      return result.toISOString();
    }
  }
  return null; // This is planned for validation checking
};

export function createNavigateBack(navigate: NavigateFunction, defaultPath: string)
{
  return () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(defaultPath);
    }
  };
}

export const fetchData = async (
  id: string,
  getData: (id: string) => Promise<Response>,
  onSuccess: (data: any | null) => void,
  onBadResponse: (response: Response) => void
) => {
  if (id === '-1') {
    onSuccess(null);
  } else {
    const response = await getData(id);
    if (response.ok) {
      const contentType = response.headers.get('Content-Type');
      
      let data;
      if (contentType && contentType.includes('application/json')) {
        data = await response.json();
      } else if (contentType && (contentType.includes('multipart/form-data') || contentType.includes('application/x-www-form-urlencoded'))) {
        data = await response.formData();
      } else {
        data = await response.text();
      }
      onSuccess(data);
    } else {
      onBadResponse(response);
    }
  }
};

// This is a generic one for submitting edit form data
// Used because eventually alerts will be added, and it'd be faster to just add them to the function
export const saveData = async (
  id: string,
  submitData: any,
  onSave: (id: any, submitData: any) => Promise<Response>,
  onCreate: (submitData: any) => Promise<Response>,
  onError: (response: Response) => void,
  onSuccess: (response: Response) => void
) => {
  console.log(submitData);
  if (id === '-1') {
    const response = await onCreate(submitData);
    if (response.ok) {
      onSuccess(response);
    } else {
      onError(response);
    }
  } else {
    const response = await onSave(id, submitData);
    if (response.ok) {
      onSuccess(response);
    } else {
      onError(response);
    }
  }
};

export const fetchListData = async (
  getData: (page?: number) => Promise<Response>,
  onSuccess: (data: any | null) => void,
  onBadResponse: (response: Response) => void,
  pageDict?: {page: number, setter: React.Dispatch<React.SetStateAction<number>>}
) => {
  const response = await getData(pageDict?.page);
  if (response.ok) {
    const contentType = response.headers.get('Content-Type');

    const data = await response.json();
    if (pageDict) {
      onSuccess(data['data']);
      pageDict.setter(data['total_pages']);
    } else {
      onSuccess(data);
    }
  } else {
    onBadResponse(response);
  }
};

/**
 * Get the summary data for a given week day
 */
export async function getSummaryData(dayOfWeek: string) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
    method: 'GET'
  };
  
  return await fetch(base_url + `/user-count-per-hour/${dayOfWeek}/`, options);
}

/**
 * Get the summary overview data
 */
export async function getSummaryOverview() {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
    method: 'GET'
  };
  
  return await fetch(base_url + '/user-count-overview', options);
}


/**
 * Accept all events
 */
export async function acceptAllEvents() {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  };
  return await fetch(base_url + '/events/accept_all', options);
}

/**
 * Accept all ads
 */
export async function acceptAllAds() {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  };
  return await fetch(base_url + '/ads/accept_all', options);
}

/**
 * Accept all organizations
 */
export async function acceptAllOrganizations() {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  };
  return await fetch(base_url + '/organizations/accept_all', options);
}

/**
 * Update the school by id
 */
export async function updateSchoolById(id: string, school: any) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify(school),
  };
  return await fetch(base_url + `/schools/school/${id}`, options);
}

/**
 * Add a school
 */
export async function createSchool(school: any) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(school),
  };
  return await fetch(base_url + '/schools/school', options);
}

/**
 * Delete a school by id
 */
export async function deleteSchoolById(id: string) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
    method: 'DELETE'
  };

  return await fetch(base_url + `/schools/school/${id}`, options);
}

/**
 * Get an school by id
 */
export async function getSchoolById(id: string) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';
  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
  };
  return await fetch(base_url + `/schools/school?id=${id}`, options);
}

/**
 * Get Schools
 */
export async function getSchools(page?: number) {
  const authToken = cookie.get(CookieNames.AUTH_TOKEN) || '';

  const options: RequestInit = {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    },
  };
  return await fetch(base_url + `/schools/school${page ? `?page=${page}` : ''}`, options);
}