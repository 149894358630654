// Added File for overview of analytics

// Importing necessary modules and components from React, Material UI, Recharts, and custom components
import React, { useState, useEffect } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, useTheme } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, Legend } from 'recharts';
import CountUp from 'react-countup';
import { Page, Title, TopBar } from '../../components/Pages/PageComponents';

// Component for displaying the analytics overview page
const AnalyticsOverviewPage = () => {
  // State for storing the page view analytics data
  const [analyticsData, setAnalyticsData] = useState([]);
  // State for storing the subscription analytics data, initialized with default values
  const [subscriptionAnalytics, setSubscriptionAnalytics] = useState({ purchase_revenue: 0, subscriptions_purchased: 0 });
  // Using the theme from Material UI for consistent styling
  const theme = useTheme();

  const base_url = 'https://api.unione.cc';

  useEffect(() => {
    const pageViewController = new AbortController();
    const subViewController = new AbortController();
    console.log('Effect is running...');

    const fetchPageViewAnalytics = async () => {
      try {
        console.log(1);
        const response = await fetch(base_url + '/page-view-analytics', pageViewController.signal);
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        // Processing the fetched data into a format suitable for display
        const dataArray = [
          { page: 'Home', views: data.home_page_views, users: data.home_page_users },
          { page: 'Pricing', views: data.pricing_page_views, users: data.pricing_page_users },
          { page: 'About', views: data.about_page_views, users: data.about_page_users },
          { page: 'Resources', views: data.resources_page_views, users: data.resources_page_users },
        ];
          // Setting the processed data into the state
        setAnalyticsData(dataArray);
      } catch (error) {
        console.error('Error fetching analytics data: ', error);
      }
    };
    // Async function to fetch subscription analytics data
    const fetchSubscriptionAnalytics = async () => {
      try {
        console.log(2);
        const response = await fetch(base_url + '/subscription-view-analytics', subViewController.signal);
        if (!response.ok) throw new Error('Network response was not ok for subscription analytics');
        const data = await response.json();
        console.log('Sub view analytics', data);

        setSubscriptionAnalytics(data); 
      } catch (error) {
        console.error('Error fetching subscription analytics data: ', error);
      }
    };

    fetchPageViewAnalytics()
      .then(fetchSubscriptionAnalytics);
    
    return () => {
      subViewController.abort();
      pageViewController.abort();
    };
  }, []);
  // Render the analytics overview UI
  return (
    <Page>
      <TopBar>
        <Title>Analytics Overview</Title>
      </TopBar>
      <Box sx={{ width: '100%', p: theme.spacing(3) }}>
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.primary.dark }}>
          Page Views and Active Users
        </Typography>
        <TableContainer component={Paper} sx={{ mb: 4, overflow: 'hidden', borderRadius: '12px' }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
              <TableRow>
                <TableCell sx={{ color: theme.palette.primary.contrastText }}>Page</TableCell>
                <TableCell align="right" sx={{ color: theme.palette.primary.contrastText }}>Page Views</TableCell>
                <TableCell align="right" sx={{ color: theme.palette.primary.contrastText }}>Active Users</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {analyticsData.map((row) => (
                <TableRow
                  key={row.page}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{row.page}</TableCell>
                  <TableCell align="right">{row.views}</TableCell>
                  <TableCell align="right">{row.users}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.primary.dark }}>
          Graphical View of Page Views
        </Typography>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            layout="vertical"
            data={analyticsData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#eceff1" />
            <XAxis type="number" stroke={theme.palette.text.secondary} />
            <YAxis dataKey="page" type="category" width={80} stroke={theme.palette.text.secondary} />
            <Tooltip wrapperStyle={{ borderColor: '#eceff1', boxShadow: '0px 0px 15px rgba(0,0,0,0.1)' }} />
            <Legend />
            <Bar dataKey="views" fill="#FAB278" name="Page Views" />
          </BarChart>
        </ResponsiveContainer>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.dark, mt: 4, fontWeight: 'bold' }}>
            Total Purchase Revenue:
            <CountUp start={0} end={subscriptionAnalytics.purchase_revenue} duration={2.75} prefix="$" separator="," />
          </Typography>
          <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.dark, fontWeight: 'bold' }}>
            Subscriptions Purchased:
            <CountUp start={0} end={subscriptionAnalytics.subscriptions_purchased} duration={2.75} separator="," />
          </Typography>
        </Box>
      </Box>
    </Page>
  );
};
export default AnalyticsOverviewPage;