import React, { useState, useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import { ItemDisplay } from '../../types';
import { deleteLocationsById, formatImageUrl, getLocations, fetchListData } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { Page, SearchBar, PaginationBar } from '../../components/Pages/PageComponents';
import { PageOptions } from '../../types';
import ImageItemList from '../../components/ListItems/ImageItemList';


/**
 * Loc Page
 */
function LocationsPage({ variation = 'default' }: PageOptions) {
  const [LocList, setLocList] = useState<Array<ItemDisplay>>([]);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [maxPages, setMaxPages] = useState<number>(1);

  const navigate = useNavigate();

  const setLocData = async (data: any) => {
    const DisplayResults = data.map((item: any) => ({
      id: item?.id,
      primary: item?.name,
      secondary: `Tags: ${item?.tags_names && item?.tags_names.length > 0 ? item.tags_names.join(', ') : 'None'}`,
      img: formatImageUrl(item?.image),
    }));
    console.log(data);
    console.log(DisplayResults);
    setLocList(DisplayResults);
  };

  useEffect(() => {
    fetchListData(getLocations,setLocData,console.log, {page: pageNumber, setter: setMaxPages});
  }, [pageNumber]);

  /**
   * Delete an item from the list by idx
   */
  const deleteByIdx = async (idx: number, setter: React.Dispatch<React.SetStateAction<Array<ItemDisplay>>>, list: Array<ItemDisplay>) => {
    const newList = list.filter((_, i) => i != idx);
    setter(newList);
  };

  const handleLocEdit = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    navigate(`${LocList[idx].id}/edit`);
  };

  const handleLocDelete = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    deleteLocationsById(LocList[idx].id.toString());
    deleteByIdx(idx, setLocList, LocList);
  };

  const handleLocClick = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    navigate(`${LocList[idx].id}`);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // Implement search logic based on the current page
    const newLocList = LocList.map((item, idx) => {
      if ((item.primary).toLowerCase().includes(event.target.value.toLowerCase())) {
        item.dont_show = false;
      } else {
        item.dont_show = true;
      }
      return item;
    });
    setLocList(newLocList);
  };

  const handleOnCreateClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    navigate('-1/edit');
  };

  const handleOnGenerateClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    navigate('generate');
  };

  return (

    <Page>
      <Grid container spacing={3} sx={{ width: '100%' }} className='pl-4 pt-4'>
        <SearchBar placeholder='Search...' onChange={handleSearch}/>
        <Grid item xs={6} md={4}>
          <Button variant="contained" color="secondary" onClick={handleOnCreateClick}>
            Create
          </Button>
          {
            variation === 'admin' ? <Button style={{marginLeft: 20}} variant="contained" color="secondary" onClick={handleOnGenerateClick}>Auto-Generate</Button> : null
          }
        </Grid>
      </Grid>
      <div className=' py-4'>
        <ImageItemList DisplayItems={LocList} onClick={handleLocClick} onEdit={handleLocEdit} onDelete={handleLocDelete} />
      </div>
      <PaginationBar pageNumber={pageNumber} maxPages={maxPages} setter={setPageNumber}/>
    </Page>
  );
}

export default LocationsPage;
