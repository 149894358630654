import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { Box, Grid, Paper, IconButton, InputBase,Tab, Button, List } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { StyledButton } from '../Buttons/StyledButton';

interface ElementProps {
  children: any;
}

export const Page: React.FC<ElementProps> = ({ children }) => {
  return (
    <div className='p-4 h-[100vh]'>
      <div className='font-cabin bg-white rounded-lg drop-shadow-2xl h-[100%] flex flex-col overflow-y-scroll'>
        {children}
      </div>
    </div>
  );
};

export const Title: React.FC<ElementProps> = ({ children }) => {
  return (
    <div className=' text-secondary-300 pt-8 pl-8 text-4xl float-left'>
      {children}
    </div>
  );
};

export const BottomBar: React.FC<ElementProps> = ({ children }) => {
  return (
    <div className='flex justify-end pb-8 mt-auto'>
      {children}
    </div>
  );
};

export const TopBar: React.FC<ElementProps> = ({ children }) => {
  return (
    <div className=' flow-root flex flex-row'>
      {children}
    </div>
  );
};

interface SearchBarProps {
  placeholder?: string,
  onChange?:  (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({ placeholder='Search for an item', onChange }) => {
  return (
    <Grid item xs={6} md={8}>
      <Paper sx={{ display: 'flex', flexDirection: 'row' }}>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder={placeholder}
          fullWidth
          onChange={onChange}
        />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
    </Grid>
  );
};

interface PaginationProps {
  pageNumber: number,
  maxPages: number,
  setter: React.Dispatch<React.SetStateAction<number>>,
  positionMode?: string // Set to '' to make the pagination bar right under the listed elements instead
}

export const PaginationBar: React.FC<PaginationProps> = ({ pageNumber, maxPages , setter, positionMode='mt-auto' }) => {

  const handleNextPage = () => {
    if (pageNumber < maxPages) {
      setter(pageNumber + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      setter(pageNumber - 1);
    }
  };

  return (
    <div className={`pb-8 pl-4 pr-4 ${positionMode}`}>
      <div className="pagination flow-root">
        <div className=' float-left'>
          <StyledButton variant='primary' onClick={handlePrevPage} disabled={pageNumber === 1}>
            Previous
          </StyledButton>
        </div>
        <div className=' float-right'>
          <StyledButton variant='primary' onClick={handleNextPage} disabled={pageNumber === maxPages}>
            Next
          </StyledButton>
        </div>
      </div>
    </div>
  );
};
