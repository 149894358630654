import { UserStateActions } from '../constants';
import { type Action, type UserState } from '../types';

/**
 *
 * @param state
 * @param action
 */
function userContextReducer(state: UserState, action: Action): UserState {
  switch (action.type) {
  case UserStateActions.SIGN_IN:
    return {
      ...state,
      isSignedIn: true,
      email: action.payload.email,
      accountType: action.payload.accountType,
    };
  case UserStateActions.SIGN_OUT:
    return {
      ...state,
      isSignedIn: false,
    };
  default:
  }
  return { ...state };
}

export default userContextReducer;
