import { ListItem, Stack, ListItemText, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { StyledButton } from '../Buttons/StyledButton';
import { ItemDisplay } from '../../types';
import { useNavigate } from 'react-router-dom';

interface ItemTextParams {
  primary: string,
  secondary: string
}

// For displaying non-editable text
export const ItemText: React.FC<ItemTextParams> = ({ primary, secondary }) => {
  return (
    <ListItemText
      primary={primary}
      secondary={secondary}
      primaryTypographyProps={{
        color: 'secondary',
        fontSize: '20px'
      }}
      sx={{
        color: 'secondary.main',
      }}
    />
  );
};


export interface AttendeeProps {
  id: any;
  primary: string;
  secondary: string;
  attended: boolean;
  onAttend: (id: any, e: React.MouseEvent<HTMLElement>) => void;
  onDelete: (id: any, e: React.MouseEvent<HTMLElement>) => void;
  onChange?: () => void;
}

function AttendeeItem(props: AttendeeProps): JSX.Element {
  const { id, primary, secondary, attended, onAttend, onDelete, onChange } = props;
  const navigate = useNavigate();
  const [hasAttended, setAttended] = useState(attended);

  /**
   * Sets the user to attended
   */
  const handleOnAttendClick = (e: React.MouseEvent<HTMLElement>) => {
    setAttended(!hasAttended);
    console.log(hasAttended);
    onAttend(id, e);
    onChange?.();
  };

  return (
    <div className=' bg-primary-300 drop-shadow-2xl rounded-lg' >
      <ListItem sx={{
        height: '100%',
        width: '100%',
        margin: '16px 0px 16px 0px',
      }}>
        <ItemText primary = {primary} secondary= {secondary} />
        <Stack spacing={2} direction='row'>
          <Button variant="contained" sx={{
            width: '200px',
            height: '36px',
            bgcolor: hasAttended ? 'warning.main' : 'primary.light',
            color: hasAttended ? 'black' : 'secondary.main',
            borderRadius: '8px',
            ': hover': {
              bgcolor: 'error.main',
              color: 'white'
            },
            typography: {
              fontWeight: 'bold',
              fontSize: '16px',
            }
          }} data-testid="attendance-button" onClick={handleOnAttendClick}>
            {hasAttended ? 'Mark Absent' : 'Mark Attended'}
          </Button>
          <StyledButton variant = 'primary' onClick={((e) => {onDelete(id,e);})} size = 'extra_small' variantExtra='contained'>Delete</StyledButton>
        </Stack>
      </ListItem>
    </div>
  );
}

export default AttendeeItem;