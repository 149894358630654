import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  TextField
} from '@mui/material';
// generateSecurePassword
function generateSecurePassword() {
  const length = 16;
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

interface DialogProps {
  idx: number,
  onClose:  (event: React.MouseEvent<HTMLElement>) => void;
  onCreate:  (idx: number, text: string, event: React.MouseEvent<HTMLElement>) => void;
  title: string;
  subtext: string;
  open: boolean;
}

const AlertDialog: React.FC<DialogProps> = ({ idx, onClose, title, subtext, onCreate, open }) => {
  const [password, setPassword] = useState<string>('');

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(event.target.value);
  };

  const generatePassword = () => {
    const generatedPassword = generateSecurePassword();
    setPassword(generatedPassword);
  };

  return (
    <Dialog open={open} style={{ width: '400px', margin: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '8px'}} maxWidth="xs" fullWidth>
      <div className='pt-8 text-center flex flex-col justify-center items-center h-full'>
        <div className='text-secondary-300 text-2xl font-cabin'>
          {title}
        </div>
        <div className='text-2x1 text-gray-600 font-cabin pt-1'>
          {subtext}
        </div>
      </div>
      <DialogContent>
        <div className='text-center flex flex-col justify-center items-center h-full pb-4'>
          <form noValidate>
            <TextField className=' block w-[250px] h-11 p-2 text-gray-600 border border-primary-0 rounded-lg bg-primary-300 text-left align-top resize-none' 
              onChange={onChange}
              value={password}
              placeholder="Password"
              type='password'
              autoComplete='new-password'
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'black',
                    borderWidth: 2,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                    borderWidth: 3,
                  },
                },
              }}
            ></TextField>
          </form>
          <div className='pt-4'>
            <Button className='w-[250px] h-11' color="primary" variant="contained" onClick={generatePassword}>
              Generate
            </Button>
          </div>
          <div className='pt-4'>
            <Button className='w-[250px] h-11' color="secondary" variant="contained" onClick={((e) => {onCreate(idx,password,e);})}>
              Create Business
            </Button>
          </div>
          <div className='pt-4'>
            <Button className='w-[250px] h-11' color="primary" variant="contained" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AlertDialog;
