import React from 'react';
import { Button } from '@mui/material';
import { PrimaryButtonStyle, SecondaryButtonStyle, ButtonDivStyle, SuccessButtonStyle, ErrorButtonStyle, buttonSizes } from './ButtonStyles';

interface ButtonProps {
  variant?: 'primary' | 'secondary' | 'success' | 'error';
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  size?: 'extra_small' | 'small' | 'medium' | 'large';
  variantExtra?: 'contained';
  children?: React.ReactNode;
}

interface ButtonGroupProps {
  children: React.ReactElement<ButtonProps>[] | React.ReactElement<ButtonProps>;
}

export const StyledButton: React.FC<ButtonProps> = ({ variant = 'primary', onClick, disabled = false, size = 'medium', variantExtra = undefined, children}) => {
  const getStyle = () => {
    switch (variant) {
    case 'primary': return PrimaryButtonStyle(buttonSizes[size]);
    case 'secondary': return SecondaryButtonStyle(buttonSizes[size]);
    case 'success': return SuccessButtonStyle(buttonSizes[size]);
    case 'error': return ErrorButtonStyle(buttonSizes[size]);
    }
  };

  return (
    <div className={ButtonDivStyle}>
      <Button sx={getStyle()} onClick={onClick} variant={variantExtra} disabled={disabled}>
        {children}
      </Button>
    </div>
  );
};


export const ButtonGroup: React.FC<ButtonGroupProps> = ({ children }) => {
  return (
    <div className=' pt-8 float-right pr-8'>
      <div className='flex'>
        {children}
      </div>
    </div>
  );
};