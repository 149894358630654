import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { DisplayField, PageOptions } from '../../types';
import { updateAdById, getEventById, partialUpdateEventById, getDictInfo } from '../../utils';
import { getAdsById, deleteAdById, findFieldValue, formatDateString, updateFieldInfo, formatImageUrl } from '../../utils';
import { ButtonGroup, StyledButton } from '../../components/Buttons/StyledButton';
import { NonEditableText } from '../../components/Text/TextComponents';
import { Page, Title, TopBar } from '../../components/Pages/PageComponents';
import { CookieNames } from '../../constants';
import { ImageDisplay } from '../../components/Files/ImageDisplay';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Menu, { MenuProps } from '@mui/material/Menu';
import { styled, alpha } from '@mui/material/styles';
import { Button, Divider, MenuItem } from '@mui/material';


const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 8,
    marginTop: theme.spacing(1),
    minWidth: 180,
    bgcolor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    transition: '0.2s ease-out',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 20,
        fontFamily: 'Cabin',
        color: theme.palette.secondary.main,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
      '&: hover': {
        backgroundColor: alpha(
          theme.palette.secondary.main,
          theme.palette.action.hoverOpacity,
        ),
        color: theme.palette.secondary.main,
        transition: '0.2s ease-in-out'
      }
    },
  },
}));


function AdsPreview({ variation = 'default' }: PageOptions) {
  const { id = '-1' } = useParams();
  const [fieldDictionary, setFieldDictionary] = useState<DisplayField[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    getAdsById(id).then(async (response) => {
      if (response.ok) {
        let data = await response.json();
        data = data ? data[0]: null;
        const newFields: DisplayField[] = [
          { name: 'Display Name', value: data?.name, dont_display: true },
          { name: 'Category', value: data?.category || '' },
          { name: 'Date', value: `from ${formatDateString(data?.time_start)} to ${formatDateString(data?.time_end)}` },
          { name: 'Approved', value: data ? (data.pending ? 'No' : 'Yes') : 'No'},
          { name: 'Description', value: data?.description || '', big: true },
          { name: 'Advertisement Image', value: data?.media, dont_display: true},
        ];
        updateFieldInfo(newFields,setFieldDictionary, false);
      }
    });
  };

  const handleDelete = async () => {
    await deleteAdById(id); 
    navigate(-1);
  };

  const handleReject = async () => {
    await updateAdById(id, { rejected: true });
    navigate(-1);
  };

  const handleAccept = async () => {
    await updateAdById(id, { pending: false });
    navigate(-1);
  };

  const handleEdit = () => {
    navigate('edit');
  };

  const handleAdShowtimes = () => {
    navigate(`../showtimes?ad_id=${id}`);
  };

  const handleCancel = () => {
    navigate(-1);
  };


  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenu = (button: string) => (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (button !== '') {
      handleCloseMenu();
    }
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };


  const previewButtonGroup = (
    <div>
      <MenuItem onClick={handleReject} disableRipple>Reject</MenuItem>
      <MenuItem onClick={handleAccept} disableRipple>Approve</MenuItem>
      <Divider />
    </div>
  );


  const Menu = (
    <ButtonGroup>
      <Button sx={{
        width: '18vh',
        height: '5vh',
        bgcolor: 'secondary.main',
        color: 'white',
        borderRadius: '8px',
        transition: '0.2s ease-out',
        typography: {
          fontFamily: 'Cabin',
          fontSize: '20px',
        },
        textTransform: 'capitalize',
        ': hover': {
          bgcolor: 'primary.main',
          color: 'secondary.main',
          transition: '0.2s ease-in-out'
        }
      }} aria-controls={open ? 'demo-customized-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} variant="contained" disableElevation onClick={handleMenu('')} endIcon={<KeyboardArrowDownIcon />}>
        Actions
      </Button>
      <StyledMenu
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}>
        {
          !getDictInfo<DisplayField>(fieldDictionary, 'Proposal') ? null : previewButtonGroup
        }
        <MenuItem onClick={handleAdShowtimes} disableRipple>Ad Showtimes</MenuItem>
        <Divider />
        <MenuItem onClick={handleEdit} disableRipple>Edit</MenuItem>
        <MenuItem onClick={handleDelete} disableRipple>Delete</MenuItem>
      </StyledMenu>
      <div className=' pl-4 pr-9 float-right'>
        <StyledButton onClick={handleCancel}>
          Cancel
        </StyledButton>
      </div>
    </ButtonGroup>
  );

  

  return (
    <Page>
      <TopBar>
        <Title>
          {findFieldValue(fieldDictionary, 'Display Name')}
        </Title>
        {Menu}
      </TopBar>
      <NonEditableText fields={fieldDictionary}/>
      <ImageDisplay name='Advertisement Image' image={getDictInfo<DisplayField>(fieldDictionary, 'Advertisement Image')?.value ? formatImageUrl(getDictInfo<DisplayField>(fieldDictionary, 'Advertisement Image')?.value as string) : undefined}/>
    </Page>
  );
}

export default AdsPreview;