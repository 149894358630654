import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DisplayField, DateTimeState, Payload, PageOptions } from '../../types';
import { findFieldValue, validateDateString, updateFieldInfo, getEventById, updateDateInfo, getDictInfo, convertDateTimeState, partialUpdateEventById } from '../../utils';
import { StyledButton, ButtonGroup} from '../../components/Buttons/StyledButton';
import { EditableText } from '../../components/Text/TextComponents';
import { DateTimePicker } from '../../components/Date/DateTimePicker';
import { createNavigateBack, fetchData, saveData, createEvent, cookie } from '../../utils';
import { Page, Title, BottomBar } from '../../components/Pages/PageComponents';
import AlertDialog from '../../components/Modals/Alert';

/**
 * 
 */
function EventEdit({ variation = 'default' }: PageOptions) {
  const { id = '-1' } = useParams();
  const [fieldDictionary, setFieldDictionary] = useState<DisplayField[]>([]);
  const [dateDictionary, setDateDictionary] = useState<DateTimeState[]>([]);
  const navigate = useNavigate();
  
  const queryParams = new URLSearchParams(location.search);
  const org_id = queryParams.get('org_id');

  useEffect(() => {
    fetchData(id, getEventById, setData, console.log);
  }, []);

  // Set data to null for create mode
  const setData = (data: any, convert?: false) => {
    data = data ? data[0]: null;
    console.log(data);
    const newFields: DisplayField[] = [
      { name: 'Display Name', value: data ? `${data.org_name}'s Event` : 'New Event', dont_display: true },
      { name: 'Event Name', value: data ? data?.name : '' },
      { name: 'Maximum Attendance', value: data ? data?.attendance_max : '' ,type: 'number'},
      { name: 'Organization Id', value: data?.org_id || org_id, type: 'number', dont_display: true},
      { name: 'Summary', value: data?.summary || '', big: true },
      { name: 'Proposal', value: data ? data.proposal : (variation === 'default' ? false : true), dont_display: true ,type: 'boolean'},
    ];
    console.log(org_id);
    console.log(newFields);

    const startDateTimeObj = validateDateString(data?.start_datetime);
    const endDateTimeObj = validateDateString(data?.end_datetime);

    const newDateFields: DateTimeState[] = [
      { name: 'Start Date and Time', date: startDateTimeObj, time: startDateTimeObj },
      { name: 'End Date and Time', date: endDateTimeObj, time: endDateTimeObj },
    ];
    updateFieldInfo(newFields, setFieldDictionary, false);
    updateDateInfo(newDateFields, setDateDictionary);
  };







  const [isDialogOpen, setDialogOpen] = useState({title: '', message: ''});
  const closeDialog = (e: React.MouseEvent<HTMLElement>) => {
    setDialogOpen({title: '', message: ''});
  };

  const handleError = async (response: Response) => {
    console.log(response);
    if (response.body) {
      const data = await response.json();
      setDialogOpen({title: data?.title || 'Unknown Problem', message: data?.message || 'An unknown problem occured on submission'});
    }
  };

  const handleSuccess = (response: Response) => {
    navigate(-1);
  };

  const handleSave = (e: React.MouseEvent<HTMLElement>) => {
    updateFieldInfo(fieldDictionary, setFieldDictionary, true);
    e.preventDefault();
    console.log(fieldDictionary);
    console.log(dateDictionary);
    const submitData: Payload = {
      'name': getDictInfo<DisplayField>(fieldDictionary, 'Event Name')?.value,
      'attendance_max': getDictInfo<DisplayField>(fieldDictionary, 'Maximum Attendance')?.value,
      'proposal': getDictInfo<DisplayField>(fieldDictionary, 'Proposal')?.value,
      'summary': getDictInfo<DisplayField>(fieldDictionary, 'Summary')?.value,
      'start_datetime': convertDateTimeState(getDictInfo<DateTimeState>(dateDictionary, 'Start Date and Time')),
      'end_datetime': convertDateTimeState(getDictInfo<DateTimeState>(dateDictionary, 'End Date and Time')),
      'org_id': getDictInfo<DisplayField>(fieldDictionary, 'Organization Id')?.value,
    };
    console.log(submitData);
    saveData(id, submitData, partialUpdateEventById, createEvent, handleError,handleSuccess);
  };










  const handleChanges = (key: string, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const Field = getDictInfo<DisplayField>(fieldDictionary, key);
    if (Field){
      Field.value = e.target.value;
      console.log(Field);
      updateFieldInfo([Field], setFieldDictionary, false);
    }
  };

  const handleTimeChanges = (key: string, e: Date | null) => {
    const Field = getDictInfo<DateTimeState>(dateDictionary, key);
    if (Field){
      Field.time = e;
      console.log(Field);
      updateDateInfo([Field], setDateDictionary);
    }
  };

  const handleDateChanges = (key: string, e: Date | null) => {
    const Field = getDictInfo<DateTimeState>(dateDictionary, key);
    if (Field){
      Field.date = e;
      console.log(Field);
      updateDateInfo([Field], setDateDictionary);
    }
  };

  return (
    <Page>
      <AlertDialog title={isDialogOpen.title} open={isDialogOpen.message!==''} message={isDialogOpen.message} onClose={closeDialog}/>
      <Title>
        {findFieldValue(fieldDictionary, 'Display Name')}
      </Title>
      <div className='flex flex-row'>
        <div className='flex-1'>
          <EditableText fields={fieldDictionary} onChange={handleChanges} />
        </div>
        <div className='flex-2'>
          <DateTimePicker fields={dateDictionary} onDateChange={handleDateChanges} onTimeChange={handleTimeChanges} />
        </div>
      </div>
      <BottomBar>
        <ButtonGroup>
          <div className='pr-4'>
            <StyledButton variant="secondary" onClick={handleSave}>Save</StyledButton>
          </div>
          <StyledButton onClick={() => navigate(-1)}>Cancel</StyledButton>
        </ButtonGroup>
      </BottomBar>
    </Page>
  );
}

export default EventEdit;