import React, { createContext, useContext } from 'react';
import { UserState, Action } from '../types';

export const UserContext = createContext<[UserState, React.Dispatch<Action>]>([{isSignedIn: false}, 
  () => ({isSignedIn: false})
]);

/**
 * A custom hook that provides the user context
 * @returns
 */
const useUserContext = () => useContext(UserContext);

export default useUserContext;
