import React, {  useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getAdsById, findFieldValue, validateDateString, updateFieldInfo, updateDateInfo, getDictInfo, convertDateTimeState } from '../../utils';
import { StyledButton, ButtonGroup} from '../../components/Buttons/StyledButton';
import { EditableText } from '../../components/Text/TextComponents';
import { formatImageUrl, updateAdById, createAd, fetchData, saveData} from '../../utils';
import { Page, Title, BottomBar } from '../../components/Pages/PageComponents';
import { DateTimePicker } from '../../components/Date/DateTimePicker';
import { ImageUpload } from '../../components/Files/ImageUpload';
import { ImageDataValue, DisplayField, DateTimeState, Payload, PageOptions } from '../../types';
import AlertDialog from '../../components/Modals/Alert';
/**
 * 
 */


function AdsEdit({ variation = 'default' }: PageOptions) {
  const { id = '-1' } = useParams();
  const [fieldDictionary, setFieldDictionary] = useState<DisplayField[]>([]);
  const [dateDictionary, setDateDictionary] = useState<DateTimeState[]>([]);
  const [imgDictionary, setImgDictionary] = useState<ImageDataValue>({});
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const event_id = queryParams.get('event_id');
  const org_id = queryParams.get('org_id');

  useEffect(() => {
    fetchData(id, getAdsById, setData, console.log);
  }, []);

  // Set data to null for create mode
  const setData = (data: any) => {
    data = data ? data[0]: null;

    const newFields: DisplayField[] = [
      { name: 'Display Name', value: data ? 'Edit Ad' : 'Create Ad', dont_display: true },
      { name: 'Ad Name', value: data?.name || '' },
      { name: 'Description', value: data?.description || '', big: true },
      { name: 'Proposal', value: data ? data.pending : (variation === 'default' ? false : true), dont_display: true ,type: 'boolean'},
      { name: 'Organization Id', value: data?.org_id || org_id, type: 'number', dont_display: true },
      { name: 'Event Id', value: data?.event_id || event_id, type: 'number', dont_display: true },
    ];
    console.log(data);

    console.log(org_id);
    console.log(newFields);

    const startDateTimeObj = validateDateString(data?.time_start);
    const endDateTimeObj = validateDateString(data?.time_end);

    const newDateFields: DateTimeState[] = [
      { name: 'Start Date and Time', date: startDateTimeObj, time: startDateTimeObj },
      { name: 'End Date and Time', date: endDateTimeObj, time: endDateTimeObj },
    ];

    const newImgFields: ImageDataValue = {
      image: null,
      url: formatImageUrl(data?.media),
    };
    updateFieldInfo(newFields, setFieldDictionary, false);
    updateDateInfo(newDateFields, setDateDictionary);
    setImgDictionary(newImgFields);
  };










  const [isDialogOpen, setDialogOpen] = useState({title: '', message: ''});
  const closeDialog = (e: React.MouseEvent<HTMLElement>) => {
    setDialogOpen({title: '', message: ''});
  };

  const handleError = async (response: Response) => {
    console.log(response);
    if (response.body) {
      const data = await response.json();
      setDialogOpen({title: data?.title || 'Unknown Problem', message: data?.message || 'An unknown problem occured on submission'});
    }
  };

  const handleSuccess = (response: Response) => {
    navigate(-1);
  };

  const handleSave = (e: React.MouseEvent<HTMLElement>) => {
    updateFieldInfo(fieldDictionary, setFieldDictionary, true);
    e.preventDefault();
    console.log(fieldDictionary);
    console.log(dateDictionary);
    const submitData: Payload = {
      'name': getDictInfo<DisplayField>(fieldDictionary, 'Ad Name')?.value,
      'category': getDictInfo<DisplayField>(fieldDictionary, 'Category')?.value,
      'pending': getDictInfo<DisplayField>(fieldDictionary, 'Proposal')?.value,
      'description': getDictInfo<DisplayField>(fieldDictionary, 'Description')?.value,
      'org_id': getDictInfo<DisplayField>(fieldDictionary, 'Organization Id')?.value,
      'event_id': getDictInfo<DisplayField>(fieldDictionary, 'Event Id')?.value,
      'time_start': convertDateTimeState(getDictInfo<DateTimeState>(dateDictionary, 'Start Date and Time')),
      'time_end': convertDateTimeState(getDictInfo<DateTimeState>(dateDictionary, 'End Date and Time')),
    };

    if (imgDictionary?.image) {
      submitData['media'] = imgDictionary.image;
    }
    
    console.log(submitData);
    saveData(id, submitData, updateAdById, createAd, handleError, handleSuccess);
  };



  const handleChanges = (key: string, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const Field = getDictInfo<DisplayField>(fieldDictionary, key);
    if (Field){
      Field.value = e.target.value;
      console.log(Field);
      updateFieldInfo([Field], setFieldDictionary, false);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleTimeChanges = (key: string, e: Date | null) => {
    const Field = getDictInfo<DateTimeState>(dateDictionary, key);
    if (Field){
      Field.time = e;
      console.log(Field);
      updateDateInfo([Field], setDateDictionary);
    }
  };

  const handleDateChanges = (key: string, e: Date | null) => {
    const Field = getDictInfo<DateTimeState>(dateDictionary, key);
    if (Field){
      Field.date = e;
      console.log(Field);
      updateDateInfo([Field], setDateDictionary);
    }
  };
  
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const ImageUrl = URL.createObjectURL(file);
      console.log(ImageUrl);
      setImgDictionary({ image: file, url: ImageUrl});
    }
  };

  return (
    <Page>
      <AlertDialog title={isDialogOpen.title} open={isDialogOpen.message!==''} message={isDialogOpen.message} onClose={closeDialog}/>
      <Title>
        {findFieldValue(fieldDictionary, 'Display Name')}
      </Title>
      <div className='flex flex-row'>
        <div className='flex-1'>
          <EditableText fields={fieldDictionary} onChange={handleChanges} />
          <ImageUpload name='Upload Image' image={imgDictionary.url} onUpload={handleFileChange}/>
        </div>
        <div className='flex-2'>
          <DateTimePicker fields={dateDictionary} onDateChange={handleDateChanges} onTimeChange={handleTimeChanges} />
        </div>
      </div>
      <BottomBar>
        <ButtonGroup>
          <div className='pr-4'>
            <StyledButton variant="secondary" onClick={handleSave}>Save</StyledButton>
          </div>
          <StyledButton onClick={handleCancel}>Cancel</StyledButton>
        </ButtonGroup>
      </BottomBar>
    </Page>
  );
}

export default AdsEdit;
