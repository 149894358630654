import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { DisplayField, PageOptions, ItemDisplay } from '../../types';
import { deleteEventById, getEventById, partialUpdateEventById, getDictInfo, getAdsByOrganizationId, formatImageUrl, getJoinRequestsByEventAbsentCount, getJoinRequestsByEventAttendedCount } from '../../utils';
import { findFieldValue, formatDateString, updateFieldInfo, cookie } from '../../utils';
import { ButtonGroup, StyledButton } from '../../components/Buttons/StyledButton';
import { NonEditableText } from '../../components/Text/TextComponents';
import { Page, Title, TopBar } from '../../components/Pages/PageComponents';
import { CookieNames } from '../../constants';
import { AdDisplay, AdDisplayList } from '../../components/ListItems/AdThumbnails';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Menu, { MenuProps } from '@mui/material/Menu';
import { styled, alpha } from '@mui/material/styles';
import { Button, Divider, MenuItem } from '@mui/material';
import { PieChart, useDrawingArea } from '@mui/x-charts';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 8,
    marginTop: theme.spacing(1),
    minWidth: 180,
    bgcolor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    transition: '0.2s ease-out',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 20,
        fontFamily: 'Cabin',
        color: theme.palette.secondary.main,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
      '&: hover': {
        backgroundColor: alpha(
          theme.palette.secondary.main,
          theme.palette.action.hoverOpacity,
        ),
        color: theme.palette.secondary.main,
        transition: '0.2s ease-in-out'
      }
    },
  },
}));

const StyledText = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 20,
}));

function PieCenterLabel({ children }: { children: React.ReactNode }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2 + 35} y={top + height / 2}>
      {children}
    </StyledText>
  );
}


function EventPreview({ variation = 'default' }: PageOptions) {
  const { id = '-1' } = useParams();
  const [fieldDictionary, setFieldDictionary] = useState<DisplayField[]>([]);
  const [AdDictionary, setAdDictionary] = useState<ItemDisplay[]>([]);

  const [Absent, setAbsent] = useState<number>(0);
  const [Attended, setAttended] = useState<number>(0);
  const [Total, setTotal] = useState<number>(0);

  const navigate = useNavigate();

  useEffect(() => {
    getData();
    setInsightData();
  }, []);

  const getData = async () => {
    getEventById(id).then(async (response) => {
      if (response.ok) {
        let data = await response.json();
        data = data ? data[0]: null;
        const newFields: DisplayField[] = [
          { name: 'Display Name', value: `${data?.name}`, dont_display: true },
          { name: 'Organizer\'s Email', value: data?.org_email },
          { name: 'Organizer\'s Phone Number', value: data?.org_phone },
          { name: 'Date', value: `from ${formatDateString(data?.start_datetime)} to ${formatDateString(data?.end_datetime)}` },
          { name: 'Maximum Attendance', value: data?.attendance_max, type: 'number' },
          { name: 'Approved', value: data ? (data.proposal ? 'No' : 'Yes') : 'No'},
          { name: 'Proposal', value: data ? data.proposal : (variation === 'default' ? false : true), dont_display: true ,type: 'boolean'},
          { name: 'Description', value: data?.summary, big: true },
          { name: 'Organization ID', value: data?.org_id, dont_display: true },
          { name: 'Event ID', value: data?.id, dont_display: true },
        ];
        console.log(newFields);
        updateFieldInfo(newFields,setFieldDictionary,false);

        getAdsByOrganizationId(id).then(async (response: Response) => {
          const AdData = await response.json();
          const DisplayResults = AdData.map((item: any) => ({
            id: item?.id,
            primary: item?.name,
            secondary: '',
            img: formatImageUrl(item?.media)
          }));
          console.log(DisplayResults);
          setAdDictionary(DisplayResults);
        });
      }
    });
  };

  const setInsightData = async () => {
    const Absent = await getAbsent();
    const Attended = await getAttended();
    setAttended(Attended);
    setAbsent(Absent);
    setTotal(Absent + Attended);
  };

  const getAbsent = async () => {
    const response = await getJoinRequestsByEventAbsentCount(id);
    if (response.ok) {
      const data = await response.json();
      return data['count'];
    }
  };

  const getAttended = async () => {
    const response = await getJoinRequestsByEventAttendedCount(id); 
    if (response.ok) {
      const data = await response.json();
      return data['count'];
    }
  };

  const handleDelete = async () => {
    await deleteEventById(id); 
    navigate(-1);
  };

  const handleReject = async () => {
    await partialUpdateEventById(id, { rejected: true });
    navigate(-1);
  };

  const handleAccept = async () => {
    await partialUpdateEventById(id, { proposal: false });
    navigate(-1);
  };

  const handleEdit = () => {
    navigate('edit');
  };

  const handleAd = () => {
    const currentPath = location.pathname;

    if (currentPath.startsWith('/admin-app/')) {
      navigate(`/admin-app/ads/-1/edit?org_id=${getDictInfo<DisplayField>(fieldDictionary, 'Organization ID')?.value}&event_id=${getDictInfo<DisplayField>(fieldDictionary, 'Event ID')?.value}`);
    } else if (currentPath.startsWith('/business-app/')) {
      navigate(`/business-app/event-list/ads/-1/edit?org_id=${getDictInfo<DisplayField>(fieldDictionary, 'Organization ID')?.value}&event_id=${getDictInfo<DisplayField>(fieldDictionary, 'Event ID')?.value}`);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleAdClick = (id: number, e: React.MouseEvent<HTMLElement>) => {
    navigate(`../../ads/${id}`);
  };

  const handleSignUps = () => {
    navigate(`../../signups/${id}`);
  };

  const handleAttendees = () => {
    navigate(`../../attendees/${id}`);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenu = (button: string) => (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (button !== '') {
      handleCloseMenu();
    }
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const normalButtonGroup = (
    <div>
      <MenuItem onClick={handleAd} disableRipple>Create Ad</MenuItem>
    </div>
  );

  const previewButtonGroup = (
    <div>
      <MenuItem onClick={handleReject} disableRipple>Reject</MenuItem>
      <MenuItem onClick={handleAccept} disableRipple>Approve</MenuItem>
    </div>
  );

  const Menu = (
    <ButtonGroup>
      <Button sx={{
        width: '18vh',
        height: '5vh',
        bgcolor: 'secondary.main',
        color: 'white',
        borderRadius: '8px',
        transition: '0.2s ease-out',
        typography: {
          fontFamily: 'Cabin',
          fontSize: '20px',
        },
        textTransform: 'capitalize',
        ': hover': {
          bgcolor: 'primary.main',
          color: 'secondary.main',
          transition: '0.2s ease-in-out'
        }
      }} aria-controls={open ? 'demo-customized-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} variant="contained" disableElevation onClick={handleMenu('')} endIcon={<KeyboardArrowDownIcon />}>
        Actions
      </Button>
      <StyledMenu
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}>
        {
          getDictInfo<DisplayField>(fieldDictionary, 'Proposal') ? normalButtonGroup : previewButtonGroup
        }
        <Divider />
        <MenuItem onClick={handleSignUps} disableRipple>Sign Ups</MenuItem>
        <MenuItem onClick={handleAttendees} disableRipple>Attendees</MenuItem>
        <Divider />
        <MenuItem onClick={handleEdit} disableRipple>Edit</MenuItem>
        <MenuItem onClick={handleDelete} disableRipple>Delete</MenuItem>
      </StyledMenu>
      <div className=' pl-4 pr-9 float-right'>
        <StyledButton onClick={handleCancel}>
          Cancel
        </StyledButton>
      </div>
    </ButtonGroup>
  );

  return (
    <Page>
      <TopBar>
        <Title>
          {findFieldValue(fieldDictionary, 'Display Name')}
        </Title>
        {Menu}
      </TopBar>
      <NonEditableText fields={fieldDictionary}/>
      <div>
        <div className=' text-secondary-300 pt-8 pl-8 text-[32px]'>
          Event Insight
          <div className="p-4 bg-primary-300 rounded-lg drop-shadow-xl w-3/5 pb-8">
            <div className="mx-auto max-w-7xl">
              <div className="flex flex-1">
                <div className="basis-full flex max-w-xs flex-col gap-y-4">
                  <p className="pl-4 text-base leading-7 text-secondary-300">Total Sign-Ups</p>
                  <p className="text-center text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">{Total}</p>
                </div>
                <div className="inline-block w-2 self-stretch bg-secondary-75"></div>
                <div className="basis-full flex max-w-xs flex-col gap-y-4">
                  <p className="pl-4 text-base leading-7 text-secondary-300">Attendance</p>
                  <p className="text-center text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">{Attended}</p>
                </div>
                <div className="inline-block w-2 self-stretch bg-secondary-75"></div>
                <div className="basis-full flex max-w-xs flex-col gap-y-4">
                  <p className="pl-4 text-base leading-7 text-secondary-300">Absence</p>
                  <p className="text-center text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">{Absent}</p>
                </div>
                <div className="inline-block w-2 self-stretch bg-secondary-75"></div>
                <div className="basis-full items-center flex max-w-xs flex-col gap-y-4">
                  <PieChart
                    colors={['red', 'blue', 'green']}
                    series={[
                      {
                        data: [
                          { id: 0, value: Attended, label: 'Attendance', color: 'green' },
                          { id: 1, value: Absent, label: 'Absence', color: 'red' },
                        ],
                        innerRadius: 30,
                        outerRadius: 40,
                        cx: 35,
                      },
                    ]}
                    width={80}
                    height={80}
                    slotProps={{
                      legend: { hidden: true },
                    }}
                  >
                    <PieCenterLabel>{Total !== 0 ? `${Math.floor(Attended/Total*100)}%` : 'N/A'}</PieCenterLabel>
                  </PieChart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        AdDictionary.length > 0 && (
          <div>
            <div className=" text-secondary-300 pt-4 pl-8 text-[28px]">Ads for Event</div>
            <div>
              <AdDisplayList DisplayItems={AdDictionary} noImageText='No Ad Image' onClick={handleAdClick} />
            </div>
          </div>
        )
      }
    </Page>
  );
}

export default EventPreview;