import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, IconButton, InputBase,Tab, Button, List } from '@mui/material';
import { ItemDisplay } from '../../types';
import { getAds, formatImageUrl, getSignUpRequests, getEventRequests, formatDateString, fetchListData, updateAdById, partialUpdateEventById, deleteOrganizationById, deleteEventById, deleteAdById, getAdsByOrganizationId, getEventsByOrganizationId } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { Page, Title, TopBar, SearchBar, PaginationBar } from '../../components/Pages/PageComponents';
import ImageItemList from '../../components/ListItems/ImageItemList';


/**
 * Ads Page
 */
function AdsPage(): JSX.Element {
  const [adsList, setAdList] = useState<Array<ItemDisplay>>([]);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [maxPages, setMaxPages] = useState<number>(1);

  const navigate = useNavigate();

  const setAdsData = async (data: any) => {
    const DisplayResults = data.map((item: any) => ({
      id: item?.id,
      primary: item?.name,
      secondary: `Date: ${formatDateString(item?.time_start)} to ${formatDateString(item?.time_end)}`,
      img: formatImageUrl(item?.media) ,
    }));
    console.log(data);
    console.log(DisplayResults);
    setAdList(DisplayResults);
  };

  useEffect(() => {
    fetchListData(getAds,setAdsData,console.log, {page: pageNumber, setter: setMaxPages});
  }, [pageNumber]);

  /**
   * Delete an item from the list by idx
   */
  const deleteByIdx = async (idx: number, setter: React.Dispatch<React.SetStateAction<Array<ItemDisplay>>>, list: Array<ItemDisplay>) => {
    const newList = list.filter((_, i) => i != idx);
    setter(newList);
  };

  const handleAdEdit = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    navigate(`${adsList[idx].id}/edit`);
  };

  const handleAdDelete = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    deleteAdById(adsList[idx].id.toString());
    deleteByIdx(idx, setAdList, adsList);
  };

  const handleAdClick = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    navigate(`${adsList[idx].id}`);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // Implement search logic based on the current page
    const newAdsList = adsList.map((item, idx) => {
      if ((item.primary).toLowerCase().includes(event.target.value.toLowerCase())) {
        item.dont_show = false;
      } else {
        item.dont_show = true;
      }
      return item;
    });
    setAdList(newAdsList);
  };

  return (

    <Page>
      <Grid container spacing={3} sx={{ width: '100%' }} className='pl-4 pt-4'>
        <SearchBar placeholder='Search...' onChange={handleSearch}/>
      </Grid>
      <div className=' py-4'>
        <ImageItemList DisplayItems={adsList} onClick={handleAdClick} onEdit={handleAdEdit} onDelete={handleAdDelete} />
      </div>
      <PaginationBar pageNumber={pageNumber} maxPages={maxPages} setter={setPageNumber}/>
    </Page>
  );
}

export default AdsPage;
