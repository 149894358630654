import React from 'react';
import { DateTimeState } from '../../types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface DateTimePickerParams {
  fields: DateTimeState[];
  onDateChange: (key: string, event: Date | null) => void;
  onTimeChange: (key: string, event: Date | null) => void;
}

const DateStyle = ' block w-[30vh] h-12 p-2 text-info-300 border border-primary-0 rounded-lg bg-primary-300 text-left align-top resize-none';
const TimeStyle = ' block w-[30vh] h-12 p-2 text-info-300 border border-primary-0 rounded-lg bg-primary-300 text-left align-top resize-none';

// For displaying editable text
export const DateTimePicker: React.FC<DateTimePickerParams> = ({ fields, onDateChange, onTimeChange }) => {
  return (
    <div className='flex-2 pr-16'>
      {fields.map((field, index) => (
        (() => {
          return (<div key={index} className='flex flex-auto text-xl font-cabin'>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div className=' text-secondary-300 w-48 pb-2'>
                  {field.name}
                </div>
                <div className=' pb-4'>
                  <TimePicker
                    value={field.time}
                    className={TimeStyle}
                    onChange={(e) => { onTimeChange(field.name, e); }}
                  />
                </div>
                <div className=' pb-4'>
                  <DatePicker
                    value={field.date}
                    className={DateStyle}
                    onChange={(e) => { onDateChange(field.name, e); }}
                  />
                </div>
              </LocalizationProvider>
            </div>
          </div>);
        })()
      ))}
    </div>
  );
};