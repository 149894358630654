import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DisplayField, DateTimeState, Payload, PageOptions } from '../../types';
import { findFieldValue, validateDateString, updateFieldInfo, getEventById, updateDateInfo, getDictInfo, createOrganizationRequest } from '../../utils';
import { StyledButton, ButtonGroup} from '../../components/Buttons/StyledButton';
import { EditableText, NonEditableText } from '../../components/Text/TextComponents';
import { createNavigateBack, fetchData, saveData, createOrganization, updateOrganizationById, getOrganizationById } from '../../utils';
import { Page, Title, TopBar } from '../../components/Pages/PageComponents';
import AlertDialog from '../../components/Modals/Alert';

/**
 * 
 */
function BusinessEdit({ variation = 'default' }: PageOptions) {
  const { id = '-1' } = useParams();
  const [nonEditDictionary, setNonEditFieldDictionary] = useState<DisplayField[]>([]);
  const [fieldDictionary, setFieldDictionary] = useState<DisplayField[]>([]);
  const navigate = useNavigate();
  const navigateBack = createNavigateBack(navigate, '/admin-app/users');

  useEffect(() => {
    fetchData(id, getOrganizationById, setData, console.log);
  }, []);

  // Set data to null for create mode
  const setData = (data: any) => {
    data = data ? data[0]: null;
    const newNonEditFields: DisplayField[] = [
      { name: 'Display Name', value: data ? 'Edit Business' : 'New Business', dont_display: true },
    ];

    const newFields: DisplayField[] = [
      { name: 'Business Name', value: data?.name || '' },
      { name: 'Email', value: data?.email || '' , dont_display: data!==null},
      { name: 'Phone', value: data?.phone || ''},
      { name: 'Password', value: '', dont_display: (variation === 'signup') },
      { name: 'Description', value: data?.description || '', big: true },
      { name: 'Proposal', value: variation === 'default' ? false : true, dont_display: true ,type: 'boolean'},
      { name: 'First Name', value: data?.firstName || data?.name || '', dont_display: true },
      { name: 'Last Name', value: data?.lastName || 'Manager', dont_display: true },
    ];
    console.log(data);
    updateFieldInfo(newFields, setFieldDictionary,false);
    updateFieldInfo(newNonEditFields, setNonEditFieldDictionary, false);
  };










  const [isDialogOpen, setDialogOpen] = useState({title: '', message: ''});
  const closeDialog = (e: React.MouseEvent<HTMLElement>) => {
    setDialogOpen({title: '', message: ''});
  };

  const handleError = async (response: Response) => {
    console.log(response);
    if (response.headers.get('Content-Type')?.includes('application/json')) {
      const data = await response.json();

      setDialogOpen({
        title: data?.title || 'Unknown Problem', 
        message: data?.message || 'An unknown problem occurred on submission'
      });
    } else {
      setDialogOpen({title: 'Unknown Problem', message: 'An unknown problem occured on submission'});
    }
  };

  const handleSuccess = (response: Response) => {
    navigate(-1);
  };






  const handleSave = (e: React.MouseEvent<HTMLElement>) => {
    updateFieldInfo(fieldDictionary, setFieldDictionary, true);
    e.preventDefault();
    console.log(fieldDictionary);
    console.log(nonEditDictionary);

    const FirstName = getDictInfo<DisplayField>(fieldDictionary, 'First Name')?.value;
    const LastName = getDictInfo<DisplayField>(fieldDictionary, 'Last Name')?.value;
    const OrgName = getDictInfo<DisplayField>(fieldDictionary, 'Business Name')?.value;

    const submitData: Payload = {
      'name': OrgName,
      'email': getDictInfo<DisplayField>(fieldDictionary, 'Email')?.value,
      'pending': getDictInfo<DisplayField>(fieldDictionary, 'Proposal')?.value,
      'phone': getDictInfo<DisplayField>(fieldDictionary, 'Phone')?.value,
      'description': getDictInfo<DisplayField>(fieldDictionary, 'Description')?.value,
      'firstName': (FirstName && FirstName!=='') ? FirstName : OrgName,
      'lastName': (LastName && LastName!=='') ? LastName : LastName,
    };
    
    const Password = getDictInfo<DisplayField>(fieldDictionary, 'Password')?.value;
    if (typeof Password === 'string' && Password.trim() !== '') {
      submitData['password'] = Password;
    }

    console.log(submitData);
    console.log(variation);
    saveData(id, submitData, updateOrganizationById, (variation === 'signup') ? createOrganizationRequest : createOrganization, handleError, handleSuccess);
  };

  const handleChanges = (key: string, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const Field = getDictInfo<DisplayField>(fieldDictionary, key);
    if (Field){
      Field.value = e.target.value;
      console.log(Field);
      updateFieldInfo([Field], setFieldDictionary,false);
    }
  };

  return (
    <Page>
      <AlertDialog title={isDialogOpen.title} open={isDialogOpen.message!==''} message={isDialogOpen.message} onClose={closeDialog}/>
      <TopBar>
        <Title>
          {findFieldValue(nonEditDictionary, 'Display Name')}
        </Title>
        <ButtonGroup>
          <StyledButton variant='secondary' onClick={handleSave}>Submit</StyledButton>
          <div className='pl-4'>
            <StyledButton onClick={() => navigateBack()}>Cancel</StyledButton>
          </div>
        </ButtonGroup>
      </TopBar>
      <NonEditableText fields={nonEditDictionary} />
      <EditableText fields={fieldDictionary} onChange={handleChanges} />
    </Page>
  );
}

export default BusinessEdit;
