import React from 'react';
import { DisplayField } from '../../types';
import PropTypes from 'prop-types';
import {  TextField  } from '@mui/material';


const BigNonEditStyle = ' p-4 text-xl text-gray-600 bg-primary-300 rounded-lg drop-shadow-xl w-3/5 break-all h-[40vh]';
const BigNonEditDivStyle = ' text-secondary-300 pt-4 text-[28px]';

const SmallEditStyle = ' block w-[80vh] p-2 text-gray-600 border border-primary-0 rounded-lg bg-primary-300 text-left align-top resize-none';
const BigEditStyle = 'block w-[80vh] p-4 text-gray-600 border border-primary-0 rounded-lg bg-primary-300 break-all text-left align-top resize-none';


interface NonEditableTextParams {
  fields: DisplayField[];
}


// For displaying non-editable text
export const NonEditableText: React.FC<NonEditableTextParams> = ({ fields }) => {
  return (
    <div className='text-xl text-gray-600 pl-8'>
      {fields.map((field, index) => (
        (() => {
          if (!field.dont_display && field.value !== undefined) {
            if (field.big) {
              return (
                <div>
                  <div key={index} className={BigNonEditDivStyle}>
                    <div className='pb-2'>
                      {field.name}
                    </div>
                    <p className={BigNonEditStyle}>
                      {`${field.value === '' || field.value === null ? `No ${field.name.toLowerCase()} provided` : field.value}`}
                    </p>
                  </div>
                </div>
              );
            } else {
              return (
                <p key={index} className={`pt-4 ${field.useDanger ? 'text-danger-300' : ''}`}>
                  {`${field.name}: ${field.value === '' || field.value === null ? `No ${field.name.toLowerCase()} provided` : field.value}`}
                </p>
              );
            }
          }
        })()
      ))}
    </div>
  );
};


interface EditableTextParams {
  fields: DisplayField[];
  onChange: (key: string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}


// For displaying editable text
export const EditableText: React.FC<EditableTextParams> = ({ fields, onChange }) => {
  const getType = (key: string) => {
    if (key.toLowerCase() === 'password') {
      return 'password';
    } else {
      return 'text';
    }
  };

  return (
    <div className='text-xl text-info-300'>
      {fields.map((field, index) => (
        (() => {
          if (!field.dont_display && field.value !== undefined) {
            console.log(getType(field.name));
            return (
              <div key={index} className='flex flex-col pl-8 py-2 text-xl font-cabin'>
                <div className=' text-secondary-300'>
                  {field.name}
                </div>
                <div>
                  <form noValidate>
                    <TextField
                      id={`input-${field.name}`}
                      type={getType(field.name)}
                      onChange={(e) => { onChange(field.name, e); }}
                      multiline={(getType(field.name) !== 'password') ? true : false}
                      value={field?.value}
                      rows={field.big ? 10 : 1}
                      className={field.big ? BigEditStyle : SmallEditStyle}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black',
                            borderWidth: 2,
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black', // Color for focused state
                            borderWidth: 3, // Border width for focused state
                          },
                        },
                      }}
                    ></TextField>
                  </form>
                </div>
              </div>);
          }
        })()
      ))}
    </div>
  );
};