/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    "./src/**/*.{js,jsx,ts,tsx}",
  ],
  theme: {
    extend: {
      colors: {
        primary: {
		  100: "#FFFFFF",
          300: "#FFFCFA",
        },
        secondary: {
          50: "#FDF2EE",
          75: "#F8C9B9",
          100: "#F6B29C",
          200: "#F29171",
          300: "#EF7B54",
        },
        danger: {
          50: "#F9E6E6",
          200: "#CC2B2B",
          300: "#C20000",
        },
        success: {
          200: "#2BCC2B",
          300: "#00C200",
        },
        warning: {
          200: "#F8E82D",
          300: "#F7E302",
        },
        info: {
          300: "#9C9C9C",
        },
      },
      fontFamily: {
        cabin: ["Cabin"],
      },
    },
  },
  plugins: [],
};