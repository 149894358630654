import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, IconButton, InputBase,Tab, Button, List } from '@mui/material';
import { ItemDisplay } from '../../types';
import { cookie, formatImageUrl, getSignUpRequests, getEventRequests, formatDateString, fetchListData, updateAdById, partialUpdateEventById, deleteOrganizationById, deleteEventById, deleteAdById, getAdsByOrganizationId, getEventsByOrganizationId } from '../../utils';
import { useNavigate } from 'react-router-dom';
import ListItemList from '../../components/ListItems/ListItemList';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Page, Title, TopBar, SearchBar, PaginationBar } from '../../components/Pages/PageComponents';
import { CookieNames } from '../../constants';
import ImageItemList from '../../components/ListItems/ImageItemList';

import tailwindConfig from '../../tailwind.config';
import resolveConfig from 'tailwindcss/resolveConfig';
const fullConfig = resolveConfig(tailwindConfig);

const secondary = fullConfig.theme?.colors?.secondary as Record<string, string>;


/**
 * Business Page
 */
function BusinessEventsPage(): JSX.Element {
  const [eventsList, setEventList] = useState<Array<ItemDisplay>>([]);
  const [adsList, setAdList] = useState<Array<ItemDisplay>>([]);
  const [tabType, setTabType] = useState('events');
  const navigate = useNavigate();
  const org_id = cookie.get(CookieNames.ORG_ID);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [maxPages, setMaxPages] = useState<number>(1);

  const setEventsData = async (data: any) => {
    const DisplayResults = data.map((item: any) => ({
      id: item?.id,
      primary: item?.name,
      secondary: `Organization: ${item.org_name}`,
    }));
    setEventList(DisplayResults);
  };

  const setAdsData = async (data: any) => {
    const DisplayResults = data.map((item: any) => ({
      id: item?.id,
      primary: item?.name,
      secondary: `Date: ${formatDateString(item?.time_start)} to ${formatDateString(item?.time_end)}`,
      img: formatImageUrl(item?.media) , // Change Later
    }));
    console.log(data);
    console.log(DisplayResults);
    setAdList(DisplayResults);
  };

  useEffect(() => {
    if (tabType === 'events') {
      fetchListData((() => (getEventsByOrganizationId(org_id,pageNumber))),setEventsData,console.log, {page: pageNumber, setter: setMaxPages});
    }
    if (tabType === 'ads') {
      fetchListData((() => (getAdsByOrganizationId(org_id,pageNumber))),setAdsData,console.log, {page: pageNumber, setter: setMaxPages});
    }
  }, [tabType, pageNumber]);

  /**
   * Delete an item from the list by idx
   */
  const deleteByIdx = async (idx: number, setter: React.Dispatch<React.SetStateAction<Array<ItemDisplay>>>, list: Array<ItemDisplay>) => {
    const newList = list.filter((_, i) => i != idx);
    setter(newList);
  };

  /**
   * Set the tabType to be the corresponding tab
   */
  const handleTabChange = (_: React.SyntheticEvent<Element, Event>, newValue: string) => {
    setTabType(newValue);
  };

  /**
   * Create
   */
  const handleOnCreate = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    navigate(`events/-1/edit?org_id=${org_id}`);
  };


  const handleEventEdit = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    navigate(`events/${eventsList[idx].id}/edit`); 
  };

  const handleEventDelete = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    deleteEventById(eventsList[idx].id.toString());
    deleteByIdx(idx, setEventList, eventsList);
  };

  const handleAdEdit = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    navigate(`/business-app/event-list/ads/${eventsList[idx].id}/edit`);
  };

  const handleAdDelete = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    deleteAdById(adsList[idx].id.toString());
    deleteByIdx(idx, setAdList, adsList);
  };

  const handleEventClick = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    navigate(`events/${eventsList[idx].id}`);
  };

  const handleAdClick = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    navigate(`ads/${adsList[idx].id}`);
  };

  return (

    <Page>
      <Grid container spacing={3} sx={{ width: '100%' }} className='pl-4 pt-4'>
        <SearchBar placeholder='Search...' />
        <Grid item xs={6} md={4}>
          {tabType == 'events' ? <Button variant="contained" color="secondary" onClick={handleOnCreate}>Create</Button> : null}
        </Grid>
      </Grid>
      <div className=' py-4'>
        <TabContext value={tabType}>
          <TabList onChange={handleTabChange} variant="fullWidth" indicatorColor='secondary' textColor='secondary'>
            <Tab label="Events" value="events"/>
            <Tab label="Ads" value="ads"/>
          </TabList>
          <TabPanel value="events" sx={{padding: '0px'}}>
            <ListItemList DisplayItems={eventsList} onClick={handleEventClick} onEdit={handleEventEdit} onDelete={handleEventDelete} />
          </TabPanel>
          <TabPanel value="ads" sx={{padding: '0px'}}>
            <ImageItemList DisplayItems={adsList} onClick={handleAdClick} onEdit={handleAdEdit} onDelete={handleAdDelete} />
          </TabPanel>
        </TabContext>
      </div>
      <PaginationBar pageNumber={pageNumber} maxPages={maxPages} setter={setPageNumber}/>
    </Page>
  );
}

export default BusinessEventsPage;
