import styled from 'styled-components';

export const SignInPageContainer = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
`;

export const SignInFormContainer = styled.div`
    padding: 24px;
    border-radius: 15px;
    border: 1px solid #000;
    background: #FFF;
    min-width: 450px;
    min-height: 430px;
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const TextFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const SubmitContainer = styled.div`
    padding-top: 70px;
`;
