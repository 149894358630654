import { ListItem, Stack, ListItemText } from '@mui/material';
import React from 'react';
import { StyledButton } from '../Buttons/StyledButton';
import { ItemDisplay } from '../../types';

export interface SignUpItemProps {
  id: any;
  primary: string;
  secondary: string;
  onEdit: (id: any, e: React.MouseEvent<HTMLElement>) => void;
  onDelete: (id: any, e: React.MouseEvent<HTMLElement>) => void;
  onClick: (id: any, e: React.MouseEvent<HTMLElement>) => void;
}

export function ListDisplayItem(props: SignUpItemProps) {
  const { id, primary, secondary, onEdit, onDelete, onClick } = props;

  return (
    <div className=' bg-primary-300 drop-shadow-2xl rounded-lg'>
      <ListItem sx={{
        height: '100%',
        width: '100%',
        margin: '16px 0px 16px 0px',
      }}>
        <ItemText primary = {primary} secondary= {secondary} handleItemClick={((e) => {onClick(id,e);})} />
        <Stack spacing={2} direction='row'>
          <StyledButton variant = 'primary' onClick={((e) => {onEdit(id,e);})} size = 'extra_small' variantExtra='contained'>Edit</StyledButton>
          <StyledButton variant = 'primary' onClick={((e) => {onDelete(id,e);})} size = 'extra_small' variantExtra='contained'>Delete</StyledButton>
        </Stack>
      </ListItem>
    </div>
  );
}


interface ItemTextParams {
  primary: string,
  secondary: string
  handleItemClick?:  (event: React.MouseEvent<HTMLElement>) => void;
}

// For displaying non-editable text
export const ItemText: React.FC<ItemTextParams> = ({ primary, secondary, handleItemClick }) => {
  return (
    <ListItemText
      primary={primary}
      secondary={secondary}
      primaryTypographyProps={{
        color: 'secondary',
        fontSize: '20px'
      }}
      onClick={handleItemClick}
      sx={{
        color: 'secondary.main',
      }}
    />
  );
};

export default ListDisplayItem;