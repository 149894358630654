import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Page, Title, TopBar } from '../../components/Pages/PageComponents';

const AnalyticsDetailPage = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  

  return (
    <Page>
      <TopBar>
        <Title>Analytics Detail: {id}</Title>
      </TopBar>
      <Box sx={{ width: '100%', height: 400, p: 3 }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Page>
  );
};

export default AnalyticsDetailPage;
