import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DisplayField, DateTimeState, Payload, PageOptions } from '../../types';
import { findFieldValue, validateDateString, updateFieldInfo, getSchoolById, getDictInfo, createSchool, updateSchoolById } from '../../utils';
import { StyledButton, ButtonGroup} from '../../components/Buttons/StyledButton';
import { EditableText } from '../../components/Text/TextComponents';
import { createNavigateBack, fetchData, saveData, createEvent, cookie } from '../../utils';
import { Page, Title, BottomBar } from '../../components/Pages/PageComponents';
import AlertDialog from '../../components/Modals/Alert';

/**
 * 
 */
function SchoolEdit() {
  const { id = '-1' } = useParams();
  const [fieldDictionary, setFieldDictionary] = useState<DisplayField[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData(id, getSchoolById, setData, console.log);
  }, []);

  // Set data to null for create mode
  const setData = (data: any) => {
    data = data ? data[0]: null;
    const newFields: DisplayField[] = [
      { name: 'Display Name', value: data ? `${data?.name}` : 'New School', dont_display: true },
      { name: 'School Name', value: data?.name || '' },
      { name: 'Email Suffix', value: data?.email_suffix || ''},
      { name: 'Description', value: data?.description || '', big: true },
    ];
    console.log(newFields);
    updateFieldInfo(newFields, setFieldDictionary, false);
  };







  const [isDialogOpen, setDialogOpen] = useState({title: '', message: ''});
  const closeDialog = (e: React.MouseEvent<HTMLElement>) => {
    setDialogOpen({title: '', message: ''});
  };

  const handleError = async (response: Response) => {
    console.log(response);
    if (response.body) {
      const data = await response.json();
      setDialogOpen({title: data?.title || 'Unknown Problem', message: data?.message || 'An unknown problem occured on submission'});
    }
  };

  const handleSuccess = (response: Response) => {
    navigate(-1);
  };

  const handleSave = (e: React.MouseEvent<HTMLElement>) => {
    updateFieldInfo(fieldDictionary, setFieldDictionary, true);
    e.preventDefault();
    console.log(fieldDictionary);
    const submitData: Payload = {
      'name': getDictInfo<DisplayField>(fieldDictionary, 'School Name')?.value,
      'email_suffix': getDictInfo<DisplayField>(fieldDictionary, 'Email Suffix')?.value,
      'description': getDictInfo<DisplayField>(fieldDictionary, 'Description')?.value,
    };
    console.log(submitData);
    saveData(id, submitData, updateSchoolById, createSchool, handleError,handleSuccess);
  };



  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    navigate(-1);
  };






  const handleChanges = (key: string, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const Field = getDictInfo<DisplayField>(fieldDictionary, key);
    if (Field){
      Field.value = e.target.value;
      console.log(Field);
      updateFieldInfo([Field], setFieldDictionary, false);
    }
  };

  return (
    <Page>
      <AlertDialog title={isDialogOpen.title} open={isDialogOpen.message!==''} message={isDialogOpen.message} onClose={closeDialog}/>
      <Title>
        {findFieldValue(fieldDictionary, 'Display Name')}
      </Title>
      <div className='flex flex-row'>
        <div className='flex-1'>
          <EditableText fields={fieldDictionary} onChange={handleChanges} />
        </div>
      </div>
      <BottomBar>
        <ButtonGroup>
          <div className='pr-4'>
            <StyledButton variant="secondary" onClick={handleSave}>Save</StyledButton>
          </div>
          <StyledButton onClick={handleCancel}>Cancel</StyledButton>
        </ButtonGroup>
      </BottomBar>
    </Page>
  );
}

export default SchoolEdit;