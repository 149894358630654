import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, IconButton, InputBase,Tab, Button, List } from '@mui/material';
import { ItemDisplay } from '../../types';
import { updateOrganizationById, getSignUpRequests, getEventRequests, getAdRequests, formatDateString, fetchListData, updateAdById, partialUpdateEventById, deleteOrganizationById, deleteEventById, deleteAdById, getEvents } from '../../utils';
import { useNavigate } from 'react-router-dom';
import ListItemList from '../../components/ListItems/ListItemList';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Page, Title, PaginationBar, SearchBar } from '../../components/Pages/PageComponents';


function EventsPage(): JSX.Element {
  const [eventsList, setEventList] = useState<Array<ItemDisplay>>([]);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [maxPages, setMaxPages] = useState<number>(1);

  const navigate = useNavigate();

  const setEventsData = async (data: any) => {
    const DisplayResults = data.map((item: any) => ({
      id: item?.id,
      primary: item?.name,
      secondary: `Organization: ${item.org_name}`,
    }));
    setEventList(DisplayResults);
  };

  useEffect(() => {
    fetchListData(getEvents,setEventsData,console.log, {page: pageNumber, setter: setMaxPages});
  }, [pageNumber]);

  /**
   * Delete an item from the list by idx
   */
  const deleteByIdx = async (idx: number, setter: React.Dispatch<React.SetStateAction<Array<ItemDisplay>>>, list: Array<ItemDisplay>) => {
    const newList = list.filter((_, i) => i != idx);
    setter(newList);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {

    // Implement search logic based on the current page
    const newEventsList = eventsList.map((item, idx) => {
      if ((item.primary).toLowerCase().includes(event.target.value.toLowerCase())) {
        item.dont_show = false;
      } else {
        item.dont_show = true;
      }
      return item;
    });
    setEventList(newEventsList);
  };

  const handleEventEdit = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    navigate(`${eventsList[idx].id}/edit`);
  };

  const handleEventDelete = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    deleteEventById(eventsList[idx].id.toString());
    deleteByIdx(idx, setEventList, eventsList);
  };

  const handleEventClick = (idx: number, e: React.MouseEvent<HTMLElement>) => {
    navigate(`${eventsList[idx].id}`);
  };


  return (
    <Page>
      <Grid container spacing={3} sx={{ width: '100%' }} className='pl-4 pt-4'>
        <SearchBar placeholder='Search...' onChange={handleSearch}/>
        <Grid item xs={6} md={4}>
        </Grid>
      </Grid>
      <div className=' py-4'>
        <ListItemList DisplayItems={eventsList} onClick={handleEventClick} onEdit={handleEventEdit} onDelete={handleEventDelete} />
      </div>
      <PaginationBar pageNumber={pageNumber} maxPages={maxPages} setter={setPageNumber}/>
    </Page>
  );
}

export default EventsPage;
