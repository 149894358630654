import React from 'react';
import { ItemDisplay } from '../../types';
import ListDisplayItem from './ListItem';

export interface SignUpListProps {
  DisplayItems: Array<ItemDisplay>;
  onEdit: (id: any, e: React.MouseEvent<HTMLElement>) => void;
  onDelete: (id: any, e: React.MouseEvent<HTMLElement>) => void;
  onClick: (id: any, e: React.MouseEvent<HTMLElement>) => void;
}

export function ListItemList(props: SignUpListProps) {
  const { DisplayItems, onEdit, onDelete, onClick } = props;

  return (
    <div className='p-4'>
      {DisplayItems.map((item, idx) => {
        if (!item.dont_show) {
          return (<ListDisplayItem key={idx} id={idx} primary={item.primary} secondary={item.secondary} onEdit={onEdit} onClick={onClick} onDelete={onDelete} />);
        }
      })}
    </div>
  );
}

export default ListItemList;