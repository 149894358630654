import React, {  useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getAdsById, findFieldValue, validateDateString, updateFieldInfo, updateDateInfo, getDictInfo, convertDateTimeState } from '../../utils';
import { StyledButton, ButtonGroup} from '../../components/Buttons/StyledButton';
import { EditableText } from '../../components/Text/TextComponents';
import { getLocationsById, editLocationsById, getTags, createLocations, formatImageUrl } from '../../utils';
import { Page, Title, BottomBar } from '../../components/Pages/PageComponents';
import { DateTimePicker } from '../../components/Date/DateTimePicker';
import MultiCategoryPicker from '../../components/TagPicker';
import { ImageDataValue, DisplayField, DateTimeState, Payload, PageOptions } from '../../types';
import { ImageUpload } from '../../components/Files/ImageUpload';
import AlertDialog from '../../components/Modals/Alert';
import Map from '../../components/LocationPicker';

interface Location {
  lat: number;
  lng: number;
}


function LocationsEdit() {
  const { id = '-1' } = useParams();
  const [selected, setSelected] = useState<Location | null>(null);
  const [fieldDictionary, setFieldDictionary] = useState<DisplayField[]>([]);
  const [imgDictionary, setImgDictionary] = useState<ImageDataValue>({});

  const [value, setValue] = useState<string[]>([]);

  const [categories, setCategories] = useState<string[]>([]);

  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const event_id = queryParams.get('event_id');
  const org_id = queryParams.get('org_id');

  useEffect(() => {
    setData();
  }, []);

  // Set data to null for create mode
  const setData = async () => {
    if (id === '-1') {
      const newFields: DisplayField[] = [
        { name: 'Display Name', value: 'Create Location', dont_display: true },
        { name: 'Name', value: ''},
      ];
      updateFieldInfo(newFields, setFieldDictionary, false);
    } else {
      const response = await getLocationsById(id);
      if (response.ok) {
        const data = await response.json();
        const newFields: DisplayField[] = [
          { name: 'Display Name', value: 'Edit Location', dont_display: true },
          { name: 'Name', value: data?.name},
        ];
        const newImgFields: ImageDataValue = {
          image: null,
          url: formatImageUrl(data?.image),
        };
        setValue(data?.tags_names);
        setSelected({lat: data?.latitude, lng: data?.longitude});
        setImgDictionary(data?.image ? newImgFields : {});
        updateFieldInfo(newFields, setFieldDictionary, false);
      }
    }
    setTags();
  };

  const setTags = async () => {
    const response = await getTags();
    if (response.ok) {
      const data = await response.json();
      console.log(data);
      setCategories(data);
    }
  };

  const [isDialogOpen, setDialogOpen] = useState({title: '', message: ''});
  const closeDialog = (e: React.MouseEvent<HTMLElement>) => {
    setDialogOpen({title: '', message: ''});
  };

  const handleError = async (response: Response) => {
    console.log(response);
    if (response.body) {
      const data = await response.json();
      setDialogOpen({title: data?.title || 'Unknown Problem', message: data?.message || 'An unknown problem occured on submission'});
    }
  };

  const handleSuccess = (response: Response) => {
    navigate(-1);
  };

  const handleSave = async (e: React.MouseEvent<HTMLElement>) => {
    updateFieldInfo(fieldDictionary, setFieldDictionary, true);
    e.preventDefault();
    if (selected) {
      const submitData: Payload = {
        'radius': getDictInfo<DisplayField>(fieldDictionary, 'Radius')?.value,
        'tags': value,
        'latitude': selected.lat,
        'longitude': selected.lng,
      };
  
      if (imgDictionary?.image) {
        submitData['media'] = imgDictionary.image;
      }
      
      if (id === '-1') {
        await createLocations(submitData);
      } else {
        await editLocationsById(id,submitData);
      }
      navigate(-1);
    } else {
      setDialogOpen({title: 'No Location Selected', message: 'No Location Selected'});
    }
  };



  const handleChanges = (key: string, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const Field = getDictInfo<DisplayField>(fieldDictionary, key);
    if (Field){
      Field.value = e.target.value;
      console.log(Field);
      updateFieldInfo([Field], setFieldDictionary, false);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const ImageUrl = URL.createObjectURL(file);
      console.log(ImageUrl);
      setImgDictionary({ image: file, url: ImageUrl});
    }
  };

  return (
    <Page>
      <AlertDialog title={isDialogOpen.title} open={isDialogOpen.message!==''} message={isDialogOpen.message} onClose={closeDialog}/>
      <Title>
        {findFieldValue(fieldDictionary, 'Display Name')}
      </Title>
      <div className='flex flex-col'>
        <EditableText fields={fieldDictionary} onChange={handleChanges} />
        <div className='ml-8'>
          <MultiCategoryPicker categories={categories} setValue={setValue} value={value}/>
          <Map setLoc={setSelected} loc={selected} readOnly={false} />
        </div>
        <ImageUpload name='Upload Image' image={imgDictionary.url} onUpload={handleFileChange}/>
      </div>
      <BottomBar>
        <ButtonGroup>
          <div className='pr-4'>
            <StyledButton variant="secondary" onClick={handleSave}>Save</StyledButton>
          </div>
          <StyledButton onClick={handleCancel}>Cancel</StyledButton>
        </ButtonGroup>
      </BottomBar>
    </Page>
  );
}

export default LocationsEdit;
