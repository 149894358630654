import React, { useEffect, useState } from 'react';
import { Grid, Tab, Button } from '@mui/material';
import { UserListItems } from '../../types';
import { Link } from 'react-router-dom';
import { getFlaggedUsers, getUserList, getRestrictedUserList, getBlockedUsers, fetchListData } from '../../utils';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import UserListItemList from '../../components/ListItems/UserListItemList';
import { PaginationBar, Page, SearchBar } from '../../components/Pages/PageComponents';
/**
 * Users Page
 */
function UsersPage(): JSX.Element {
  const [allUserData, setAllUserData] = useState<UserListItems[]>([]);
  const [flaggedUserData, setFlaggedUserData] = useState<UserListItems[]>([]);
  const [blockedUserData, setBlockedUserData] = useState<UserListItems[]>([]);
  const [restrictedUserData, setRestrictedUserData] = useState<UserListItems[]>([]);

  const [tabType, setTabType] = useState('all');
  
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [maxPages, setMaxPages] = useState<number>(1);

  const [searchText, setSearchText] = useState<string>('');

  

  useEffect(() => {
    getData();
  }, [pageNumber,tabType]); // Trigger effect when currentPage changes

  /**
   * Fetches data from the server based on the current page and usersPerPage
   */
  const getData = () => {
    if (tabType === 'all') {
      fetchListData(getUserList,setAllUserData,console.log, {page: pageNumber, setter: setMaxPages});
    }
    if (tabType === 'flagged') {
      fetchListData(getFlaggedUsers,setFlaggedUserData,console.log, {page: pageNumber, setter: setMaxPages});
    }
    if (tabType === 'restricted') {
      fetchListData(getRestrictedUserList,setRestrictedUserData,console.log, {page: pageNumber, setter: setMaxPages});
    }
    if (tabType === 'blocked') {
      fetchListData(getBlockedUsers,setBlockedUserData,console.log, {page: pageNumber, setter: setMaxPages});
    }
    filterUserlist(searchText);
  };

  /**
   * Set the tabType to be the corresponding tab
   */
  const handleTabChange = (_: React.SyntheticEvent<Element, Event>, userType: string) => {
    setTabType(userType);
    setPageNumber(1); // Reset currentPage when switching tabs
    getData();
  };

  const filterUserlist = (searchString: string) => {
    if (tabType === 'all') {
      const newUserList = allUserData.map((user, idx) => {
        if ((user.firstName + ' ' + user.lastName).toLowerCase().includes(searchString)) {
          user.dont_show = false;
        } else {
          user.dont_show = true;
        }
        return user;
      });
      setAllUserData(newUserList);
    }

    if (tabType === 'flagged') {
      const newFlaggedList = flaggedUserData.map((user, idx) => {
        if ((user.firstName + ' ' + user.lastName).toLowerCase().includes(searchString)) {
          user.dont_show = false;
        } else {
          user.dont_show = true;
        }
        return user;
      });
      setFlaggedUserData(newFlaggedList);
    }

    if (tabType === 'blocked') {
      const newBlockedList = blockedUserData.map((user, idx) => {
        if ((user.firstName + ' ' + user.lastName).toLowerCase().includes(searchString)) {
          user.dont_show = false;
        } else {
          user.dont_show = true;
        }
        return user;
      });
      setBlockedUserData(newBlockedList);
    }

    if (tabType === 'restricted') {
      const newRestrictedList = restrictedUserData.map((user, idx) => {
        if ((user.firstName + ' ' + user.lastName).toLowerCase().includes(searchString)) {
          user.dont_show = false;
        } else {
          user.dont_show = true;
        }
        return user;
      });
      setRestrictedUserData(newRestrictedList);
    }
  };

  /**
   * Search from the list of users and update after every keystroke
   */
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value.toLowerCase());
    filterUserlist(event.target.value.toLowerCase());
  };

  const handleItemChange = () => {
    getData();
  };

  return (
    <Page>
      <Grid container spacing={2} className='pl-4 pt-4'>
        <SearchBar placeholder='Search for a user' onChange={handleSearch}/>
        <Grid item>
          <Link to={'-1/edit'}>
            <Button variant="contained" color="secondary" data-testid="users-page-create">
              Create
            </Button>
          </Link>
        </Grid>
      </Grid>
      <div className=' py-4'>
        <TabContext value={tabType}>
          <div className='pl-6 pr-6'>
            <TabList onChange={handleTabChange} variant="fullWidth" indicatorColor='secondary' textColor='secondary'>
              <Tab label="All" value="all" data-testid="users-page-tab-all" />
              <Tab label="Flagged" value="flagged" data-testid="users-page-tab-flagged" />
              <Tab label="Restricted" value="restricted" data-testid="users-page-tab-restricted" />
              <Tab label="Blocked" value="blocked" data-testid="users-page-tab-blocked" />
            </TabList>
          </div>
          <TabPanel value="all">
            <UserListItemList items={allUserData} onChange={handleItemChange} />
          </TabPanel>
          <TabPanel value="flagged">
            <UserListItemList items={flaggedUserData} onChange={handleItemChange} />
          </TabPanel>
          <TabPanel value="restricted">
            <UserListItemList items={restrictedUserData} onChange={handleItemChange} />
          </TabPanel>
          <TabPanel value="blocked">
            <UserListItemList items={blockedUserData} onChange={handleItemChange} />
          </TabPanel>
        </TabContext>
      </div>
      <PaginationBar pageNumber={pageNumber} maxPages={maxPages} setter={setPageNumber}/>
    </Page>
  );
}

export default UsersPage;