import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteChangeReason, AutocompleteChangeDetails } from '@mui/material/Autocomplete';

const SmallEditStyle = ' block w-[80vh] p-2 text-gray-600 border border-primary-0 rounded-lg bg-primary-300 text-left align-top resize-none';

const inpStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black',
      borderWidth: 2,
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
      borderWidth: 3,
    },
  },
};

interface TagPickerParams {
  categories: string[];
  setValue: React.Dispatch<React.SetStateAction<string[]>>;
  value: string[];
}

const MultiCategoryPicker: React.FC<TagPickerParams> = ({categories, setValue, value}) => {

  const handleAddCategory = (
    event: React.ChangeEvent<any>,
    newValue: string[] | null,
    reason: AutocompleteChangeReason,
  ) => {
    if (newValue) {
      setValue(newValue);
    }
  };

  const filterOptions = (options: string[], params: any) => {
    const filtered: string[] = options.filter((option) =>
      option.toLowerCase().includes(params.inputValue.toLowerCase())
    );

    return filtered;
  };

  return (
    <div className='flex flex-col py-2 text-xl font-cabin w-[80vh]'>
      <div className=' text-secondary-300 w-48'>
        Tags
      </div>
      <Autocomplete
        multiple
        filterOptions={filterOptions}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={categories}
        renderOption={(props, option) => <li {...props}>{option}</li>}
        freeSolo
        renderInput={(params) => <TextField className={SmallEditStyle} sx={inpStyle} {...params} />}
        value={value}
        onChange={handleAddCategory}
      />
    </div>
  );
};

export default MultiCategoryPicker;