import { ListItem, Stack, ListItemText } from '@mui/material';
import React from 'react';
import { StyledButton } from '../Buttons/StyledButton';
import { ItemDisplay } from '../../types';

export interface SignUpItemProps {
  id: any;
  primary: string;
  secondary: string;
  onAccept: (id: any, e: React.MouseEvent<HTMLElement>) => void;
  onReject: (id: any, e: React.MouseEvent<HTMLElement>) => void;
  onClick: (id: any, e: React.MouseEvent<HTMLElement>) => void;
}

export function SignUpItem(props: SignUpItemProps) {
  const { id, primary, secondary, onAccept, onReject, onClick } = props;

  return (
    <div className=' bg-primary-300 drop-shadow-2xl rounded-lg'>
      <ListItem sx={{
        height: '100%',
        width: '100%',
        margin: '16px 0px 16px 0px',
      }}>
        <ItemText primary = {primary} secondary= {secondary} handleItemClick={((e) => {onClick(id,e);})} />
        <Stack spacing={2} direction='row'>
          <StyledButton variant = 'success' onClick={((e) => {onAccept(id,e);})} size = 'extra_small' variantExtra='contained'>Accept</StyledButton>
          <StyledButton variant = 'error' onClick={((e) => {onReject(id,e);})} size = 'extra_small' variantExtra='contained'>Reject</StyledButton>
        </Stack>
      </ListItem>
    </div>
  );
}


interface ItemTextParams {
  primary: string,
  secondary: string
  handleItemClick?:  (event: React.MouseEvent<HTMLElement>) => void;
}

// For displaying non-editable text
export const ItemText: React.FC<ItemTextParams> = ({ primary, secondary, handleItemClick }) => {
  return (
    <ListItemText
      primary={primary}
      secondary={secondary}
      primaryTypographyProps={{
        color: 'secondary',
        fontSize: '20px'
      }}
      onClick={handleItemClick}
      sx={{
        color: 'secondary.main',
      }}
    />
  );
};

export interface SignUpListProps {
  DisplayItems: Array<ItemDisplay>;
  onAccept: (id: any, e: React.MouseEvent<HTMLElement>) => void;
  onReject: (id: any, e: React.MouseEvent<HTMLElement>) => void;
  onClick: (id: any, e: React.MouseEvent<HTMLElement>) => void;
}

export function SignUpList(props: SignUpListProps) {
  const { DisplayItems, onAccept, onReject, onClick } = props;

  return (
    <div className='p-4'>
      {DisplayItems.map((item, idx) => {
        return (<SignUpItem key={idx} id={idx} primary={item.primary} secondary={item.secondary} onAccept={onAccept} onClick={onClick} onReject={onReject} />);
      })}
    </div>
  );
}

export default SignUpItem;