import React from 'react';
import { AttendeeDisplay } from '../../types';
import ListDisplayItem from './AttendeeItem';

export interface SignUpListProps {
  DisplayItems: Array<AttendeeDisplay>;
  onAttend: (id: any, e: React.MouseEvent<HTMLElement>) => void;
  onDelete: (id: any, e: React.MouseEvent<HTMLElement>) => void;
}

export function ListItemList(props: SignUpListProps) {
  const { DisplayItems, onAttend, onDelete } = props;

  return (
    <div className='p-4'>
      {DisplayItems.map((item, idx) => {
        if (!item.dont_show) {
          return (<ListDisplayItem key={idx} id={idx} attended={item.attended} primary={item.primary} secondary={item.secondary} onAttend={onAttend} onDelete={onDelete} />);
        }
      })}
    </div>
  );
}

export default ListItemList;