import React, { useState } from 'react';
import {
  TextField, Typography, Button
} from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import {
  FormContainer, InputContainer, SubmitContainer, TextFieldContainer
} from '../pages/SignInPage/SignInPage.styled';
import useUserContext from '../hooks/useUserContext';
import { authenticate } from '../utils';
import { CookieNames, UserStateActions } from '../constants';
import { type AccountType } from '../types';
import { cookie } from '../utils';
import { Title } from './Pages/PageComponents';

export interface SignInFormProps {
  accountType: AccountType;
  defaultPath: string;
}

import AlertDialog from './Modals/Alert';

/**
 * SignInForm component
 */
function SignInForm(props: SignInFormProps): JSX.Element {
  const { accountType, defaultPath } = props;
  const [emailText, setEmailText] = useState('');
  const [passwordText, setPasswordText] = useState('');
  const navigate = useNavigate();

  /**
     * Handles OnChange event for Email Text Input
     * @param e onChange Event
     */
  const onEmailTextChanged = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmailText(e.target.value);
  };

  /**
     * Handles OnChange event for Password Text Input
     * @param e onChange Event
     */
  const onPasswordTextChanged = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPasswordText(e.target.value);
  };


  const [isDialogOpen, setDialogOpen] = useState({title: '', message: ''});
  const closeDialog = (e: React.MouseEvent<HTMLElement>) => {
    setDialogOpen({title: '', message: ''});
  };

  /**
     * Handles onClick on the Sign-In button
     */
  const onSubmitSignIn = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const response = await authenticate(emailText, passwordText, accountType);
    const data = await response.json();
    const currentTime = Date.now();
    if (response.status == 200) {
      cookie.set(CookieNames.AUTH_TOKEN, data.access, {domain: '.unione.cc'});
      if (accountType === 'business') {
        const subscriptionEnd = data.organization.subscription_end;
        cookie.set(CookieNames.ORG_ID, data.organization.id, {domain: '.unione.cc'});

        // Is organization subscribed or has the subscription ended?
        if (!subscriptionEnd || subscriptionEnd < currentTime) {
          window.location.href = 'https://unione.cc/pricing';
          return;
        }
      }
      navigate(defaultPath);
    } else {
      if (response.status == 404) {
        setDialogOpen({title: data?.title || 'Unknown Account Credentials', message: data?.message || 'You have tried to sign in with a non-existant account'});
      } else {
        setDialogOpen({title: data?.title || 'A Problem Occurred', message: data?.message || 'An unknown problem occured when trying to sign in'});
      }
    }
  };

  return (
    <FormContainer >
      <AlertDialog title={isDialogOpen.title} open={isDialogOpen.message!==''} message={isDialogOpen.message} onClose={closeDialog}/>
      <InputContainer>
        <TextFieldContainer>
          <Typography variant="h6">
            Email
          </Typography>
          <TextField
            data-testid="email-input-field"
            label="Email"
            variant="outlined"
            type="email"
            onChange={onEmailTextChanged}
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <Typography variant="h6">
            Password
          </Typography>
          <TextField
            data-testid="password-input-field"
            label="Password"
            variant="outlined"
            type="password"
            onChange={onPasswordTextChanged}
          />
          {accountType === 'business' &&
            (
              <Link
                to="/sign-up"
                style={{ fontStyle: 'italic' }}
              >
                Sign-up for a new account?
              </Link>
            )}
        </TextFieldContainer>
      </InputContainer>
      <SubmitContainer>
        <Button
          data-testid="sign-in-button"
          variant="contained"
          size="large"
          fullWidth
          color='secondary'
          onClick={onSubmitSignIn}
        >
          Sign In
        </Button>
      </SubmitContainer>
    </FormContainer>
  );
}

export default SignInForm;