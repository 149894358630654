import React, { useRef } from 'react';
import { Container, useTheme } from '@mui/material';

const ImageUploadDivStyle = ' text-secondary-300 pt-4 text-[28px]';

interface UploadProps {
  name?: string;
  noImageText?: string;
  image?: string;
}

export const ImageDisplay: React.FC<UploadProps> = ({ name = 'No image exists', noImageText = 'Image', image}) => {
  const theme = useTheme();

  return (
    <div className='text-xl text-info-300 pl-8 py-2'>
      <div className='flex flex-col text-xl font-cabin text-secondary-300 w-48 mb-2'>
        {name}
      </div>
      <Container sx={{
        float: 'left',
        height: '20vh',
        width: '20vh',
        padding: '2px',
        marginRight: '10px',
        flex: '0 0 auto',
        aspectRatio: '1',
        backgroundColor: theme.palette.primary.main,
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        border: '1px solid rgba(0, 0, 0, 0.1)', // Add a 1-pixel border
        borderRadius: '12px',
        boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.1)', // Add a shadow
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        {image ? null : <div className='text-sm font-cabin text-secondary-300'>
          {noImageText}
        </div>
        }
      </Container>
    </div>
  );
};



